import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router, private jwtHelper: JwtHelperService, private authService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = localStorage.getItem("jwt");
    const refreshToken = localStorage.getItem("refresh_token");
    const decodedToken = this.jwtHelper.decodeToken(token);
    // console.log("canactivate", decodedToken);
    // console.log("canactivate", this.jwtHelper.isTokenExpired(token, 3));
    if (token && !this.jwtHelper.isTokenExpired(token)) {

      //console.log("decodetoken", this.jwtHelper.decodeToken(token))
      return true;
    }
    // console.log("auth-gaurd tryRefreshingTokens");
    let isRefreshTokenExpired: boolean = this.tryRefreshingTokens(token);
    //console.log("isRefreshSuccess", isRefreshTokenExpired);
    // let newtoken = localStorage.getItem("jwt");
    // console.log("this.jwtHelper.isTokenExpired(token)", this.jwtHelper.isTokenExpired(newtoken))
    if (isRefreshTokenExpired) {
      this.router.navigate(["login"]);
    }
    return true;

  }



  private tryRefreshingTokens(token: string): boolean {
    const refreshToken = localStorage.getItem("refresh_token");
    if (token == null || refreshToken == null) {
      return false;
    }
    const userName = localStorage.getItem("CurrentUserName");
    const credentials = { accessToken: token, refreshToken: refreshToken, userName: userName };
    //console.log("auth-gaurd refreshtoken called", credentials);
    let sucesss = false;
    this.authService.refreshToken(credentials).subscribe((res: any) => {
      let access_token = res.accessToken;
      if (this.jwtHelper.isTokenExpired(access_token)) {
        sucesss = true;

      }
      else {
        sucesss = false;
      }
    });

    return sucesss;
  }
}
