import { Component, EventEmitter, Input, OnInit, Injector, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { TreeviewService } from '../../service/treeview.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from "../../../environments/environment";
import { Sections } from '../../model/sections';
import { NgxSpinnerService } from "ngx-spinner";
import { SectionsService } from "../../service/sections.service";
import { take } from 'rxjs/operators';
import { MethodserviceService } from '../record/method/service/methodservice.service';
import { element } from 'protractor';
import { MinussignsparenPipe } from '../../common/commonhtml/minussignsparen.pipe';
import {
  CloseScrollStrategy, ConnectedPositioningStrategy,
  IgxDropDownComponent, IgxTreeGridComponent, OverlaySettings, PositionSettings, RowType,
  VerticalAlignment, Transaction, IGridEditEventArgs
} from 'igniteui-angular';
import { recordNavigation } from 'src/app/service/recordNavigation.service';
import { sectionModel, sectionTreeModel } from 'src/app/model/sectionTreeModel';
import { TreemenuComponent } from 'src/app/common/treemenu/treemenu.component';
import { NotificationService } from 'src/app/core/services/notification.service';
@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss'],
})
export class SectionsComponent implements OnInit {
  @ViewChild(IgxTreeGridComponent) public sectiongrid: IgxTreeGridComponent;
  @ViewChild(IgxDropDownComponent) public igxDropDown: IgxDropDownComponent;
  @Input() sectionclicked;
  activeId: any
  sectionsDisplay: any = [];
  insidebudgetdataArray: any = [];
  months = [];
  details: any
  additonal_info_sum = [];
  dtOptions: DataTables.Settings = {};
  sectionTree: sectionTreeModel[] = [];
  sectionmodal: sectionModel;
  treemenu: TreemenuComponent;
  SectionrecordsType: any[] = [];
  addedData: any;
  deletedRecords: sectionTreeModel[] = [];
  hideSectionView = false;
  notifier: any;
  FolderLimitReached = false;
  public totalNumber = 0;
  public hideRowSelectors = true;
  public AddedRecordsID = [];
  constructor(private sectionservice: SectionsService, private methodservice: MethodserviceService,
    private treeview: TreeviewService, private _router: Router, private SpinnerService: NgxSpinnerService,
    private RecordNavigation: recordNavigation, private injector: Injector) {
    this.activeId = localStorage.getItem('ForecastId');
    this.notifier = this.injector.get(NotificationService);
    this.dtOptions = {
      searching: false,
      paging: false,
      search: false,
      ordering: false,
    };

  }
  ngOnInit() {
    // this.dtOptions = {
    //   searching: false,
    //   paging: false,
    //   ordering: false,
    // }
    this.ngOnChanges();


  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  getloopyears() {
    this.months = [];
    // this.treeview.getforecastDetails(this.activeId).subscribe 
    // (( response ) =>{
    //    this.details = response;
    //   if(this.details) {
    //     var date = new Date(this.details.startdate);
    //     var monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
    //     for(var i = 0; i < 12; i++) {
    //         this.months.push(monthNames[date.getMonth()] + ' ' + date.getFullYear()%100);
    //         date.setMonth(date.getMonth() + 1);
    //     }
    //   }
    // })
    let startDate = localStorage.getItem('Forecaststartdate');
    var date = new Date(startDate);
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    for (var i = 0; i < 12; i++) {
      this.months.push(monthNames[date.getMonth()] + ' ' + date.getFullYear());
      date.setMonth(date.getMonth() + 1);
    }
  }

  transform(value: any, args?: any): any {
    if (Math.sign(value) == -1) {
      return '(' + Math.abs(value) + ')';
    } else {
      return value;
    }
  }

  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']

    for (let letter of expr.toString()) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }
  async ngOnChanges() {
    this.dtOptions = {
      searching: false,
      paging: false,
      search: false,
      ordering: false,
    };
    let testingvalue = [];
    console.log("section Licked")
    if (this.sectionclicked != null) {
      this.sectionsDisplay = [];
      this.insidebudgetdataArray = [];
      let total = 0;
      this.getloopyears();
      this.SpinnerService.show();
      this.sectionservice.getSectionsChildrens(this.activeId, this.sectionclicked).pipe(take(1)).subscribe
        (async (response: any) => {
          this.SpinnerService.hide();
          this.hideSectionView = false;
          if (response != undefined) {
            if (response.sectionTreeModel.length > 0) {
              this.sectionTree = response.sectionTreeModel;

            }
            if (response.recordTypes.length > 0) {
              this.SectionrecordsType = response.recordTypes;

            }

            if (response.recordTypes.length == 0) {
              this.hideSectionView = true;
            }
            if (response.sectionDataView != null)
              if (response.sectionDataView.length > 0) {
                let dv: DataView = response.sectionDataView;
                dv[0].row.table.forEach(element => {
                  for (let i = 0; i < 12; i++) {
                    element[i] = this.transform(element[i])
                  }
                  element["total"] = this.transform(element["total"]);
                });

                this.sectionsDisplay.push(dv[0].row.table)

                if (this.sectionsDisplay != null) {
                  this.additonal_info_sum = [];
                  let convertjson = {
                    'addition': 0,
                    'avarage': 0,
                    'count': 0,
                    'max': 0,
                    'min': 0,
                  }

                  this.sectionsDisplay.forEach(element => {
                    for (let allint = 0; allint < 12; allint++) {
                      let sum: any;
                      let avarage = 0;
                      let count = 0;
                      let minarray: any = [];
                      let Positive_Count = 0;
                      let Negative_Count = 0;
                      for (let index = 0; index < element.length; index++) {
                        sum = '';
                        let Result = element[index][allint];
                        if (this.check(element[index][allint]) == true) {
                          Result = element[index][allint].replace(/\(/g, '-').replace(/\)/g, '');
                          Negative_Count += Math.abs(parseInt(Result));
                        } else {
                          Positive_Count += Math.abs(parseInt(Result));
                          Result = element[index][allint];
                        }
                        minarray.push(Math.abs(Result));
                      }

                      sum = Positive_Count - Negative_Count;
                      avarage = sum / element.length;
                      let data: any = avarage.toFixed(2);
                      count = element.length;
                      convertjson.addition = Positive_Count - Negative_Count;
                      convertjson.avarage = parseInt(data);
                      convertjson.count = count;
                      convertjson.max = Math.max(...minarray)
                      convertjson.min = Math.min(...minarray)

                      let ResultjsonData = {
                        'addition': Positive_Count - Negative_Count,
                        'avarage': data,
                        'count': count,
                        'max': Math.max(...minarray),
                        'min': Math.min(...minarray),
                      }
                      testingvalue.push(ResultjsonData);
                      this.additonal_info_sum.push(convertjson);
                    }
                  });
                  this.additonal_info_sum = testingvalue;

                }
              }
          }
        })
    }
  }

  openadditonsum($event, numbers) {
    for (let i = 0; i <= 12; i++) {
      if (numbers == i) {
        $('#additonalinfoshow-' + i).removeClass('hidden');
      } else {
        $('#additonalinfoshow-' + i).addClass('hidden');
      }
    }
  }

  removesumvalue($event, itteration) {
    this.dtOptions = {
      searching: false,
      paging: false,
      ordering: true,
    }
    var element = document.getElementById("additonalinfoshow-" + itteration);
    element.classList.add("hidden");
  }
  addsumvalue($event, selectedcheckboxrow) {
    this.dtOptions = {
      searching: false,
      paging: false,
      ordering: false,
    }
    let activeoverlay = false;
    const sumcb: any = document.getElementById('selectioninfosum-' + selectedcheckboxrow);
    const avgcb: any = document.getElementById('selectioninfoavarage-' + selectedcheckboxrow);
    const minicb: any = document.getElementById('selectioninfomaximum-' + selectedcheckboxrow);
    const maxcb: any = document.getElementById('selectioninfominimum-' + selectedcheckboxrow);
    const countcb: any = document.getElementById('selectioninfocount-' + selectedcheckboxrow);
    // checked
    if (sumcb.checked == true) {
      $('#sum-' + selectedcheckboxrow).removeClass('hidden');
      activeoverlay = true;
    }
    if (avgcb.checked == true) {
      $('#Avarage-' + selectedcheckboxrow).removeClass('hidden');
      activeoverlay = true;
    }
    if (minicb.checked == true) {
      $('#Minimum-' + selectedcheckboxrow).removeClass('hidden');
      activeoverlay = true;
    }
    if (maxcb.checked == true) {
      $('#Maximum-' + selectedcheckboxrow).removeClass('hidden');
      activeoverlay = true;
    }
    if (countcb.checked == true) {
      $('#Count-' + selectedcheckboxrow).removeClass('hidden');
      activeoverlay = true;
    }
    if (activeoverlay == true) {
      $('#updatedvalue').removeClass('hidden');
    }
    // unchecked 
    if (sumcb.checked == false) {
      $('#sum-' + selectedcheckboxrow).addClass('hidden');
    }
    if (avgcb.checked == false) {
      $('#Avarage-' + selectedcheckboxrow).addClass('hidden');
    }
    if (minicb.checked == false) {
      $('#Minimum-' + selectedcheckboxrow).addClass('hidden');
    }
    if (maxcb.checked == false) {
      $('#Maximum-' + selectedcheckboxrow).addClass('hidden');
    }
    if (countcb.checked == false) {
      $('#Count-' + selectedcheckboxrow).addClass('hidden');
    }
  }


  public AddFolder(parentRow, id, index) {
    const parent = this.sectiongrid.getRowByIndex(parentRow.index);
    var isChild = parent.key;
    if (parentRow.data.isFolderAllowed === '1') {
      isChild = parent.key;
    }

    this.addedData = {
      name: "New Folder",
      nodeType: "40000",
      nodeID: (this.sectiongrid.data.length + 1).toString(),
      parentNodeid: isChild,
      IsNewlyAdded: true,
      isFolderAllowed: "1",
      currentNodeid: "",
    };
    let isFolderlimitReached = this.CheckFolderLimitReached(this.addedData.parentNodeid);
    //console.log("isFolderlimitReached", isFolderlimitReached)
    if (isFolderlimitReached) {
      this.addedData.isFolderAllowed = "0";
    }
    let firstSiblingRecordIdx = this.sectionTree.findIndex(
      (r) => r.nodeID === this.addedData.parentNodeid
    );

    this.sectionTree.splice(firstSiblingRecordIdx + 1, 0, this.addedData);
    this.sectiongrid.transactions.commit(this.sectiongrid.data);
    this.addedData = "";
  }
  CheckFolderLimitReached(parentNodeID) {
    let countFolder = 0;
    let maxFolder = 4;
    let parentNodeIndex = this.sectionTree.findIndex(
      (r) => r.nodeID === parentNodeID
    );

    let parentNode = this.sectiongrid.getRowByIndex(parentNodeIndex);
    //console.log(" parentNode", parentNode);
    while (parentNode != null && countFolder <= maxFolder && parentNode.data.nodeType != null) {
      //console.log(" countFolder++;", countFolder);
      //console.log(" parentNode 1", parentNode);

      if (parentNode.data.nodeType === "40000") {
        countFolder++;
      }
      parentNodeIndex = this.sectionTree.findIndex(
        (r) => r.nodeID === parentNode.data.parentNodeid
      );
      parentNode = this.sectiongrid.getRowByIndex(parentNodeIndex);
    }
    return countFolder >= maxFolder;
  }

  public addChildRow(parentRow, id, index) { 
    console.log(parentRow);
    const parent = this.sectiongrid.getRowByIndex(parentRow.index);
    var isChild = parentRow.rowID;
    if (parentRow.data.isFolderAllowed === '0') {
      isChild = parent.treeRow.parent.key;
    }
    if (isChild === undefined) {
      isChild = parentRow.data.nodeID;
    }


    this.addedData = {
      name: "New Record",
      nodeType: "",
      nodeID: (this.sectiongrid.data.length + 1).toString(),
      parentNodeid: isChild,
      IsNewlyAdded: true,
      isFolderAllowed: "0",
      isSection: "0",
      isModified: false,
      currentNodeid: "",
    };

    let firstSiblingRecordIdx = this.sectionTree.findIndex(
      (r) => r.nodeID === this.addedData.parentNodeid
    );
    this.sectionTree.splice(firstSiblingRecordIdx + 1, 0, this.addedData);
    this.sectiongrid.transactions.commit(this.sectiongrid.data);
    this.addedData = "";
  }

  public onRowAdd(args: IGridEditEventArgs): void {

    if (args.isAddRow) {
      const rowID = args.rowID;
      this.sectiongrid.updateCell(this.addedData.name, rowID, "name");
      this.sectiongrid.updateCell(this.addedData.nodeType, rowID, "nodeType");
      this.addedData = "";
    }
  }
  public deleteRow(index, event, row) {

    if (row.data.isSection === "1") {
      this.notifier.showError("Section cannot be deleted");
    }
    else {
      if (row.data.currentNodeid === "") {
        const currentrow = this.sectiongrid.getRowByIndex(index);


        let firstSiblingRecordIdx = this.sectionTree.findIndex(
          (r) => r.nodeID === currentrow.data.nodeID
        );
        this.sectionTree.splice(firstSiblingRecordIdx, 1);
        row.delete();
      } else {
        const currentrow = this.sectiongrid.getRowByIndex(index);


        let firstSiblingRecordIdx = this.sectionTree.findIndex(
          (r) => r.nodeID === currentrow.data.nodeID
        );
        this.deletedRecords.push(this.sectionTree[firstSiblingRecordIdx]);
        this.sectionTree.splice(firstSiblingRecordIdx, 1);
        let SiblingRecords = this.sectionTree.filter(
          (r) => r.parentNodeid === currentrow.data.nodeID
        );
        if (SiblingRecords.length > 0) {
          SiblingRecords.forEach((element) => {
            let SiblingRecordIdx = this.sectionTree.findIndex(
              (r) => r.nodeID === element.nodeID
            );
            this.sectionTree.splice(SiblingRecordIdx, 1);
          })
        }

        row.delete();
      }
    }
  }


  public editDone(evt) {
    var currentRow = this.sectiongrid.getRowByKey(evt.rowID);
    let firstSiblingRecordIdx = this.sectionTree.findIndex(
      (r) => r.nodeID === currentRow.data.nodeID
    );
    if (this.sectionTree[firstSiblingRecordIdx].isSection != "1") {
      this.sectionTree[firstSiblingRecordIdx].name = evt.rowData.name;
      this.sectionTree[firstSiblingRecordIdx].nodeType = evt.rowData.nodeType;
      if (!this.sectionTree[firstSiblingRecordIdx].IsNewlyAdded) {
        this.sectionTree[firstSiblingRecordIdx].IsModified = true;
      }
    } else {
      this.notifier.showError("Section cannot be edited here.")
    }


  }
  handleCellEditEnter(event) {
    if (event.rowData.isSection === "1") {
      event.cancel = true;
    }
  }

  rowEditEnter(event, row) {
    var currentRow = this.sectiongrid.getRowByKey(row.rowID);
    currentRow.cells[0].editMode = true;
  }
  handleRowSelection(row, event) {
    if (row.data.nodeID != null)
      this.RecordNavigation.recordNavigation.emit(row.data.nodeID);
  }
  SaveRecords(event) {
    this.sectionmodal = new sectionModel();
    this.sectionmodal.sectionTreeModels = this.sectionTree;
    this.sectionmodal.deletedRecords = this.deletedRecords;
    this.SpinnerService.show();
    this.sectionservice.saveRecordsFolder(this.sectionmodal).subscribe
      ((response: any) => {
        localStorage.setItem('TreeRecordNodeId', this.sectionclicked);
        localStorage.setItem("UpdatedTreeView", JSON.stringify(this.sectionmodal));
        localStorage.setItem("treeview", JSON.stringify(response))
        this.SpinnerService.hide();
        window.location.reload();
      });
  }
  selectionChange(evt, cell) {
    cell.value = evt.newSelection.map(el => el.name).toString();
  }
  private _positionSettings: PositionSettings = {
    verticalDirection: VerticalAlignment.Bottom
  }

  private _overlaySettings: OverlaySettings = {
    closeOnOutsideClick: true,
    modal: false,
    positionStrategy: new ConnectedPositioningStrategy(this._positionSettings),
    scrollStrategy: new CloseScrollStrategy()
  }

  toggleDropDown(evt) {
    this._overlaySettings.positionStrategy.settings.target = evt.target;
    if (this.igxDropDown != undefined)
      this.igxDropDown.toggle(this._overlaySettings);
  }

  select(evt, cell, row) {
    const val = this.SectionrecordsType[evt.newSelection.index].nodeType;
    cell.update(val);
    let firstSiblingRecord = this.sectionTree.find((r) => r.nodeID === row.data.nodeID);
    firstSiblingRecord.nodeType = val.toString();
    if (!firstSiblingRecord.IsNewlyAdded) {
      firstSiblingRecord.IsModified = true;
    }
  }

  public parseArray(value) {

    let s = [];
    if (value != '' && value != undefined && value != null) {
      s = this.SectionrecordsType.filter(x => x.nodeType == value);
    }

    let result = "";
    if (value == null && value == undefined) {
      return result;
    }
    if (s.length == 1) {
      result = s[0].recordType
    } else if (value === "40000") {
      result = "Folder";
    } else if (value === "30002") {
      result = "Sales";
    } else if (value === "30003") {
      result = "Costs";
    } else if (value === "30004") {
      result = "Overheads";
    } else if (value === "30012") {
      result = "Other Costs";
    } else if (value === "30005") {
      result = "Other Income";
    } else if (value === "30016") {
      result = "Extra Ordinary";
    } else if (value === "30007") {
      result = "Fixed Assets";
    } else if (value === "30008") {
      result = "Loans";
    }
    else if (value == "30017") {
      result = "Factoring";
    }
    else if (value === "30009") {
      result = "Other Assets/Liabilities";
    }
    else if (value === "30010") {
      result = "Capital Accounts";
    }
    else if (value === "30011") {
      result = "Memo Records";
    }
    else if (value === "30013") {
      result = "Depreciation";
    }
    else if (value === "30014") {
      result = "Assumptions";
    }
    else if (value === "30006") {
      result = "Bank Accounts";
    }
    return result;
  }


}
