import { Component, OnInit, Input } from '@angular/core';
import { MemoRecordOptionService } from '../../../service/memo-record-option.service';
import { MemoRecordOption } from '../../../model/memo-record-option';
import { ValuelistComponent } from '../../record/valuelist/valuelist.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-accrued-prepaid-cost-record-options',
  templateUrl: './accrued-prepaid-cost-record-options.component.html',
  styleUrls: ['./accrued-prepaid-cost-record-options.component.scss']
})
export class AccruedPrepaidCostRecordOptionsComponent implements OnInit {

  @Input() RecordOptionlistCollection: any;
  recordCollectionList: [];
  displaycollection: boolean;
  constructor(public recordOptionsServices: MemoRecordOptionService,
    private ValuelistComponent: ValuelistComponent,
    private SpinnerService: NgxSpinnerService) { }

  ngOnChanges() {
    this.displaycollection = false;
    if (this.RecordOptionlistCollection != '') {
      this.recordCollectionList = this.RecordOptionlistCollection;
      // console.log(this.recordCollectionList);
      this.displaycollection = true;
    }
  }
  ngOnInit() {
    this.displaycollection = false;
    if (this.RecordOptionlistCollection != '') {
      this.recordCollectionList = this.RecordOptionlistCollection;
      console.log("this.recordCollectionList", this.recordCollectionList);
      this.displaycollection = true;
    }
  }
  accrualPrepaymentOptionModelChange(collection, $event) {
    let selectedNodeId = parseInt($event.target.value);
    let CollectionList: any = this.recordCollectionList;
    let NodeId = CollectionList.nodeId;
    //this.SpinnerService.show();
    localStorage.setItem("SaveCalulcateEvent","true");
    this.recordOptionsServices.changeAccruedoptios(NodeId, selectedNodeId, collection[0].recordNodeId).subscribe
      (
        (response: any) => {
          // try {
          //   this.ValuelistComponent.DisplayAllCommonDataArray = [];
          //   this.ValuelistComponent.BudgetDerrivationView = [];
          //   this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
          //   this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
          //   this.ValuelistComponent.PeriodTabView = response.getPeriodTabView;
          //   this.SpinnerService.hide();
          // } catch (error) {
          //   this.SpinnerService.hide();
          // }

        }
      )
  }

  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }

  openingEntryCollectionchange($event, nodeId, oldvalue) {
    if ($event.target.value == '') {
      return;
    }
    let newValue = parseFloat($event.target.value.replaceAll(/,/g, '')).toFixed(2);
    let oldvalues = parseFloat(oldvalue.toString()).toFixed(2);
    if (newValue == oldvalues) {
      return;
    }
    let collectionlist: any = this.recordCollectionList;
    let convertjson = {
      value: newValue,
      selectedNodeId: nodeId,
      activeRecordNodeId: collectionlist.accrualPrepaymentOptionModel[0].recordNodeId
    }
    this.SpinnerService.show();
    this.recordOptionsServices.GetRecordAccruedOpeningEntry(convertjson).subscribe
      (
        (response: any) => {
          try {
            this.ValuelistComponent.DisplayAllCommonDataArray = [];
            this.ValuelistComponent.BudgetDerrivationView = [];
            this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
            this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
            this.ValuelistComponent.PeriodTabView = response.getPeriodTabView;
            this.SpinnerService.hide();
          } catch (error) {
            this.SpinnerService.hide();
          }
        }
      )
  }

  tradeDebitorsOpeningEntryModelschange($event, nodeId, oldvalue) {
    if ($event.target.value == '') {
      return;
    }
    let newValue = parseFloat($event.target.value.replaceAll(/,/g, '')).toFixed(2);
    let oldvalues = parseFloat(oldvalue.toString()).toFixed(2);
    if (newValue == oldvalues) {
      return;
    }
    let collectionlist: any = this.recordCollectionList;
    let convertjson = {
      value: newValue,
      selectedNodeId: nodeId,
      activeRecordNodeId: collectionlist.accrualPrepaymentOptionModel[0].recordNodeId
    }
    //this.SpinnerService.show();
    localStorage.setItem("SaveCalulcateEvent","true");
    this.recordOptionsServices.GetRecordTradedebitorsOpeningEntry(convertjson).subscribe
      (
        (response: any) => {
          // try {
          //   this.ValuelistComponent.DisplayAllCommonDataArray = [];
          //   this.ValuelistComponent.BudgetDerrivationView = [];
          //   this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
          //   this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
          //   this.ValuelistComponent.PeriodTabView = response.getPeriodTabView;
          //   this.SpinnerService.hide();
          // } catch (error) {
          //   this.SpinnerService.hide();
          // }
        }
      )
  }

  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      if (validatebynumberFormate == '.') {
        $event.target.value = "0.";
      } else {
        $event.target.value = validatebynumberFormate;
      }
    }
  }

}
