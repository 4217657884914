import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { TreeviewService } from '../../service/treeview.service';
import { Router } from '@angular/router';
import { Report } from '../../model/report/report';
import { NgxSpinnerService } from "ngx-spinner";
import { ReportService } from "../../service/report.service";
import { take } from 'rxjs/operators';
import { IgxExcelExporterOptions, IgxExcelExporterService, IgxTreeGridComponent, RowType } from '@infragistics/igniteui-angular';
import { ReportDecimalpipePipe } from './report-decimalpipe.pipe';
import { NotificationService } from 'src/app/core/services/notification.service';
import * as XLSX from 'xlsx';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MinussignsparenPipe } from 'src/app/common/commonhtml/minussignsparen.pipe';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  @ViewChild('reportGrid') public treeGrid: IgxTreeGridComponent;
  activeId: any;
  forecastname = '';
  defualtreportDisplayData = [];
  defualtreportDsiplayvaluedata = [];
  details: any = [];
  months: any = [];
  insidemonths: any = [];
  activenodeId: any = [];
  quertlyinsideloop: any = []
  quaterlyLoop: any = [];
  allTabsnameArray: any = [];
  allTabsdataArray: any = [];
  newMontharrayStructure = [];
  newInsideMontharrayStructure = [];
  annuallyinsideloop = [];
  displaytotal: any = [];
  NavitemSubmenu = [];
  navItems = [];
  public selected = [];
  public displaySubmenustyle = '';
  showDrawerClick: boolean;
  hideDrawerClick: boolean;
  collectionArray = [];
  ReportCollectionArrayResult = [];
  fileName = 'ExcelSheet.xlsx';
  IsActualForecast = 'false';
  IsActual = '';
  isConsolidation: boolean = false;
  PeriodTabView: any = [];
  ActivePeriodTab: 0;
  ForecastbudgetReport = [];
  ForecastMainBankAccounts = [];
  SelectBankAccountId = 0;
  VarianceMonthColleciton = [];
  ForecastReportBudgteNodeId: any;
  VarianceMonthSelectionIndex = 0;
  ActiveFrequncy = "Monthly";
  ReportTabActiveindex = 0;
  isRolling = false;
  ComapanyName = "";
  prviousLinkClicked = null;
  public options = {
    enabled: true,
    copyHeaders: true,
    copyFormatters: true,
    separator: '\t'
  };
  reportjson = [
    { label: 'Profit And Loss', name: 'ProfitAndLossBudget', type: 'Budget', value: 990001, },
    { label: 'CashFlow', name: 'CashFlowBudget', type: 'Budget', value: 990002, },
    { label: 'Balance Sheet', name: 'BalanceSheetBudget', type: 'Budget', value: 990003, },
    { label: 'Funds Flow', name: 'FundsFlowBudget', type: 'Budget', value: 990004, },
    { label: 'Profit And Loss By Department', name: 'ProfitAndLossByDepartmentBudget', type: 'Budget', value: 990005, },
    { label: 'OtherAssetLiabilityEntryBudget', name: 'OtherAssetLiabilityEntryBudget', type: 'Budget', value: 990006, },
    { label: 'Profit And Loss By Tracking', name: 'ProfitAndLossByTrackingBudget', type: 'Budget', value: 990007, },
    { label: 'Daily CashFlow', name: 'DailyCashFlowBudget', type: 'Budget', value: 990008, },
    // schedule Report
    { label: 'Employee Schedule', name: 'EmployeeSchedule', type: 'Schedules', value: 990031, },
    { label: 'Fixed Asset Schedule', name: 'FixedAssetSchedule', type: 'Schedules', value: 990032, },
    { label: 'Loans Received Schedule', name: 'LoansReceivedSchedule', type: 'Schedules', value: 990033, },
    { label: 'Loans Made Schedule', name: 'LoansMadeSchedule', type: 'Schedules', value: 990034, },
    { label: 'GST Schedule', name: 'GSTSchedule', type: 'Schedules', value: 990035, },
    { label: 'Statistics And Ratios', name: 'StatisticsAndRatios', type: 'Schedules', value: 990037, },
    { label: 'Assumptions', name: 'AssumptionSchedule', type: 'Schedules', value: 990038 },
    // Variation report
    { label: 'Profit And Loss (Variance)', name: 'ProfitAndLossVariation', type: 'Variance', value: 990021, },
    { label: 'CashFlow (Variance)', name: 'CashFlowVariation', type: 'Variance', value: 990022, },
    { label: 'Balance Sheet (Variance)', name: 'BalanceSheetVariation', type: 'Variance', value: 990023, },
    { label: 'Funds Flow (Variance)', name: 'FundsFlowVariation', type: 'Variance', value: 990024, },
    { label: 'Profit And Loss By Department (Variance)', name: 'ProfitAndLossByDepartmentVariation', type: 'Variance', value: 990025, },
    //Consolidation Report
    { label: 'Elimination P&L Schedule', name: 'EliminationP&LSchedule', type: 'Consolidation', value: 990071, },
    { label: 'Elimination BS Schedule', name: 'EliminationBSSchedule', type: 'Consolidation', value: 990072, },
    { label: 'Elimination CashFlow Schedule', name: 'EliminationCashFlowSchedule', type: 'Consolidation', value: 990073, },
    { label: 'Minority Interest Schedule', name: 'MinorityInterestSchedule', type: 'Consolidation', value: 990074, },
    { label: 'Associate Interest Schedule', name: 'AssociateInterestSchedule', type: 'Consolidation', value: 990075, },

  ]

  constructor(
    private ReportServices: ReportService,
    private treeview: TreeviewService,
    private _router: Router,
    private SpinnerService: NgxSpinnerService,
    private excelExportService: IgxExcelExporterService, private injector: Injector
  ) {
    this.activeId = localStorage.getItem('ForecastId');
    let mini = true;
    this.IsActualForecast = localStorage.getItem('IsCanEnterActual');
    this.forecastname = localStorage.getItem('ForecastName');
    this.isConsolidation = (localStorage.getItem('isConsolidation') === 'true');
    this.ComapanyName = localStorage.getItem('Companyname');
    console.log(this.ComapanyName);
    if(this.ComapanyName == null){
      this.ComapanyName = "";
    }
  }



  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  public navigate(item) {
    console.log(item);
    this.selected = [];
    this.displaySubmenustyle = 'block';
    this.selected.push(item);
    // console.log(this.selected);
  }
  RightsidebarmenClickEvent = "RightsidebarmenClickEventFunClick";
  RightsidebarmenClickEventFunClick(reportType, Item) {
     
    this.SelectBankAccountId = 0;
    if (Item.callback.name == "Forecasthome") {
      this._router.navigateByUrl('/forecast');
    } else {
      if (Item.name == "Cashflow") {
        this.SpinnerService.show();
        var ForecastNodeId = localStorage.getItem('ForecastId');
        this.ReportServices.GetForecastBankAccounts(ForecastNodeId).subscribe(
          ((response: any) => {
            this.SpinnerService.hide();
            console.log("response GetForecastBankAccounts", response);
            this.ForecastMainBankAccounts = response;
            if (this.ForecastMainBankAccounts.length > 1) {
              var eventgenarate: any = (<HTMLInputElement>document.getElementById('CashFlowBankAccount'));
              localStorage.setItem("CashflowItem", Item);
              eventgenarate.click();

            } else {
              this.SelectBankAccountId = response[0].nodeId;
              this[Item.callback.name](reportType, Item);
              this.sidebarmenuContain();
            }
          })
        )
      } else {
        //console.log(Item);
        this[Item.callback.name](reportType, Item);
        //this.sidebarmenuContain();
      }
    }

  }

  navliClick(Name, Index){ 
    document.getElementById(Name+'-'+Index).click();
    this.prviousLinkClicked = Index;
    this.SpinnerService.show();
    this.delay(300);
    this.SpinnerService.hide();
  }

  ForecastMainBankAccountsList() {
    this.SpinnerService.show();
    var ForecastNodeId = localStorage.getItem('ForecastId');
    this.ReportServices.GetForecastBankAccounts(ForecastNodeId).subscribe(
      ((response: any) => {
        this.SpinnerService.hide();
        this.ForecastMainBankAccounts = response;
        // console.log(this.ForecastMainBankAccounts);
      })
    )
  }

  SelectBankAccountIdEvent($event) {
    this.SelectBankAccountId = parseInt($event.target.value);
  }

  sidebarmenuContain() {
    this.displaySubmenustyle = '';
    // if (this.showDrawerClick = true) {
    //   this.displaySubmenustyle = 'block';
    // } else {
    //   this.displaySubmenustyle = '';
    // }
    // this.selected = [];
  }
  showDrawer() {
    this.showDrawerClick = true;
    this.hideDrawerClick = false;
    this.displaySubmenustyle = 'block';
  }
  hideDrawer() {
    this.hideDrawerClick = true;
    this.showDrawerClick = false;
    this.displaySubmenustyle = '';
  }
  changevarianceMonth(event) {
    this.VarianceMonthSelectionIndex = event;
  }
  RenderReportMenu() {
    let VariationReportOption: any;
    if (localStorage.getItem('IsCanEnterActual') == 'true') {
      this.IsActual = 'true';
      this.NavitemSubmenu = [
       // { name: 'Home', icon: 'home', type: 'home', featureKey: 'Home', displaysubmenu: [], callback: { name: 'Forecasthome', perameter: '', active: 'true' } },
        {
          name: 'Budget Reports', type: 'Budget', icon: 'account_circle', featureKey: 'BudgetReport',
          displaysubmenu: [
            { name: 'Profit And Loss', icon: 'star', type: 'Budget', featureKey: 'P&LReport', callback: { name: 'reportcollection', perameter: '990001', active: 'true' } },
            { name: 'Profit And Loss by Dept', icon: 'star', type: 'Budget', featureKey: 'P&LReportByDept', callback: { name: 'reportcollection', perameter: '990005', active: 'true' } },
            { name: 'Profit And Loss by Tracking', icon: 'star', type: 'Budget', featureKey: 'P&LReportByTracking', callback: { name: 'reportcollection', perameter: '990007', active: 'true' } },
            { name: 'Cashflow', type: 'Budget', icon: 'request_quote', featureKey: 'Cashflow', callback: { name: 'reportcollection', perameter: '990002', active: 'true' } },
            { name: 'Balance Sheet', type: 'Budget', icon: 'account_balance_wallet', featureKey: 'BalanceSheet', callback: { name: 'reportcollection', perameter: '990003', active: 'true' } },
            { name: 'Funds Flow', type: 'Budget', icon: 'wallet', featureKey: 'FundsFlow', callback: { name: 'reportcollection', perameter: '990004', active: 'true' } },
            { name: 'Close All', icon: 'disabled_by_default', type: 'Budget', featureKey: '', callback: { name: 'closeAllBudgetReport', perameter: '', active: 'true' } },
            { name: 'Show All', icon: 'disabled_visible', type: 'Budget', featureKey: '', callback: { name: 'showAllBudgetReport', perameter: '', active: 'true' } },

          ], callback: { name: '', active: 'true' }
        },
        {
          name: 'Variance Reports', type: 'Variance', icon: 'report', featureKey: 'VarianceReport',
          displaysubmenu: [
            { name: 'Profit And Loss', icon: 'star', type: 'Variance', featureKey: 'P&LVarianceReport', callback: { name: 'reportcollection', perameter: '990021', active: 'true' } },
            { name: 'Profit And Loss (by Dept)', icon: 'star', type: 'Variance', featureKey: 'P&LVarianceByDeptReport', callback: { name: 'reportcollection', perameter: '990025', active: 'true' } },
            { name: 'Cashflow', icon: 'request_quote', type: 'Variance', featureKey: 'CashflowVarianceRept', callback: { name: 'reportcollection', perameter: '990022', active: 'true' } },
            { name: 'Balance Sheet', icon: 'account_balance_wallet', type: 'Variance', featureKey: 'BalanceSheetVariance', callback: { name: 'reportcollection', perameter: '990023', active: 'true' } },
            { name: 'Funds Flow', icon: 'wallet', type: 'Variance', featureKey: 'FundsFlowVariance', callback: { name: 'reportcollection', perameter: '990024', active: 'true' } },
            { name: 'Close All', icon: 'disabled_by_default', type: 'Variance', featureKey: '', callback: { name: 'closeAllVariationReport', perameter: '', active: 'true' } },
            { name: 'Show All', icon: 'disabled_visible', type: 'Variance', featureKey: '', callback: { name: 'showAllVariationReport', perameter: '', active: 'true' } },
          ], callback: { name: '', active: 'true' }
        },
        {
          name: 'Schedules Reports', icon: 'schedule', type: 'Schedules', featureKey: 'ScheduleReport',
          displaysubmenu: [
            { name: 'Employee Schedule', icon: 'badge', type: 'Schedules', featureKey: 'EmployeeSchedule', callback: { name: 'reportcollection', perameter: '990031', active: 'true' } },
            { name: 'Fixed Asset Schedule', icon: 'web_asset', type: 'Schedules', featureKey: 'FixedAssetSchedule', callback: { name: 'reportcollection', perameter: '990032', active: 'true' } },
            { name: 'Loans Received Schedule', icon: 'event_available', type: 'Schedules', featureKey: 'LoanRecievedSchedule', callback: { name: 'reportcollection', perameter: '990033', active: 'true' } },
            { name: 'Loans Made Schedule', icon: 'home', type: 'real_estate_agent', featureKey: 'LoansMadeSchedule', callback: { name: 'reportcollection', perameter: '990034', active: 'true' } },
            { name: 'GST Schedule', icon: 'home', type: 'event_note', featureKey: 'GSTSchedule', callback: { name: 'reportcollection', perameter: '990035', active: 'true' } },
            { name: 'Assumptions', icon: 'home', type: 'psychology', featureKey: 'AssumptionSchedule', callback: { name: 'reportcollection', perameter: '990038', active: 'true' } },
            { name: 'Statistics And Ratios', icon: 'home', type: 'query_stats', featureKey: 'Statis&RatioSchedule', callback: { name: 'reportcollection', perameter: '990037', active: 'true' } },
          ], callback: { name: '', active: 'true' }
        },
        {
          name: 'Consolidation Reports', icon: 'home', type: "Consolidation", featureKey: 'ConsolidationReport', displaysubmenu: [
            { name: 'Elimination Profit and Loss Schedule', icon: 'badge', type: 'Consolidation', featureKey: 'EliminationP&LSchedule', callback: { name: 'reportcollection', perameter: '990071', active: 'true' } },
            { name: 'Elimination BalanceSheet Schedule', icon: 'web_asset', type: 'Consolidation', featureKey: 'EliminationBSSchedule', callback: { name: 'reportcollection', perameter: '990072', active: 'true' } },
            { name: 'Elimination CashFlow Schedule', icon: 'event_available', type: 'Consolidation', featureKey: 'EliminationCFSchedule', callback: { name: 'reportcollection', perameter: '990073', active: 'true' } },
            { name: 'Minority Interest Schedule', icon: 'event_note', type: 'Consolidation', featureKey: 'MinorityInterestSchedule', callback: { name: 'reportcollection', perameter: '990074', active: 'true' } },
            { name: 'Associate Interest Schedule', icon: 'query_stats', type: 'Consolidation', featureKey: 'AssociateInterestSchedule', callback: { name: 'reportcollection', perameter: '990075', active: 'true' } },
          ], callback: { name: '', active: 'true' }
        },
        {
          name: 'One Touch Reports', icon: 'star', type: "OneTouchReport", featureKey: 'OneTouchReport', 
          displaysubmenu: [
            { name: 'One Touch Report', icon: 'star', type: 'OneTouchReport', featureKey: 'OneTouchReport',  callback: { name: 'reportcollection', perameter: '9900222', active: 'true' }},
           ], callback: { name: '', active: 'true' }
        }
      ]
    } else {
      this.IsActual = 'false';
      this.NavitemSubmenu = [
       // { name: 'Home', icon: 'home', type: 'home', featureKey: 'Home', displaysubmenu: [], callback: { name: 'Forecasthome', perameter: '', active: 'true' } },
        {
          name: 'Budget Reports', icon: 'account_circle', type: 'budget', featureKey: 'BudgetReport',
          displaysubmenu: [
            { name: 'Profit And Loss', type: 'Budget', featureKey: 'P&LReport', icon: 'star', callback: { name: 'reportcollection', perameter: '990001', active: 'true' } },
            { name: 'Profit And Loss by Dept', type: 'Budget', featureKey: 'P&LReportByDept', icon: 'star', callback: { name: 'reportcollection', perameter: '990005', active: 'true' } },
            { name: 'Profit And Loss by Tracking', icon: 'star', type: 'Budget', featureKey: 'P&LReportByTracking', callback: { name: 'reportcollection', perameter: '990007', active: 'true' } },
            { name: 'Cashflow', type: 'Budget', featureKey: 'Cashflow', icon: 'request_quote', callback: { name: 'reportcollection', perameter: '990002', active: 'true' } },
            { name: 'Balance Sheet', type: 'Budget', featureKey: 'BalanceSheet', icon: 'account_balance_wallet', callback: { name: 'reportcollection', perameter: '990003', active: 'true' } },
            { name: 'Funds Flow', type: 'Budget', featureKey: 'FundsFlow', icon: 'wallet', callback: { name: 'reportcollection', perameter: '990004', active: 'true' } },
            { name: 'Close All', icon: 'disabled_by_default', type: 'Budget', featureKey: '', callback: { name: 'closeAllBudgetReport', perameter: '', active: 'true' } },
            { name: 'Show All', icon: 'disabled_visible', type: 'Budget', featureKey: '', callback: { name: 'showAllBudgetReport', perameter: '', active: 'true' } },

          ], callback: { name: '', active: 'true' }
        },
        {
          name: 'Schedules Reports', icon: 'schedule', type: 'Schedules', featureKey: 'ScheduleReport',
          displaysubmenu: [
            { name: 'Employee Schedule', icon: 'badge', type: 'Schedules', featureKey: 'EmployeeSchedule', callback: { name: 'reportcollection', perameter: '990031', active: 'true' } },
            { name: 'Fixed Asset Schedule', icon: 'web_asset', type: 'Schedules', featureKey: 'FixedAssetSchedule', callback: { name: 'reportcollection', perameter: '990032', active: 'true' } },
            { name: 'Loans Received Schedule', icon: 'event_available', type: 'Schedules', featureKey: 'LoanRecievedSchedule', callback: { name: 'reportcollection', perameter: '990033', active: 'true' } },
            { name: 'Loans Made Schedule', icon: 'real_estate_agent', type: 'Schedules', featureKey: 'LoansMadeSchedule', callback: { name: 'reportcollection', perameter: '990034', active: 'true' } },
            { name: 'GST Schedule', icon: 'event_note', type: 'Schedules', featureKey: 'GSTSchedule', callback: { name: 'reportcollection', perameter: '990035', active: 'true' } },
            { name: 'Assumptions', icon: 'psychology', type: 'Schedules', featureKey: 'AssumptionSchedule', callback: { name: 'reportcollection', perameter: '990038', active: 'true' } },
            { name: 'Statistics And Ratios', icon: 'query_stats', type: 'Schedules', featureKey: 'Statis&RatioSchedule', callback: { name: 'reportcollection', perameter: '990037', active: 'true' } },
          ], callback: { name: '', active: 'true' }
        },
        {
          name: 'Consolidation Reports', icon: 'home', type: "Consolidation", featureKey: 'ConsolidationReport', displaysubmenu: [
            { name: 'Elimination Profit and Loss Schedule', icon: 'badge', type: 'Consolidation', featureKey: 'EliminationP&LSchedule', callback: { name: 'reportcollection', perameter: '990071', active: this.isConsolidation } },
            { name: 'Elimination BalanceSheet Schedule', icon: 'web_asset', type: 'Consolidation', featureKey: 'EliminationBSSchedule', callback: { name: 'reportcollection', perameter: '990072', active: this.isConsolidation } },
            { name: 'Elimination CashFlow Schedule', icon: 'event_available', type: 'Consolidation', featureKey: 'EliminationCFSchedule', callback: { name: 'reportcollection', perameter: '990073', active: this.isConsolidation } },
            { name: 'Minority Interest Schedule', icon: 'event_note', type: 'Consolidation', featureKey: 'MinorityInterestSchedule', callback: { name: 'reportcollection', perameter: '990074', active: this.isConsolidation } },
            { name: 'Associate Interest Schedule', icon: 'query_stats', type: 'Consolidation', featureKey: 'AssociateInterestSchedule', callback: { name: 'reportcollection', perameter: '990075', active: this.isConsolidation } },
          ], callback: { name: '', active: this.isConsolidation }
        },
        {
          name: 'One Touch Reports', icon: 'star', type: "OneTouchReport", featureKey: 'OneTouchReport', 
          displaysubmenu: [
            { name: 'One Touch Report', icon: 'star', type: 'OneTouchReport', featureKey: 'OneTouchReport',  callback: { name: 'reportcollection', perameter: '9900222', active: 'true' }},
           ], callback: { name: '', active: 'true' }
        }
      ]
    }

  }

  reportCollectionIndexRemove(Index) {
    setTimeout(() => {
      this.SpinnerService.show();
    }, 400);
    $('.nav-collection-'+this.ReportTabActiveindex).removeClass('active');
    this.allTabsnameArray.splice(Index, 1);
    this.ReportTabActiveindex = Index;
    this.ReportCollectionArrayResult.splice(Index, 1);
    $('.nav-collection-'+Index).addClass('active');
    setTimeout(() => {
      this.SpinnerService.hide();
    }, 700);

    
  }
  exportexcel(table_id) {
    var filename = 'export_' + parseInt(table_id) + '_' + new Date().toLocaleDateString() + '.xlsx';
    let element = document.getElementById('table-' + parseInt(table_id));
    console.log(table_id);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename);
  }

  closeAllVariationReport() {
    if (this.ReportCollectionArrayResult.length > 0) {
      let arrayVariation = [];
      this.ReportCollectionArrayResult.forEach((element, Index) => {
        if (element.type == 'Variance') {
          arrayVariation.push(Index);
        }
      });
      if (arrayVariation.length > 0) {
        for (var i = arrayVariation.length - 1; i >= 0; i--) {
          this.allTabsnameArray.splice(arrayVariation[i], 1);
          this.ReportCollectionArrayResult.splice(arrayVariation[i], 1);
        }
      }
    }
  }

  showAllVariationReport() {
    let variationReportShowAll = [
      { name: 'Profit And Loss', type: 'Variance', callback: { name: 'reportcollection', perameter: '990021', active: 'true' } },
      { name: 'Cashflow', type: 'Variance', callback: { name: 'reportcollection', perameter: '990022', active: 'true' } },
      { name: 'Balance Sheet', type: 'Variance', callback: { name: 'reportcollection', perameter: '990023', active: 'true' } },
      { name: 'Funds Flow', type: 'Variance', callback: { name: 'reportcollection', perameter: '990024', active: 'true' } },
    ];

    variationReportShowAll.forEach(async element => {
      await this.reportcollection(element.callback.perameter, element);
      this.delay(300);
    });

  }


  closeAllBudgetReport() {
    if (this.ReportCollectionArrayResult.length > 0) {
      let arrayVariation = [];
      this.ReportCollectionArrayResult.forEach((element, Index) => {
        if (element.type == 'Budget') {
          arrayVariation.push(Index);
        }
      });
      if (arrayVariation.length > 0) {
        for (var i = arrayVariation.length - 1; i >= 0; i--) {
          this.allTabsnameArray.splice(arrayVariation[i], 1);
          this.ReportCollectionArrayResult.splice(arrayVariation[i], 1);
        }
      }
    }
  }

  showAllBudgetReport() {
    let BudgetReportShowAll = [
      { name: 'Profit And Loss', type: 'Budget', featureKey: 'P&LReport', icon: 'star', callback: { name: 'reportcollection', perameter: '990001', active: 'true' } },
      { name: 'Cashflow', type: 'Budget', featureKey: 'Cashflow', icon: 'request_quote', callback: { name: 'reportcollection', perameter: '990002', active: 'true' } },
      { name: 'Balance Sheet', type: 'Budget', featureKey: 'BalanceSheet', icon: 'account_balance_wallet', callback: { name: 'reportcollection', perameter: '990003', active: 'true' } },
      { name: 'Funds Flow', type: 'Budget', featureKey: 'FundsFlow', icon: 'wallet', callback: { name: 'reportcollection', perameter: '990004', active: 'true' } },
    ];

    BudgetReportShowAll.forEach(async element => {
      await this.reportcollection(element.callback.perameter, element);
      this.delay(300);
    });

  }


  getPeriodTabView(forecastNodeId) {
    this.PeriodTabView = [];
    this.ReportServices.getPeriodCollection(forecastNodeId).subscribe(
      ((response: any) => {
        this.PeriodTabView = response.getPeriodTabView;
        this.ActivePeriodTab = 0;
      })
    )
  }
  getVarianceMonths(forecastNodeId) {
    this.ReportServices.getVarianceMonthCollection(forecastNodeId).subscribe(
      ((response: any) => {
        this.VarianceMonthColleciton = response;
        console.log("getVarianceMonths", this.VarianceMonthColleciton);
      })
    )

  }

 async getForecastbudgetReport(ForecastNodeId) {
    this.SpinnerService.show();
  await  this.ReportServices.getForecastBudgetReport(ForecastNodeId).subscribe(
      ((response: any) => {
       // this.SpinnerService.hide();
        this.ForecastbudgetReport = response;
        let result: any = this.ForecastbudgetReport;
        // console.log("getForecastbudgetReport", result);
        this.ForecastReportBudgteNodeId = result[0].nodeId;
        if (result[0].englishLabel == "Rolling Budget") {
          this.isRolling = true;
        }
        else {
          this.isRolling = false;
        }
      })
    )
  }

  ForecastbudgteReporChange($event) {
    let NodeId = $event.target.value;
    this.ForecastReportBudgteNodeId = NodeId;
    this.ForecastbudgetReport.forEach(element => {
      console.log("ForecastbudgteReporChange", element, NodeId)
      if (element.nodeId == NodeId) {
        if (element.englishLabel == "Rolling Budget") {
          this.isRolling = true;
        }
        else {
          this.isRolling = false;
        }
      }
    });
    this.ReportServices.setForecastBudgetReport(this.activeId, NodeId).subscribe(
      ((response: any) => {
        try {
          return response;
        } catch (error) {
          return error;
        }
      })
    )
  }

  async ngOnInit() {
    this.activenodeId = [];
    this.allTabsdataArray = [];
    this.allTabsnameArray = [];
    let reporttypesarray: any = [];
    let Reporttype = '90001';
    this.newMontharrayStructure = [];
    this.newInsideMontharrayStructure = [];
    this.displaytotal = [];
    let forecastNodeId = localStorage.getItem("ForecastId");
    this.getPeriodTabView(forecastNodeId);
    this.getVarianceMonths(forecastNodeId);
    this.NavitemSubmenu = [];
    this.RenderReportMenu();
    this.getForecastbudgetReport(this.activeId);
    // this.ForecastMainBankAccountsList();
    this.NavitemSubmenu.forEach(element => {
      if (element.name == "Budget Report") {
        this.navigate(element);
      }
    });
    this.SpinnerService.show();
    let BudgetRecordCalculation = localStorage.getItem("CurrencyCalculationneeded");
    if(BudgetRecordCalculation!=null && BudgetRecordCalculation == "true") {
    await  this.ReportServices.BudgetRecordRecalu(forecastNodeId).subscribe(
      ( data:any)=>{
          localStorage.setItem("CurrencyCalculationneeded","false");
          BudgetRecordCalculation = "false";
       this.ReportServices.getReportdDetail(this.activeId, Reporttype).pipe(take(1)).subscribe
      (async (response) => {
        this.SpinnerService.hide();
        this.defualtreportDisplayData = [];
        this.defualtreportDsiplayvaluedata = [];
        if (response != null) {
          if (response != null) {
            this.defualtreportDisplayData.push(response);
            let newarrayvararray = this.months;
            this.newMontharrayStructure.push(newarrayvararray);

            let newarrayinsideMonth = this.insidemonths;
            this.newInsideMontharrayStructure.push(newarrayinsideMonth);

            reporttypesarray = [];
            let data: any = this.defualtreportDisplayData;
            this.defualtreportDisplayData.forEach(data => {
              //reporttypesarray.push(data.reportType);
            });
          }
          await this.delay(800);
          this.defualtreportDsiplayvaluedata = [];
         
          if (reporttypesarray != '') {
            let GetFrequency = "Monthly";
            reporttypesarray.forEach((element, index) => {
              this.displaytotal = [];
              this.SpinnerService.show();
              this.ReportServices.getReportData(this.activeId, element, GetFrequency, 0, this.ForecastReportBudgteNodeId, this.SelectBankAccountId).subscribe(
                (response: Report[]) => {
                  // console.log("GetReportData ngonint", response);
                  this.SpinnerService.hide();
                  if (response != null && response.length > 0) {
                    let data: any = response;
                    let datavalue: any = data[0].row;
                    this.reportjson.forEach(jasondata => {
                      if (jasondata.value == parseInt(element)) {
                        if (this.isRolling) {
                          let jasonArrayData = {
                            Name: element.name,
                            Label: element.label + " - Rolling",
                          }
                          this.activenodeId.push(jasondata.name + " - Rolling");
                          this.allTabsnameArray.push(jasonArrayData);
                        } else {
                          let jasonArrayData = {
                            Name: element.name,
                            Label: element.label + " - Rolling",
                          }
                          this.activenodeId.push(jasondata.name);
                          this.allTabsnameArray.push(jasonArrayData);
                        }

                      }
                    });
                    this.defualtreportDsiplayvaluedata.push(datavalue);
                    this.allTabsdataArray.push(datavalue.table);
                    // console.log(this.allTabsdataArray);
                    this.allTabsdataArray.forEach(element => {
                      element["children"] = null;
                    });
                  }

                }
              )
            });
          }
        }
  })
        }
      )
    } else {
      await  this.ReportServices.getReportdDetail(this.activeId, Reporttype).pipe(take(1)).subscribe
      (async (response) => {
        this.SpinnerService.hide();
        this.defualtreportDisplayData = [];
        this.defualtreportDsiplayvaluedata = [];
        if (response != null) {
          if (response != null) {
            this.defualtreportDisplayData.push(response);
            let newarrayvararray = this.months;
            this.newMontharrayStructure.push(newarrayvararray);

            let newarrayinsideMonth = this.insidemonths;
            this.newInsideMontharrayStructure.push(newarrayinsideMonth);

            reporttypesarray = [];
            let data: any = this.defualtreportDisplayData;
            this.defualtreportDisplayData.forEach(data => {
              //reporttypesarray.push(data.reportType);
            });
          }
          await this.delay(800);
          this.defualtreportDsiplayvaluedata = [];
         
          if (reporttypesarray != '') {
            let GetFrequency = "Monthly";
            reporttypesarray.forEach((element, index) => {
              this.displaytotal = [];
              this.SpinnerService.show();
              this.ReportServices.getReportData(this.activeId, element, GetFrequency, 0, this.ForecastReportBudgteNodeId, this.SelectBankAccountId).subscribe(
                (response: Report[]) => {
                  // console.log("GetReportData ngonint", response);
                  this.SpinnerService.hide();
                  if (response != null && response.length > 0) {
                    let data: any = response;
                    let datavalue: any = data[0].row;
                    this.reportjson.forEach(jasondata => {
                      if (jasondata.value == parseInt(element)) {
                        if (this.isRolling) {
                          let jasonArrayData = {
                            Name: element.name,
                            Label: element.label + " - Rolling",
                          }
                          this.activenodeId.push(jasondata.name + " - Rolling");
                          this.allTabsnameArray.push(jasonArrayData);
                        } else {
                          let jasonArrayData = {
                            Name: element.name,
                            Label: element.label + " - Rolling",
                          }
                          this.activenodeId.push(jasondata.name);
                          this.allTabsnameArray.push(jasonArrayData);
                        }

                      }
                    });
                    this.defualtreportDsiplayvaluedata.push(datavalue);
                    this.allTabsdataArray.push(datavalue.table);
                    // console.log(this.allTabsdataArray);
                    this.allTabsdataArray.forEach(element => {
                      element["children"] = null;
                    });
                  }

                }
              )
            });
          }
        }
  })
    }
  
      
    await this.delay(900);
  }


  checkVal(array, value) {
    let activearrow: boolean;
    array.forEach(async (element, index) => {
      if (element.parentRowNum === value.rowNum) {
        activearrow = true;
      }
    });
    if (activearrow == true) {
      return true;
    } else {
      return false;
    }
  }

  tablecollapse(parentIdvalue) {
    // console.log(parentIdvalue);
    if ($('.displaydata-' + parentIdvalue).hasClass('hidden')) {
      // console.log('has a class');
      $('.displaydata-' + parentIdvalue).removeClass('hidden');
    } else {
      // console.log('not has a class');
      $('.displaydata-' + parentIdvalue).addClass('hidden');
    }
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName('displaydata-' + parentIdvalue);
  }

  trackByFn(index: any, item: any) {
    return index + 1;
  }
  trackByMethod(index: number, el: any): number {
    return el.id;
  }

  exportCsv(table_id, separator = ',') {
    if (table_id != '') {
      var rows = document.querySelectorAll('#reportGrid-' + table_id + ' tr');
      var csv = [];
      if (rows != null) {
        for (var i = 0; i < rows.length; i++) {
          var row = [], cols = rows[i].querySelectorAll('td, th');
          for (var j = 0; j < cols.length; j++) {
            if ((<HTMLElement>cols[j]).innerText != null) {
              var data = (<HTMLElement>cols[j]).innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ');
              data = data.replace(/"/g, '""');
              row.push('"' + data + '"');
            }

          }
          csv.push(row.join(separator));
        }
        var csv_string = csv.join('\n');
        var filename = 'export_' + table_id + '_' + new Date().toLocaleDateString() + '.xlsx';
        var link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:text/xlsx;charset=utf-8,' + encodeURIComponent(csv_string));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }

  }


  copytoclipboard(table_id) {
    let table: any = document.getElementById('reportGrid');

    console.log("table", table)
    table.append(style);
    var style = "<style>";
    style = style + "table {width: 100%;font: 17px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";
    table.style = style;
    this.selectNode(table);
    document.execCommand('copy')

  }
  selectNode(node) {
    let range = document.createRange();
    range.selectNodeContents(node)
    let select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)
  }

  printReport(Id) {
    var tab = document.getElementById('reportGrid' + Id);
    var style = "<style>";
    style = style + "table {width: 100%;font: 17px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";
    var win = window.open('', '', 'height=700,width=700');
    win.document.write(style);
    win.document.write(tab.outerHTML);
    win.document.close();
    win.print();
  }

  CashFlowBankAccountForecastEvent(CashFlowBankAccount) {
    CashFlowBankAccount.close();
    let item = localStorage.getItem("CashflowItem");
    console.log("item", item);
    this.reportcollection(990002, item)
  }
  reportcollection(Reporttypes, Item) {
    console.log("reportcollection", Reporttypes, Item);
    if (Reporttypes == 9900222) {
      this._router.navigateByUrl('/OTRReport');
      return;
    }
    let index = 0;
    if (Reporttypes == 990021 || Reporttypes == 990025 || Reporttypes == 990023 || Reporttypes == 990024 || Reporttypes == 990022) {
      index = this.VarianceMonthSelectionIndex;
    }
    let ForecastId = this.activeId;
    this.collectionArray = [];
    let colletionArrayResultValues = [];
    this.defualtreportDsiplayvaluedata = []
    let collectionArrayValues = [];
    let GetFrequency = "Monthly";
    this.SpinnerService.show();
    const notifier = this.injector.get(NotificationService);
    this.ReportServices.getReportData(ForecastId, parseInt(Reporttypes), GetFrequency, index, this.ForecastReportBudgteNodeId, this.SelectBankAccountId).subscribe(
      (response: Report[]) => { 
        try {
          this.SpinnerService.hide();
        if (response != null && response.length > 0) {
          collectionArrayValues = [];
          var nameArr = (Object.keys(response[0].row.table[0]).toString()).split(',');

          if(Reporttypes == "990038"){
              collectionArrayValues.push(nameArr[3]);
          } else {
            for (var i = 10; i < nameArr.length; i++) { 
              collectionArrayValues.push(nameArr[i]);
            }
          }

          if (Item.type == "Consolidation") {
            collectionArrayValues.splice(0, 1, "sourceNodeId");
          }
          let data: any = response;
          let datavalue = data[0].row;
          this.defualtreportDsiplayvaluedata.push(datavalue.table);
          colletionArrayResultValues.push(datavalue.table);
          this.reportjson.forEach(element => {
            if (element.value == parseInt(Reporttypes)) {
              if (this.isRolling) {
                let jasonArrayData = {
                  Name: element.name,
                  Label: element.label + " - Rolling",
                }
                this.activenodeId.push(element.name + " - Rolling");
                this.allTabsnameArray.push(jasonArrayData);
              } else {
                let jasonArrayData = {
                  Name: element.name,
                  Label: element.label,
                }
                this.activenodeId.push(element.name);
                this.allTabsnameArray.push(jasonArrayData);
              }
            }
          });
          
          let arraytree = [];
          colletionArrayResultValues.forEach(element => { 
              if(element.name == null){
                  element.name = " - ";
              } 
              element.forEach(elements => {
                elements["children"] = null;
              });
              

              // if (length > 10) {
              //   return '6%';
              // }
              // if (collection.Frequency == "Quarterly" && length < 4) {
              //   return '40%';
              // }
              // if (collection.Frequency == "Annually") {
              //   return '25%';
              // }

              arraytree.push(element);
          });

          let ArrayjsonCollectionArrayValues = [];

          collectionArrayValues.forEach(element => { 
           let jsonCollectionArrayValues = {
              name : element,
              width: '6%',
            }
            if(element == "sourceNodeId"){ 
              jsonCollectionArrayValues.width = '15%';
            }
             if (collectionArrayValues.length > 10) {
              jsonCollectionArrayValues.width = '6%';
            }
           
            if (GetFrequency == "Quarterly" && collectionArrayValues.length < 4) {
              jsonCollectionArrayValues.width = '40%';
            }
            if (GetFrequency == "Quarterly" && collectionArrayValues.length == 5) {
              jsonCollectionArrayValues.width = '15%';
            }
            if (GetFrequency == "Annually") {
              jsonCollectionArrayValues.width = '25%';
            }
            if(Reporttypes == "990038"){ 
              jsonCollectionArrayValues.width = '50%';
            } 
           
            ArrayjsonCollectionArrayValues.push(jsonCollectionArrayValues);
          });

          this.allTabsdataArray = arraytree;
          let items = { BudgetResult: arraytree, CollectionColumn: collectionArrayValues, type: Item.type , Frequency: GetFrequency, CustomWidth: ArrayjsonCollectionArrayValues}
          this.ReportCollectionArrayResult.push(items);
          this.ReportTabActiveindex = this.allTabsnameArray.length-1;
          this.delay(100);
          console.log(this.allTabsnameArray);
          if(this.allTabsnameArray.length>0){ 
            let lengthData = this.allTabsnameArray.length -1;
          } else {
            this.ReportTabActiveindex = 0;
          }
        } else {  
          if(Reporttypes == "990038") {
              let responseDefault = {
                "rowVersion": null,
                "row": {
                    "rowError": "",
                    "rowState": 2,
                    "table": [
                        {
                            "parentRowNum": null,
                            "rowNum": 1,
                            "name": "",
                            "description": "",
                            "nodeType": 50033,
                            "id": 2709,
                            "nodePath": "/0001/0011/0000",
                            "isEliminated": false
                        }
                    ],
                    "itemArray": [
                        null,
                        1,
                        "sales",
                        "based on sales",
                        50033,
                        2709,
                        "/0001/0011/0000",
                        false
                    ],
                    "hasErrors": false
                },
                "isNew": false,
                "isEdit": false
              }
              collectionArrayValues = [];
            var nameArr = (Object.keys(responseDefault.row.table[0]).toString()).split(',');

            if(Reporttypes == "990038"){
                collectionArrayValues.push(nameArr[3]);
            } 

            if (Item.type == "Consolidation") {
              collectionArrayValues.splice(0, 1, "sourceNodeId");
            }
            let data: any = responseDefault;
            let datavalue = data.row;
            this.defualtreportDsiplayvaluedata.push(datavalue.table);
            colletionArrayResultValues.push(datavalue.table);
            this.reportjson.forEach(element => {
              if (element.value == parseInt(Reporttypes)) {
                if (this.isRolling) {
                  let jasonArrayData = {
                    Name: element.name,
                    Label: element.label + " - Rolling",
                  }
                  this.activenodeId.push(element.name + " - Rolling");
                  this.allTabsnameArray.push(jasonArrayData);
                } else {
                  let jasonArrayData = {
                    Name: element.name,
                    Label: element.label,
                  }
                  this.activenodeId.push(element.name);
                  this.allTabsnameArray.push(jasonArrayData);
                }
              }
            });
            
            let arraytree = [];
            colletionArrayResultValues.forEach(element => { 
                if(element.name == null){
                    element.name = " - ";
                } 
                element.forEach(elements => {
                  elements["children"] = null;
                });
                arraytree.push(element);
              
            });
            let ArrayjsonCollectionArrayValues = [];

            collectionArrayValues.forEach(element => { 
             let jsonCollectionArrayValues = {
                name : element,
                width: '6%',
              }
              if(element == "sourceNodeId"){ 
                jsonCollectionArrayValues.width = '15%';
              }
               if (collectionArrayValues.length > 10) {
                jsonCollectionArrayValues.width = '6%';
              }
             
              if (GetFrequency == "Quarterly" && collectionArrayValues.length < 4) {
                jsonCollectionArrayValues.width = '40%';
              }
              if (GetFrequency == "Quarterly" && collectionArrayValues.length == 5) {
                jsonCollectionArrayValues.width = '15%';
              }
              if (GetFrequency == "Annually") {
                jsonCollectionArrayValues.width = '25%';
              }
              if(Reporttypes == "990038"){ 
                jsonCollectionArrayValues.width = '50%';
              } 
             
              ArrayjsonCollectionArrayValues.push(jsonCollectionArrayValues);
            });
            this.allTabsdataArray = arraytree;
            let items = { BudgetResult: arraytree, CollectionColumn: collectionArrayValues, type: Item.type , Frequency: GetFrequency,CustomWidth: ArrayjsonCollectionArrayValues }
            this.ReportCollectionArrayResult.push(items);
            this.ReportTabActiveindex = this.allTabsnameArray.length-1;
            this.delay(100);
            if(this.allTabsnameArray.length>0){ 
              let lengthData = this.allTabsnameArray.length -1;
            } else {
              this.ReportTabActiveindex = 0;
            }
          } else {
            notifier.showError("No data to display.");
          }
          
          
        }
        } catch (error) {
          this.SpinnerService.hide();
          notifier.showError("Your Session has been expired please login");
          this._router.navigateByUrl("login");
        }
        
      },
      // err => {
      //   this.SpinnerService.hide();
      //   throw err;
      // }
    )

  }

  async changeFrequency($event, resultsData) {

    let reporttypesarray: any = [];
    let GetFrequency = "";
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName('tabscontentclass active')[0].id;
    const slug = totalgetelemtbyclassnamearray.substring(totalgetelemtbyclassnamearray.indexOf('-') + 1);
    let reportselectedtype = this.allTabsnameArray[slug].Name;
    if ($event == "Monthly") {
      GetFrequency = "Monthly";
    }
    if ($event == "Quarterly") {
      GetFrequency = "Quarterly";
    }
    if ($event == "Annually") {
      GetFrequency = "Annually";
    }
    this.ActiveFrequncy = GetFrequency;
    this.reportjson.forEach(jasondata => {
      let dataArray = [];
      let collectionArrayResult = [];
      if (jasondata.name == reportselectedtype) {
        this.SpinnerService.show();
        this.ReportCollectionArrayResult[slug] = [];
        this.ReportServices.getReportData(this.activeId, jasondata.value, GetFrequency, this.ActivePeriodTab, this.ForecastReportBudgteNodeId, this.SelectBankAccountId).subscribe(
          (response: Report[]) => {
            this.SpinnerService.hide();
            if (response != null && response.length > 0) {
              var nameArr = (Object.keys(response[0].row.table[0]).toString()).split(',');
              console.log(nameArr);
              if(nameArr[10] == "resultNodeId"){
                  const tempdata = nameArr[10];
                  nameArr[10] = nameArr[0];
                  nameArr[0] = tempdata;
              }

              if(reportselectedtype == "AssumptionSchedule"){
                collectionArrayResult.push(nameArr[3]);
              } else {
                for (var i = 10; i < nameArr.length; i++) { 
                  collectionArrayResult.push(nameArr[i]);
                }
              }

              let data: any = response;
              let datavalue = data[0].row;
              dataArray.push(datavalue.table);
              let arraytree = [];
              dataArray.forEach(element => {
                element.forEach(elements => {
                  elements["children"] = null;
                });
                arraytree.push(element);
              });

              let ArrayjsonCollectionArrayValues = [];
              collectionArrayResult.forEach(element => { 
                let jsonCollectionArrayValues = {
                   name : element,
                   width: '6%',
                 }
                 if(element == "sourceNodeId"){ 
                   jsonCollectionArrayValues.width = '15%';
                 }
                  if (collectionArrayResult.length > 10) {
                   jsonCollectionArrayValues.width = '6%';
                 }
                
                 if (GetFrequency == "Quarterly" && collectionArrayResult.length < 4) {
                   jsonCollectionArrayValues.width = '40%';
                 }
                 if (GetFrequency == "Quarterly" && collectionArrayResult.length == 5) {
                  jsonCollectionArrayValues.width = '15%';
                }
                 if (GetFrequency == "Annually") {
                   jsonCollectionArrayValues.width = '25%';
                 }
                 if(reportselectedtype == "AssumptionSchedule"){ 
                  jsonCollectionArrayValues.width = '50%';
                 }
                
                 ArrayjsonCollectionArrayValues.push(jsonCollectionArrayValues);
               });
              
              let items = { BudgetResult: arraytree, CollectionColumn: collectionArrayResult, type: resultsData.type , Frequency: GetFrequency, CustomWidth: ArrayjsonCollectionArrayValues }
              this.ReportCollectionArrayResult[slug] = items;
              console.log(items);

            } else { 

              if(reportselectedtype == "AssumptionSchedule") {
                let responseDefault = {
                  "rowVersion": null,
                  "row": {
                      "rowError": "",
                      "rowState": 2,
                      "table": [
                          {
                              "parentRowNum": null,
                              "rowNum": 1,
                              "name": "",
                              "description": "",
                              "nodeType": 50033,
                              "id": 2709,
                              "nodePath": "/0001/0011/0000",
                              "isEliminated": false
                          }
                      ],
                      "itemArray": [
                          null,
                          1,
                          "sales",
                          "based on sales",
                          50033,
                          2709,
                          "/0001/0011/0000",
                          false
                      ],
                      "hasErrors": false
                  },
                  "isNew": false,
                  "isEdit": false
                }
                var nameArr = (Object.keys(responseDefault.row.table[0]).toString()).split(',');
                console.log(nameArr);
                if(nameArr[10] == "resultNodeId"){
                    const tempdata = nameArr[10];
                    nameArr[10] = nameArr[0];
                    nameArr[0] = tempdata;
                }
                if(reportselectedtype == "AssumptionSchedule"){
                  collectionArrayResult.push(nameArr[3]);
                } else {
                  for (var i = 10; i < nameArr.length; i++) { 
                    collectionArrayResult.push(nameArr[i]);
                  }
                }
                let data: any = responseDefault;
                let datavalue = data.row;
                dataArray.push(datavalue.table);
                let arraytree = [];
                dataArray.forEach(element => {
                  element.forEach(elements => {
                    elements["children"] = null;
                  });
                  arraytree.push(element);
                });
                
                let ArrayjsonCollectionArrayValues = [];
              collectionArrayResult.forEach(element => { 
                let jsonCollectionArrayValues = {
                   name : element,
                   width: '6%',
                 }
                 if(element == "sourceNodeId"){ 
                   jsonCollectionArrayValues.width = '15%';
                 }
                  if (collectionArrayResult.length > 10) {
                   jsonCollectionArrayValues.width = '6%';
                 }
                
                 if (GetFrequency == "Quarterly" && collectionArrayResult.length < 4) {
                   jsonCollectionArrayValues.width = '40%';
                 }
                 if (GetFrequency == "Quarterly" && collectionArrayResult.length == 5) {
                  jsonCollectionArrayValues.width = '15%';
                }
                 if (GetFrequency == "Annually") {
                   jsonCollectionArrayValues.width = '25%';
                 }

                 if(reportselectedtype == "AssumptionSchedule"){ 
                  jsonCollectionArrayValues.width = '50%';
                 }
                
                 ArrayjsonCollectionArrayValues.push(jsonCollectionArrayValues);
               });

                let items = { BudgetResult: arraytree, CollectionColumn: collectionArrayResult, type: resultsData.type , Frequency: GetFrequency,CustomWidth: ArrayjsonCollectionArrayValues }
                this.ReportCollectionArrayResult[slug] = items;
                console.log(items);
              }
             
            }
          }
        )
      }
    });


  }
  public exportButtonHandler() {
    this.excelExportService.export(this.treeGrid, new IgxExcelExporterOptions('ExportedDataFile'));
  }
  ngOnChanges() {
    // this.ForecastMainBankAccountsList();
    this.getForecastbudgetReport(this.activeId);
  }

  async changePeriodYears(index, resultsData) {
    this.ActivePeriodTab = index;
    this.SpinnerService.show();
    let reporttypesarray: any = [];
    let GetFrequency = "Monthly";
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName('tabscontentclass active')[0].id;
    const slug = totalgetelemtbyclassnamearray.substring(totalgetelemtbyclassnamearray.indexOf('-') + 1);
    let reportselectedtype = this.allTabsnameArray[slug].Name;
    this.reportjson.forEach(jasondata => {
      let dataArray = [];
      let collectionArrayResult = [];
      if (jasondata.name == reportselectedtype) {
        this.ReportServices.getReportData(this.activeId, jasondata.value, GetFrequency, index, this.ForecastReportBudgteNodeId, this.SelectBankAccountId).subscribe(
          (response: Report[]) => {
            if (response != null && response.length > 0) {
              var nameArr = (Object.keys(response[0].row.table[0]).toString()).split(',');
              if (GetFrequency == 'Annually') {
                collectionArrayResult = [nameArr[0], nameArr[nameArr.length - 1]];
              } else {
                for (var i = 10; i < nameArr.length; i++) {
                  collectionArrayResult.push(nameArr[i]);
                }
              }
              let data: any = response;
              let datavalue = data[0].row;
              dataArray.push(datavalue.table);
              let arraytree = [];
              dataArray.forEach(element => {
                element.forEach(elements => {
                  elements["children"] = null;
                });
                arraytree.push(element);
              });
              let items = { BudgetResult: arraytree, CollectionColumn: collectionArrayResult, type: resultsData.type , Frequency: GetFrequency }
              this.ReportCollectionArrayResult[slug] = items;
              this.SpinnerService.hide();
            }
          }
        )
      }
    });
  }

  handleRowSelection(row, evet) {
    console.log(row, row.rowData['parentRowNum']);
  }

  public background = (row: RowType) => row.data['rowNum'] === '1' ? 'red' : null;


  public rowStyles = {
    background: this.background,
    'font-weight': (row: RowType) => row.data['parentRowNum'] === 1 ? 'bold' : null,
    'Height': '30px'
  };

  private upFontCondition = (rowData: any, columnKey: any): boolean => {
    return rowData['parentRowNum'] == null;
  }
  private zeroCondition = (rowData: any, columnKey: any): boolean => {
    return rowData[columnKey] === 0;
  }

  public beatsPerMinuteClasses = {
    boldFont: this.upFontCondition

  };
  public zeroValueAndBoldClasses = {
    boldFont: this.upFontCondition,
    displayzero: this.zeroCondition
  }
  // public checkWidth = (rowData: any, length: any, collection:any): any => {
  //   if (rowData == "sourceNodeId") {
  //     return '15%'
  //   }
  //   if (length > 10) {
  //     return '6%';
  //   }
  //   if (collection.Frequency == "Quarterly" && length < 4) {
  //     return '40%';
  //   }
  //   if (collection.Frequency == "Annually") {
  //     return '25%';
  //   }
  // }

  public GetLabelName(Name) {
    this.reportjson.forEach(element => {
      if (element.name == Name) {
        return 'profitandLoss';
      }
    });
  }
}
