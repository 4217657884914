import { Component, OnInit,Injector } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { XeroService } from '../service/xero.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-xero-authentication',
  templateUrl: './xero-authentication.component.html',
  styleUrls: ['./xero-authentication.component.scss']
})
export class XeroAuthenticationComponent implements OnInit {

  Xerotenants = [];
  selectedOrganization = "";
  Activetabmenu: any;
  displayMaping: boolean;
  ForecastId: any

  trackingCodevalue: boolean;
  archiveAccount: boolean;
  allGLAccount: boolean;
  displayMappingButton: boolean;
  selectedTennantId: any;
  displayConnect: boolean;
  displayDisconnect: boolean;
  displayButtonMaping: boolean;
  displayButtonImport:boolean;
  displayImport:boolean
  XeroAutorizationisConnected:boolean;
  SelectedNodeDetailBinding:any;
  displayImportButton:boolean;
  constructor(private xeroService: XeroService,
    private _router: Router,
    private SpinnerService: NgxSpinnerService,
    private injector: Injector
  ) {
    this.ForecastId = localStorage.getItem("ForecastId");
  }

  async ngOnInit() {
    this.displayConnect = false;
    this.displayImport = false;
    if (this.ForecastId == '') {
      this._router.navigateByUrl('/client');
    }
    let SelectedNodeDetailBinding =  localStorage.getItem("SelectedNodeDetailBinding");
    this.SelectedNodeDetailBinding = SelectedNodeDetailBinding;
    this.Activetabmenu = 0;
    this.displayMaping = false;
    this.trackingCodevalue = false;
    this.archiveAccount = false;
    this.allGLAccount = false;
    this.selectedTennantId = '';
    this.displayDisconnect = false;
    this.XeroAutorizationisConnected = false;
    this.displayButtonMaping = false;
    this.displayButtonImport = false;
    let IntegrationIsActive = localStorage.getItem("IntegrationActive");
    let IntegrationWithName = localStorage.getItem("IntegrationWithName");
    if(IntegrationIsActive){
      if(IntegrationWithName!="Not Connected"){
        this.XeroAutorizationisConnected = true;
        this.SpinnerService.show();
        await this.xeroService.getSelectedTenant(this.ForecastId).subscribe(
            ((response: any) => {
              if (response != null) {
                this.SpinnerService.hide();
                this.selectedTennantId = response.tenantId.toString();
                if (this.selectedTennantId != '') {
                  this.displayConnect = false;
                  this.displayButtonMaping = true;
                  this.displayDisconnect = true;
                  this.GetXerotenants();
                }
              }
            })
          )
        }
    }
    this.xeroService.getIntegrationSettingDetails(this.ForecastId).subscribe(
      ((response: any) => {
        // console.log(response);
        this.trackingCodevalue = response.includeTrackingCodes;
        this.archiveAccount = response.includeArchivedGL;
        this.allGLAccount = response.includeAllAccounts;
      })
    )
   
  }
  ngOnChanges() {
    this.ngOnInit();
  }

  

  IntegrationDetails = [
    { name: "Settings", longLable: "Settings" },
    { name: "ImportData", longLable: "ImportData" },
    { name: "CreateMaping", longLable: "CreateMaping" }]

  XeroAuthentication() {
    let ForecastId = localStorage.getItem("ForecastId");
    this.xeroService.XeroAuthentication(ForecastId).subscribe(
      ((response: any) => {
        var inputValue: any = (<HTMLInputElement>document.getElementById("xeroBuildUrl"));
        if (inputValue != null) {
          inputValue.value = response;
          window.open(response, "_blank");
        }
      })
    )
  }

  ChangePeriodType(data, i) {
    this.Activetabmenu = parseInt(i);
  }

  OpenLink() {
  }

  GetXerotenants() {
    this.Xerotenants = [];
    const notifier = this.injector.get(NotificationService);
    let ForecastId = localStorage.getItem("ForecastId");
    this.xeroService.XeroTenants(ForecastId).subscribe(
      ((response: any) => { 
        if(response == null) {
          notifier.showError("Please Authenticate using the click Xero Authentication");
          return;
        } else {
          this.Xerotenants = response;
          this.displayConnect = true;
          this.selectedOrganization = this.Xerotenants[0].tenantId.toString();
          this.selectedTennantId = this.Xerotenants[0].tenantId.toString();
        }
      })
    )
  }
  changeOrganization($event) {
    this.selectedOrganization = $event.target.value;
    if (this.selectedOrganization != '') {
      this.displayConnect = true;
      this.displayButtonMaping = false;
      this.displayDisconnect = false;
    }

  }
  ImportIntegration(){
    this.displayImport = true;
    this.displayMaping = false;
    //this._router.navigateByUrl("/integration-import");
  }

  SaveXeroOrganization() {
    this.displayMaping = false;
    if (this.selectedOrganization == null) {
      window.location.reload();
    }
    this.SpinnerService.show();
    this.xeroService.getSelectedTenant(this.ForecastId).subscribe(
      ((result: any) => {
        if (result != null) {
          this.selectedTennantId = result.tenantId.toString();
          if (this.selectedTennantId == this.selectedOrganization) {
            window.location.reload();
          }
        }
        let tenantName = "";
        let tenantId = "";
        let tenantType = "";
        let Isselected = 0;
        let ForecastId = localStorage.getItem("ForecastId");
        this.Xerotenants.forEach((element, index) => {
          if (this.selectedOrganization === element.tenantId && this.selectedTennantId != null && this.selectedOrganization!="") {
            tenantId = element.tenantId;
            tenantName = element.tenantName.toString();
            tenantType = element.tenantType;
            Isselected = 1;
            let saveXeroTenantModel = {
              TenantId: element.tenantId,
              TenantName: element.tenantName.toString(),
              TenantType: element.tenantType,
              NodeId: parseInt(ForecastId),
              Isselected: 1,
            }

            this.xeroService.SaveXerotenant(saveXeroTenantModel).subscribe(
              ((response: any) => {
                this.SpinnerService.hide();
                localStorage.setItem("IntegrationWithName", saveXeroTenantModel.TenantName);
               // this.ngOnInit();
                location.reload();
              })
            )
          } 
          if (this.selectedOrganization == element.tenantId && this.selectedOrganization == this.selectedTennantId) {
            //location.reload();
          }
        });
      })
    )

  }

  Maping() {
    this.displayButtonImport = true;
    this.displayMaping = true;
    this.displayImport = false;
    this.displayButtonImport = true;
  }
  Disconnection() {
    this.SpinnerService.show();
    this.xeroService.Disconnection(this.ForecastId).subscribe(
      ((response: any) => {
        this.SpinnerService.hide();
        localStorage.setItem("IntegrationWithName", "");
        this._router.navigateByUrl('/forecast');
      })
    )
  }
  trackingcode($event) {
    this.displayMaping = false;
    let value = $event.target.checked;
    this.xeroService.ForecastIntegrationTrackingCode(this.ForecastId, value).subscribe(
      ((response: any) => {
        this.trackingCodevalue = response.includeTrackingCodes;
        this.archiveAccount = response.includeArchivedGL;
        this.allGLAccount = response.includeAllAccounts;
      })
    )
  }
  allArchivedcode($event) {
    this.displayMaping = false;
    let value = $event.target.checked;
    this.xeroService.ForecastIntegrationGlSetting(this.ForecastId, value).subscribe(
      ((response: any) => {

        this.trackingCodevalue = response.includeTrackingCodes;
        this.archiveAccount = response.includeArchivedGL;
        this.allGLAccount = response.includeAllAccounts;
      })
    )
  }
  allAccountcode($event) {
    this.displayMaping = false;
    let value = $event.target.checked;
    this.xeroService.ForecastIntegrationAllAcountSetting(this.ForecastId, value).subscribe(
      ((response: any) => {
        this.trackingCodevalue = response.includeTrackingCodes;
        this.archiveAccount = response.includeArchivedGL;
        this.allGLAccount = response.includeAllAccounts;
      })
    )
  }

}
