<igx-content>
    <div class="ValidDerivationSubTypes" *ngIf="displayvaluelistdata && isConsolidation==false">
        <div
            *ngIf="ValidDerivationSubTypes.length>0 && ValidDerivationSubTypesName.length>0 && displayvaluelistdata[0].derivationType != 83082 && displayvaluelistdata[0].derivationType != 83091">
            <select name="dropdown-option" class="form-control" style="width: auto;font-size: 15px;margin-top: 1px;"
                [ngClass]="{'disabled': ShowWhatIf==true}" [disabled]="ShowWhatIf"
                (change)="changeSubderrivationType($event, displayvaluelistdata ,ValidDerivationSubTypes)">
                <option *ngFor="let result of ValidDerivationSubTypes; let i = index" value="{{result}}"
                    [selected]="result == derivationSubType"> {{ ValidDerivationSubTypesName[i]}}
                </option>
            </select>
        </div>
    </div>
    <div *ngIf="DisplayCalculateAsBalanceCommon" class="ValidDerivationSubTypes">
        <div class="col-sm-6" *ngIf="DisplayCalculateAsBalanceValues!=null && isConsolidation==false"
            style="padding: 0px;">
            <input type="checkbox" [checked]="DisplayCalculateAsBalanceValues.isSelected"
                (change)="PercentageCalculateAsBalance(DisplayCalculateAsBalanceValues, $event)" class="col-sm-2"
                style="padding: 0px;width: 34px;height: 20px;margin: 1px 0 0 0;" />
            <label class="col-sm-8"
                style="padding: 0px; margin: 0px; text-align: left;">{{DisplayCalculateAsBalanceValues.localizedLabel}}</label>

        </div>
    </div>
    <div *ngIf="displayvaluelistdata">
        <div *ngIf="displayvaluelistdata.nodeTypeEnglishLabel != 'CapitalPaidNextYear'">
            <form>
                <section class="collection">
                    <div class="collection-item" id="{{displayvaluelistdata.nodeId}}">
                    </div>
                </section>
                <div *ngFor="let display of displaydarrivation">
                    <div class="defualtdisplay" *ngIf="display.inputs.length == 0">
                        <div class="valuelistdisplayrow"
                            [ngClass]="{'valuelistdisplayrow': display.targetNodeID == '',  'row valuelistdisplayrow': display.targetNodeID !='' }">
                            <div class="row DisplayAdjusted" *ngIf="addeddata && !display.isConsolidation"
                                style="padding: 0px;">
                                <div class="" style="height: 34px;"></div>
                                <div class="display-border-after-setall"></div>
                                <app-sectiontree [sectiontreeview]="sectiontreeview" [WhatIf]="false"
                                    [selectedNodeIds]="selectedNodeIds" [CalculateAsBalance]="DisplayCalculateAsBalance"
                                    (countChanged)="countChangedHandler($event, displayvaluelistdata[0] )"></app-sectiontree>
                            </div>
                            <div class="displayWithSelection {{displayvaluelistdata[0].nodeTypeEnglishLabel}}"
                                [ngClass]="{'row':displayvaluelistdata[0].nodeTypeEnglishLabel!='TotalAdditions'}">
                                <div *ngIf="displayvaluelistdata[0].nodeTypeEnglishLabel != 'TotalAdditions'">
                                    <div class="testingdata-{{i}}"
                                        *ngFor="let result of displayvaluelistdata;let i = index">
                                        <div *ngIf="DerivationOptions">
                                            <div class="valuelistdisplayrow derrivationdisplaydiv"
                                                *ngIf="DerivationOptions.localizedLabel">
                                                <div class="col-sm-12" *ngIf="DerivationOptions.optionType == 550029 ">
                                                    <div *ngIf="DerivationOptions.optionType == 550029">
                                                        <div class="col-sm-3">
                                                            <input type="checkbox" class=" col-sm-11"
                                                                [checked]="DerivationOptions.isSelected"
                                                                (change)="CalculateAsBalance(DerivationOptions, $event)" />
                                                        </div>
                                                        <label class=" col-sm-9"
                                                            style="font-size: 14px;">{{DerivationOptions.localizedLabel}}</label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div
                                                        *ngIf="DerivationOptions.localizedLabel !='Calculate As Balance' ">
                                                        <label
                                                            class="col-sm-5 ">{{DerivationOptions.localizedLabel}}</label>
                                                        <div class="col-sm-7"><input
                                                                style="text-align: right; padding:1px;"
                                                                class=" col-sm-11 {{result.values.valuesNodeId}}-percentage"
                                                                (blur)="noDarrivationPercentage(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                                value="{{DerivationOptions.storedValue*100}}"
                                                                allow-decimal="true" allow-negative="true"
                                                                [disabled]="ShowWhatIf" type="text" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                       
                                       
                                        <div class="row TittleSetup budgetDataTittle">
                                            <div _ngcontent-jck-c424="" class="col-sm-4"><label class="display-title"
                                                    style="font-size: 14px; color: white;">Net Sales</label></div>
                                            <div _ngcontent-jck-c424="" class="col-sm-8"><label class="display-title"
                                                    style="font-size: 14px;">{{ result.englishLabel}}</label></div>
                                        </div>
                                         <div class="row-percent valuelistdisplayrow setalldisplaydiv {{displaydarrivation[0].nodeType}}" 
                                         *ngIf="displaydarrivation[0].nodeType!=80011" style="height: 30px;" 
                                         [ngClass]="{'DisplayHideStockOnHandSetAll':result.englishLabel =='Stock On Hand'
                                          && result.values.allowSetAllEntry && !result.isConsolidation,
                                          'Displayhide': result.englishLabel=='Loan Balance' && recordNodeType == 50031 
                                            }">
                                            <div class="row"  *ngIf="displaydarrivation[0].perecent>0 || displaydarrivation[0].nodeType==80012">
                                                <label [ngClass]="{'col-sm-4 NewDesignLayout':recordNodeType==50002 , 'col-sm-5':recordNodeType!=50002}" style="margin-top: 0px;">Percent</label>
                                                <div class="col-sm-7" style="margin-top: 0px;"><input
                                                        id="{{displaydarrivation[0].perecent}}"
                                                        decimals="{{result.decimalPlaces}}"
                                                        style="text-align: right; padding:1px;"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}}-setall  "
                                                        [disabled]="ShowWhatIf || result.isConsolidation==true"
                                                        (blur)="FixedRatePercentage($event, displaydarrivation[0], result, result.values.valuesNodeId)"
                                                        allow-decimal="true" allow-negative="true"
                                                        (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                                                        value="{{displaydarrivation[0].perecent}}" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row row-Periods" style="height: 30px;"
                                        *ngIf="displaydarrivation[0].derivationOptions[0]!=null && displaydarrivation[0].derivationOptions[0].localizedLabel =='# Periods'">
                                            <div
                                                *ngIf="displaydarrivation[0].derivationOptions[0].localizedLabel =='# Periods' && !isConsolidation">
                                                <label [ngClass]="{'col-sm-4 NewDesignLayout':recordNodeType==50002 , 'col-sm-5':recordNodeType!=50002}">{{displaydarrivation[0].derivationOptions[0].localizedLabel}}</label>
                                                <div class="col-sm-7"><input style="text-align: right; padding:1px;"
                                                        class=" col-sm-11"
                                                        (blur)="numberofperiodsofoptions($event, displaydarrivation[0].derivationOptions[0].nodeId )"
                                                        value="{{displaydarrivation[0].derivationOptions[0].value}}"
                                                        [disabled]="ShowWhatIf" allow-decimal="true"
                                                        allow-negative="true" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="valuelistdisplayrow setalldisplaydiv"
                                            *ngIf="result.values.allowOpeningEntry || result.values.hasOpening ">
                                            <div class="row" style="height: 30px;"
                                                *ngIf="result.values.allowOpeningEntry && !result.isConsolidation && recordNodeType !=50031">
                                                <label [ngClass]="{'col-sm-4 NewDesignLayout':recordNodeType==50002 , 'col-sm-5':recordNodeType!=50002}">Opening</label>
                                                <div class="col-sm-7"><input id="{{result.opening}}"
                                                        decimals="{{result.decimalPlaces}}"
                                                        style="text-align: right; padding:1px;"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}}-setall  "
                                                        (blur)="noDarivationopening(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                        allow-decimal="true" allow-negative="true"
                                                        [disabled]="ShowWhatIf" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)" value="{{result.opening}}"
                                                        type="text" />
                                                </div>
                                            </div>
                                            <div class="row" style="height: 30px;"
                                                *ngIf="!result.values.allowOpeningEntry && !result.values.isConsolidation && result.values.hasOpening">
                                                <label [ngClass]="{'col-sm-4 NewDesignLayout':recordNodeType==50002 , 'col-sm-5':recordNodeType!=50002}">Opening</label>
                                                <div class="col-sm-7"><input id="{{result.opening}}"
                                                        decimals="{{result.decimalPlaces}}"
                                                        style="text-align: right; padding:1px;"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}}-setall  "
                                                        (blur)="noDarivationopening(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                        allow-decimal="true" allow-negative="true"
                                                        [disabled]="true" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)" value="{{result.opening}}"
                                                        type="text" />
                                                </div>
                                            </div>
                                            
                                            <div class="row" style="height: 30px;"
                                                *ngIf="!result.values.allowOpeningEntry && result.values.isConsolidation && result.values.hasOpening">
                                                <label [ngClass]="{'col-sm-4 NewDesignLayout':recordNodeType==50002 , 'col-sm-5':recordNodeType!=50002}">Opening</label>
                                                <div class="col-sm-7"><input id="{{result.opening}}"
                                                        decimals="{{result.decimalPlaces}}"
                                                        style="text-align: right; padding:1px;"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}}-setall  "
                                                        (blur)="noDarivationopening(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                        allow-decimal="true" allow-negative="true"
                                                        [disabled]="result.isConsolidation"
                                                        (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                                                        value="{{result.opening}}" type="text" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="valuelistdisplayrow setalldisplaydiv {{result.nodeTypeEnglishLabel}}" 
                                            [ngClass]="{'NotOpeningEntery': !result.values.allowOpeningEntry && !result.values.allowValueEntry}">
                                            <!-- <div class="display-border-after-setall"></div>  -->
                                            <div class="row" style="height: 30px;"
                                                *ngIf="result.values.allowSetAllEntry && !result.isConsolidation">
                                                <label [ngClass]="{'col-sm-4 NewDesignLayout':recordNodeType==50002 , 'col-sm-5':recordNodeType!=50002}" id="hidecopy-{{result.values.valuesNodeId}}"
                                                    *ngIf="result.allowSetAllEntry== true && result.values.allowValueEntry== true">Set
                                                    All</label>
                                                <div class="col-sm-7"
                                                    *ngIf="result.allowSetAllEntry== true && result.values.allowValueEntry== true">
                                                    <input decimals="{{result.decimalPlaces}}"
                                                        style="text-align: right; padding:1px;"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}}-setall"
                                                        (blur)="noDarivationsetall(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                        (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                                                        [disabled]='result.allowSetAllEntry ==false'
                                                        [disabled]="ShowWhatIf"
                                                        (contextmenu)="open($event,result.values.valuesNodeId)"
                                                        allow-decimal="true" allow-negative="true" type="text" />
                                                    <div
                                                        class="display overlayHotlinkCopypaste hide rightclick-{{result.values.valuesNodeId}}">
                                                        <section class="user-menu" style="border: 1px solid;">
                                                            <div style="color: white;font-size: 18px;height: 35px;border-bottom: 1px solid;"
                                                                (click)="CopyToClipboard(result.values.valuesNodeId)">
                                                                Copy</div>
                                                            <div (click)="convertoExcel(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                                style="color: white;font-size: 18px;"> Paste </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="display-border-after-setall"></div>
                                        <div class="valuelistdisplayrow"
                                            *ngFor="let valuearr of result.values.valuesArray | slice:PeriodTabViewStartIndex:PeriodTabViewEndIndex; let i = index ; trackBy:trackByFn">
                                            <div class="row row{{recordNodeType}}Data-{{i+PeriodTabViewStartIndex}}">
                                                <label 
                                                    [ngClass]="{'col-sm-4 NewDesignLayout':recordNodeType==50002 , 'col-sm-5':recordNodeType!=50002}"
                                                    [style.font-weight]="ValueListMonthDisplay[i+PeriodTabViewStartIndex].fontWight">{{ValueListMonthDisplay[i+PeriodTabViewStartIndex].name}}</label>
                                                <div class="col-sm-7" *ngIf=" result.currentActualsIndex==-1">
                                                    <input
                                                        *ngIf="result.values.allowValueEntry && !result.isConsolidation"
                                                        type="text" class=" col-sm-11 {{result.values.valuesNodeId}}"
                                                        decimals={{result.values.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)" [disabled]="ShowWhatIf"
                                                        id="{{result.values.valuesNodeId}}-{{i+PeriodTabViewStartIndex}}"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (blur)="NoderrivationMonthCalculation(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0,i+PeriodTabViewStartIndex)" />
                                                    <input
                                                        *ngIf="!result.values.allowValueEntry && result.nodeType != 60103"
                                                        type="text"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}} withoutActualModeEnable"
                                                        decimals={{result.values.decimalPlaces}} [disabled]="true"
                                                        style="text-align: right; padding:1px;"
                                                        (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        id="{{result.values.valuesNodeId}}-{{i+PeriodTabViewStartIndex}}"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (blur)="NoderrivationMonthCalculation(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0, i+PeriodTabViewStartIndex)" />
                                                    <input
                                                        *ngIf="!result.values.allowValueEntry && result.values.isConsolidation && result.nodeType == 60103"
                                                        type="text" class=" col-sm-11 {{result.values.valuesNodeId}}"
                                                        decimals={{result.values.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)" [disabled]="ShowWhatIf"
                                                        id="{{result.values.valuesNodeId}}-{{i+PeriodTabViewStartIndex}}"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}" disabled="true" />
                                                    <input
                                                        *ngIf="!result.values.allowValueEntry && !result.values.isConsolidation && result.nodeType == 60103"
                                                        type="text" class=" col-sm-11 {{result.values.valuesNodeId}}"
                                                        decimals={{result.values.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)" [disabled]="ShowWhatIf"
                                                        id="{{result.values.valuesNodeId}}-{{i+PeriodTabViewStartIndex}}"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}" disabled="true" />
                                                    <input
                                                        *ngIf="result.values.allowValueEntry && result.values.isConsolidation"
                                                        type="text" class=" col-sm-11 {{result.values.valuesNodeId}}"
                                                        decimals={{result.values.decimalPlaces}}
                                                        style="text-align: right; padding:1px;" [disabled]="ShowWhatIf"
                                                        (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        id="{{result.values.valuesNodeId}}-{{i+PeriodTabViewStartIndex}}"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}" disabled="true" />
                                                </div>
                                                <div class="col-sm-7"
                                                    *ngIf="result.values.allowValueEntry && result.currentActualsIndex!=-1">
                                                    <input
                                                        *ngIf="IsEnabled(i+PeriodTabViewStartIndex, result.values) == true"
                                                        type="text" class=" col-sm-11 {{result.values.valuesNodeId}}"
                                                        decimals={{result.values.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        [style.font-weight]="FontWight(i+PeriodTabViewStartIndex, result.values)"
                                                        [disabled]="false" (click)="valuelistregrex($event)"
                                                        [disabled]="ShowWhatIf" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        id="{{result.values.valuesNodeId}}-{{i+PeriodTabViewStartIndex}}"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (blur)="NoderrivationMonthCalculation(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0,i+PeriodTabViewStartIndex)" />
                                                    <input
                                                        *ngIf="IsEnabled(i+PeriodTabViewStartIndex, result.values) == false"
                                                        type="text"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}} ActualModeEnable"
                                                        decimals={{result.values.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        [style.font-weight]="FontWight(i+PeriodTabViewStartIndex, result.values)"
                                                        [disabled]="true" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}" />
                                                </div>
                                                <div class="col-sm-7"
                                                    *ngIf="result.values.allowValueEntry == false && result.currentActualsIndex!=-1">
                                                    <input
                                                        *ngIf="IsEnabled(i+PeriodTabViewStartIndex, result.values) == true"
                                                        type="text"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}} {{i+10}}"
                                                        decimals={{result.values.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        [style.font-weight]="FontWight(i+PeriodTabViewStartIndex, result.values)"
                                                        [disabled]="false" (click)="valuelistregrex($event)"
                                                        [disabled]="ShowWhatIf" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        id="{{result.values.valuesNodeId}}-{{i+PeriodTabViewStartIndex}}"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (blur)="NoderrivationMonthCalculation(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0,i+PeriodTabViewStartIndex)" />
                                                    <input
                                                        *ngIf="IsEnabled(i+PeriodTabViewStartIndex, result.values) == false"
                                                        type="text"
                                                        class=" col-sm-11 {{result.values.valuesNodeId}} ActualModeEnable"
                                                        decimals={{result.values.decimalPlaces}}
                                                        style="text-align: right; padding:1px;"
                                                        [style.font-weight]="FontWight(i+PeriodTabViewStartIndex, result.values)"
                                                        [disabled]="true" (keypress)="isNumber($event)"
                                                        id="{{result.values.valuesNodeId}}-{{i+PeriodTabViewStartIndex}}"
                                                        (keyup)="ValidateValue($event)"
                                                        [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                        [ngModelOptions]="{standalone: true}" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="display-border-before-total"></div>
                                        <div class="valuelistdisplayrow Total-div">
                                            <div class="row" *ngIf="result.values.hasTotal">
                                                <label [ngClass]="{'col-sm-4 NewDesignLayout':recordNodeType==50002 , 'col-sm-5':recordNodeType!=50002}">Total</label>
                                                <div class="col-sm-7"
                                                    *ngIf="result.values.allowTotalEntry && result.values.allowValueEntry && !result.values.isConsolidation">
                                                    <input type="text" id="{{result.values.valuesNodeId}}-total"
                                                        class=" col-sm-11 {{result.total}}"
                                                        style="text-align: right; padding:1px;"
                                                        value="{{result.total | minussignsparen}}"
                                                        (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                        (keyup)="ValidateValue($event)"
                                                        (blur)="noDarivationtotal(result.values.valuesNodeId, result.values, $event , displayvaluelistdata , 0)"
                                                        decimals={{result.decimalPlaces}}
                                                        [disabled]="result.values.allowTotalEntry == false || result.values.isConsolidation"
                                                        [disabled]="ShowWhatIf" />
                                                </div>
                                                <label
                                                    *ngIf="!result.values.allowTotalEntry || !result.values.allowValueEntry  || result.values.isConsolidation"
                                                    id="{{result.values.valuesNodeId}}-total" class=" col-sm-6"
                                                    style="text-align: right; padding:1px;"
                                                    value="{{result.total | minussignsparen}}"> {{result.total |
                                                    minussignsparen}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="displayvaluelistdata[0].nodeTypeEnglishLabel == 'TotalAdditions'">
                                    <div class="col" *ngFor="let result of displayvaluelistdata;let i = index">
                                        <label class="display-title" style="font-size: 14px;">{{
                                            displayvaluelistdata[0].englishLabel}}</label>
                                        <div class="display-border-after-setall"></div>
                                        <div class="row valuelistdisplayrow"
                                            *ngFor="let defualtresult of result.values.valuesArray | slice:PeriodTabViewStartIndex:PeriodTabViewEndIndex; let i = index">
                                            <div class="col">
                                                <label class="col-sm-5 "
                                                    [ngClass]="{'ActualMode': result.currentActualsIndex >= i}">{{ValueListMonthDisplay[i+PeriodTabViewStartIndex].name}}</label>
                                                <label class="col-sm-5 " style="text-align: right; padding:1px;"
                                                    [ngClass]="{'ActualMode': result.currentActualsIndex >= i}">{{defualtresult
                                                    * (i+1) }}</label>
                                            </div>
                                        </div>
                                        <div class="display-border-before-total"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="displaywithdarrivation" *ngIf="display.inputs.length> 0">
                        <div class="row valuelistdisplayrow TittleSetup">
                            <div class="text-center col-sm-3" *ngIf="targetNodeIsDisplayDiv>0 && !addeddata">
                                <!-- <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
                                  <span class="visually-hidden">...</span>
                                </div> -->
                                <label class="display-title" style="font-size: 14px;"
                                    *ngIf="displayvaluelistdata[0].englishLabel">{{
                                    displayvaluelistdata[0].englishLabel}}</label>
                                <div class="row-percent valuelistdisplayrow" style="height: 30px;">
                                    <div class="row">
                                    </div>
                                </div>
                                <div class="montharrayheader"></div>
                                <div class="display-border-after-setall"></div>
                                <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
                                  </div>
                                  <span class="visually-hidden">Loading Tree...</span>
                            </div>
                            <div [ngClass]="{'col-sm-3 ': displaydarrivation[0].englishLabel == 'From Employees' || displaydarrivation[0].englishLabel == 'Costs Units'  || displaydarrivation[0].englishLabel == 'Sales Units' , 
                            'col-sm-4' : displaydarrivation[0].englishLabel != 'From Employees' ||  displaydarrivation[0].englishLabel != 'Costs Units' || displaydarrivation[0].englishLabel != 'Sales Units'  }"
                                style="padding: 0px; margin-right: 8px;" *ngIf="addeddata && !display.isConsolidation">
                                <label class="display-title" style="font-size: 14px;"
                                    *ngIf="displayvaluelistdata[0].englishLabel">{{
                                    displayvaluelistdata[0].englishLabel}}</label>
                                <div class="row-percent valuelistdisplayrow" style="height: 30px;">
                                    <div class="row">
                                    </div>
                                </div>
                                <div class="montharrayheader"></div>
                                <div class="display-border-after-setall"></div>
                               
                                
                                <app-sectiontree [sectiontreeview]="sectiontreeview" [selectedNodeIds]="selectedNodeIds"
                                    [WhatIf]="false" [CalculateAsBalance]="DisplayCalculateAsBalance"
                                    (countChanged)="countChangedHandler($event, displayvaluelistdata[0] )"></app-sectiontree>
                            </div>
                            <div 
                            [ngClass]="
                            {'col': displayindex == 0 || inputarrayloop.englishLabel == 'Cycle Totals',  
                            'col-sm-2' : displayindex != 0 && inputarrayloop.englishLabel != 'Cycle Totals'
                             && displayvaluelistdata[0].derivationEngType != 'CalculateOverdraftInterest'
                             && displayvaluelistdata[0].derivationEngType != 'FromStockOnHand'  ,
                             'col-sm-2':displayindex != 0 && displayvaluelistdata[0].derivationEngType == 'FromStockOnHand',
                             'col-sm-6':inputarrayloop.customenglishname == 'Four-Five-Four',
                             'col-sm-7':inputarrayloop.customenglishname == 'Five-Four-Four',
                             'col-sm-8':inputarrayloop.customenglishname == 'Four-Four-Five',
                            'col':displayvaluelistdata[0].derivationEngType == 'ProfileData' 
                            || displayvaluelistdata[0].derivationEngType == 'CalculateOverdraftInterest',
                            'col-sm-5':inputarrayloop.customenglishname == 'EvenSpread',
                            'col-sm-7': inputarrayloop.customenglishname =='Days' &&  displayvaluelistdata[0].derivationEngType == 'DaysCredit',
                             }"
                                class="custom-{{inputarrayloop.customenglishname}}" 
                                *ngFor="let inputarrayloop of display.inputs;let i = index; let displayindex = index">

                                <div class="row budgetDataTittle"
                                    [ngClass]="{titleheader:displayvaluelistdata[0].englishLabel == 'Bank Overdraft Interest'}"
                                    *ngIf="displayindex == 0 || inputarrayloop.englishLabel == 'Cycle Totals'">
                                    <div class="col-sm-4">
                                        <label class="display-title"
                                            [ngClass]="{'ActualModeEnabletitle': IsEnabled(i,inputarrayloop) == false}"
                                            style="font-size: 14px; color: white; position: absolute; display: none;">
                                            {{inputarrayloop.englishLabel}} {{display.derivationEngType}}
                                        </label>
                                    </div>
                                    <div class="col-sm-8">
                                        <label class="display-title"
                                            [ngClass]="{'ActualModeEnabletitle': IsEnabled(i,inputarrayloop) == false}"
                                            style="font-size: 14px;text-align: left;">
                                            {{inputarrayloop.englishLabel}} {{display.derivationEngType}}
                                        </label>
                                    </div>
                                </div>
                                <label class="display-title"
                                    *ngIf="displayindex != 0 && inputarrayloop.englishLabel != 'Cycle Totals'"
                                    style="font-size: 14px;">{{inputarrayloop.englishLabel}}
                                    {{display.derivationEngType}}</label>

                                <div class="row-percent valuelistdisplayrow" style="height: 30px;">
                                    <div class="row">
                                    </div>
                                </div>

                                <div class="montharrayheader">
                                    <div
                                        *ngIf="inputarrayloop.allowValueEntry == true && !inputarrayloop.isConsolidation">
                                        <div class="row valuelistdisplayrow setalldisplaydiv"
                                            *ngIf="inputarrayloop.allowSetAllEntry">
                                            <label class="col-sm-5"
                                                *ngIf="displayindex == 0 || inputarrayloop.englishLabel == 'Cycle Totals' "
                                                id="hidecopy-{{inputarrayloop.englishLabel}}">Set All</label>
                                            <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0}">
                                                <input *ngIf="inputarrayloop.englishLabel!='Percentages'"
                                                    (blur)="darrivationsetall(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1)"
                                                    decimals="{{inputarrayloop.decimalPlaces}}"
                                                    id="{{inputarrayloop.englishLabel}}-setall-{{i}}"
                                                    style="text-align: right; padding:1px;"
                                                    (keypress)="LimitOnly($event, inputarrayloop.limitNumber)"
                                                    (keyup)="ValidateValue($event)" class=" col-sm-11"
                                                    [disabled]='inputarrayloop.allowSetAllEntry ==false'
                                                    [disabled]="ShowWhatIf"
                                                    (contextmenu)="open($event,inputarrayloop.englishLabel)"
                                                    type="text" />
                                                <input *ngIf="inputarrayloop.englishLabel == 'Percentages'"
                                                    (blur)="darrivationsetall(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1)"
                                                    decimals="{{inputarrayloop.decimalPlaces}}"
                                                    id="{{inputarrayloop.englishLabel}}-{{inputarrayloop.nodeId}}-setall-{{i}}"
                                                    style="text-align: right; padding:1px;"
                                                    (keypress)="LimitOnly($event, inputarrayloop.limitNumber)"
                                                    (keyup)="ValidateValue($event)" class=" col-sm-11"
                                                    [disabled]='inputarrayloop.allowSetAllEntry ==false'
                                                    [disabled]="ShowWhatIf"
                                                    (contextmenu)="open($event,inputarrayloop.englishLabel)"
                                                    type="text" />
                                                <div
                                                    class="display overlayHotlinkCopypaste hide rightclick-{{inputarrayloop.englishLabel}}">
                                                    <section class="user-menu" style="border: 1px solid;">
                                                        <div style="color: white;font-size: 18px;height: 35px;border-bottom: 1px solid;"
                                                            (click)="CopyToClipboard(inputarrayloop.englishLabel)">Copy
                                                        </div>
                                                        <div (click)="convertoExcel(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1)"
                                                            style="color: white;font-size: 18px;"> Paste </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row valuelistdisplayrow setalldisplaydiv"
                                            *ngIf="inputarrayloop.allowOpeningEntry">
                                            <label class="col-sm-5" *ngIf="displayindex == 0">Opening</label>
                                            <div class="col-sm-7">
                                                <input
                                                    id="{{inputarrayloop.englishLabel}}-setall-{{i}}  {{inputarrayloop.opening}}"
                                                    decimals="{{inputarrayloop.decimalPlaces}}"
                                                    style="text-align: right; padding:1px;"
                                                    class=" col-sm-11 {{result.values.valuesNodeId}}-setall  "
                                                    (blur)="noDarivationopening(inputarrayloop.englishLabel, inputarrayloop, $event , displayvaluelistdata , 0)"
                                                    allow-decimal="true" allow-negative="true"
                                                    (keyup)="ValidateValue($event)" [disabled]="ShowWhatIf"
                                                    value="{{inputarrayloop.opening | minussignsparen}}" type="text" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="display-border-after-setall"></div>
                                <div class="display-border">
                                    <div class="row" *ngIf="inputarrayloop.englishLabel == 'Cycle Totals'">
                                        <label class="col-sm-5"
                                            *ngIf="inputarrayloop.englishLabel == 'Cycle Totals'">{{cyclePeriodIndex}}</label>
                                        <div class="col" *ngIf="displayvaluelistdata[0].currentActualsIndex ==-1">
                                            <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                class=" col-sm-11 {{inputarrayloop.englishLabel}}" type="text"
                                                id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                [(ngModel)]="inputarrayloop.valuesArray[0]"
                                                (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1,i+PeriodTabViewStartIndex)"
                                                (click)="valuelistregrex($event)" [disabled]="ShowWhatIf"
                                                style="text-align: right; padding:1px;"
                                                [ngModelOptions]="{standalone: true}" />
                                        </div>
                                        <div class="col" *ngIf="displayvaluelistdata[0].currentActualsIndex !=-1">
                                                <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                class=" col-sm-11 {{inputarrayloop.englishLabel}} {{inputarrayloop.currentActualsIndex}}"
                                                type="text" disabled=disabled
                                                id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                [(ngModel)]="inputarrayloop.valuesArray[0]"
                                                (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1,i+PeriodTabViewStartIndex)"
                                                (click)="valuelistregrex($event)"
                                                style="text-align: right; padding:1px;" [disabled]="ShowWhatIf"
                                                [ngModelOptions]="{standalone: true}" />
                                        </div>

                                    </div>
                                    <div *ngIf="inputarrayloop.englishLabel != 'Cycle Totals'">
                                        <div class="MonthArrayClass">
                                            <div *ngIf="inputarrayloop.englishLabel =='Payment Profile'">
                                                <div class="row valuelistdisplayrow row{{recordNodeType}}Data-{{i}}"
                                                    *ngFor="let valuearrayresult of inputarrayloop.valuesArray | slice:0:12; let i = index; trackBy:trackByFn">
                                                    <label class="col-sm-5"
                                                        *ngIf="displayindex == 0 && inputarrayloop.englishLabel != 'Cycle Totals' && inputarrayloop.englishLabel == 'Payment Profile'"
                                                        [ngClass]="{'ActualModeEnabletitle': inputarrayloop.currentActualsIndex>=i}">{{
                                                        inputarrayloop.paymentProfilePeriod[i]}}</label>
                                                    <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0 }"
                                                        *ngIf="inputarrayloop.currentActualsIndex!=-1">
                                                        <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                            class=" col-sm-11 {{inputarrayloop.englishLabel}} testing"
                                                            [ngClass]="{'ActualModeEnable': IsEnabled(i,inputarrayloop) == false && FontWight(i, inputarrayloop)=='Normal'}"
                                                            type="text"
                                                            [style.font-weight]="FontWight(i, inputarrayloop)"
                                                            id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                            [(ngModel)]="inputarrayloop.valuesArray[i]"
                                                            (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1,i+PeriodTabViewStartIndex)"
                                                            (click)="valuelistregrex($event)"
                                                            (keypress)="isNumber($event)"
                                                            [disabled]="ShowWhatIf  || inputarrayloop.isConsolidation"
                                                            (keyup)="ValidateValue($event)"
                                                            style="text-align: right; padding:1px;"
                                                            [ngModelOptions]="{standalone: true}" />
                                                        <label *ngIf="inputarrayloop.allowValueEntry==false"
                                                            [style.font-weight]="FontWight(i, inputarrayloop)">{{valuearrayresult}}</label>
                                                    </div>
                                                    <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0 }"
                                                        *ngIf="inputarrayloop.currentActualsIndex==-1">
                                                        <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                            class=" col-sm-11  {{inputarrayloop.englishLabel}} latestData"
                                                            type="text"
                                                            id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                            [(ngModel)]="inputarrayloop.valuesArray[i]"
                                                            (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1,i+PeriodTabViewStartIndex)"
                                                            (click)="valuelistregrex($event)"
                                                            (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            [disabled]="ShowWhatIf || inputarrayloop.isConsolidation"
                                                            style="text-align: right; padding:1px;"
                                                            [ngModelOptions]="{standalone: true}" />
                                                        <label
                                                            *ngIf="inputarrayloop.allowValueEntry==false">{{valuearrayresult}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="inputarrayloop.englishLabel !='Payment Profile'">

                                                <div class="row valuelistdisplayrow row{{recordNodeType}}Data-{{i}}"
                                                    *ngFor="let valuearrayresult of inputarrayloop.valuesArray | slice:PeriodTabViewStartIndex:PeriodTabViewEndIndex; let i = index; trackBy:trackByFn">
                                                    <label class="col-sm-5"
                                                        *ngIf="displayindex == 0 && inputarrayloop.englishLabel != 'Cycle Totals' && inputarrayloop.englishLabel!='Payment Profile'"
                                                        [ngClass]="{'ActualModeEnabletitle': inputarrayloop.currentActualsIndex>=i}">{{ValueListMonthDisplay[i+PeriodTabViewStartIndex].name}}</label>
                                                    <label class="col-sm-5"
                                                        *ngIf="displayindex == 0 && inputarrayloop.englishLabel != 'Cycle Totals' && inputarrayloop.englishLabel == 'Payment Profile'"
                                                        [ngClass]="{'ActualModeEnabletitle': inputarrayloop.currentActualsIndex>=i}">{{
                                                        inputarrayloop.paymentProfilePeriod[i]}}</label>
                                                    <label class="col-sm-5"
                                                        *ngIf="inputarrayloop.englishLabel == 'Cycle Totals'">20-21</label>

                                                    <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0 }"
                                                        *ngIf="inputarrayloop.currentActualsIndex!=-1">
                                                        <input *ngIf="inputarrayloop.allowValueEntry==true"
                                                            class=" col-sm-11 {{inputarrayloop.englishLabel}} testing"
                                                            [ngClass]="{'ActualModeEnable': IsEnabled(i,inputarrayloop) == false && FontWight(i, inputarrayloop)=='Normal'}"
                                                            type="text"
                                                            [style.font-weight]="FontWight(i, inputarrayloop)"
                                                            id="{{inputarrayloop.englishLabel}}-months-{{i}}"
                                                            [(ngModel)]="inputarrayloop.valuesArray[i]"
                                                            (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1,i+PeriodTabViewStartIndex)"
                                                            (click)="valuelistregrex($event)" [disabled]="ShowWhatIf"
                                                            (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            style="text-align: right; padding:1px;"
                                                            [ngModelOptions]="{standalone: true}" />
                                                        <label *ngIf="inputarrayloop.allowValueEntry==false"
                                                            [style.font-weight]="FontWight(i, inputarrayloop)">{{valuearrayresult}}</label>
                                                    </div>
                                                    <div [ngClass]="{'col-sm-7': displayindex == 0, 'col' : displayindex != 0 }"
                                                        *ngIf="inputarrayloop.currentActualsIndex==-1">
                                                        <input
                                                            *ngIf="inputarrayloop.allowValueEntry==true  && inputarrayloop.englishLabel!='Percentages'"
                                                            class=" col-sm-11 {{inputarrayloop.englishLabel}} latestData"
                                                            type="text"
                                                            id="{{inputarrayloop.englishLabel}}-months-{{i+PeriodTabViewStartIndex}}"
                                                            [(ngModel)]="inputarrayloop.valuesArray[i+PeriodTabViewStartIndex]"
                                                            (blur)="derrivationmonthCalculation(inputarrayloop.englishLabel, inputarrayloop , $event , displayvaluelistdata , 1,i+PeriodTabViewStartIndex)"
                                                            (click)="valuelistregrex($event)"
                                                            (keypress)="isNumber($event)"
                                                            [disabled]="ShowWhatIf  || inputarrayloop.isConsolidation ==true"
                                                            (keyup)="ValidateValue($event)"
                                                            style="text-align: right; padding:1px;"
                                                            [ngModelOptions]="{standalone: true}" />

                                                        <input
                                                            *ngIf="inputarrayloop.allowValueEntry==true && inputarrayloop.englishLabel=='Percentages'"
                                                            class=" col-sm-11 {{inputarrayloop.englishLabel}}-{{inputarrayloop.nodeId}} latestData"
                                                            type="text"
                                                            id="{{inputarrayloop.englishLabel}}-{{inputarrayloop.nodeId}}-months-{{i+PeriodTabViewStartIndex}}"
                                                            [(ngModel)]="inputarrayloop.valuesArray[i+PeriodTabViewStartIndex]"
                                                            (blur)="derrivationmonthCalculation((inputarrayloop.englishLabel-inputarrayloop.nodeId), inputarrayloop , $event , displayvaluelistdata , 1,i+PeriodTabViewStartIndex)"
                                                            (click)="valuelistregrex($event)"
                                                            [disabled]="ShowWhatIf  || inputarrayloop.isConsolidation"
                                                            (keypress)="isNumber($event)"
                                                            (keyup)="ValidateValue($event)"
                                                            style="text-align: right; padding:1px;"
                                                            [ngModelOptions]="{standalone: true}" />
                                                        <label
                                                            *ngIf="inputarrayloop.allowValueEntry==false" style="font-weight: 400;">{{valuearrayresult}}</label>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                        <div class="display-border-before-total"></div>
                                        <div class="row valuelistdisplayrow setalldisplaydiv Total-div">
                                            <label class="col-sm-5"
                                                *ngIf="displayindex == 0 && !inputarrayloop.isConsolidation">Total</label>
                                            <div *ngIf="inputarrayloop.englishLabel=='Even Spread'" class="col-sm-7">
                                                <div class="col">
                                                    <label class=" col-sm-11">{{inputarrayloop.total}}</label>
                                                </div>
                                            </div>
                                            <div *ngIf="display.englishLabel !='Profile'" class="col-sm-7">
                                                <div class="col"
                                                    *ngIf="inputarrayloop.hasTotal && inputarrayloop.allowValueEntry && !inputarrayloop.isConsolidation">
                                                    <label class=" col-sm-11"
                                                        *ngIf="inputarrayloop.allowValueEntry == false">{{inputarrayloop.total}}</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div 
                             class="col displayvaluedisbaled-{{displayvaluelistdata[0].nodeTypeEnglishLabel}}"
                                *ngFor="let result of displayvaluelistdata;let i = index">

                                <label class="display-title" style="font-size: 14px; height: 50px;">{{
                                    displayvaluelistdata[0].englishLabel}}</label>
                                <div class="row valuelistdisplayrow setalldisplaydiv"></div>
                                <div class="row-percent valuelistdisplayrow" style="height: 30px;">
                                    <div class="row">
                                    </div>
                                </div>
                                <div class="display-border-after-setall"></div>
                                <div class="MonthArrayClass">
                                    <div class="row valuelistdisplayrow row{{recordNodeType}}Data-{{i}}"
                                        *ngFor="let defualtresult of result.values.valuesArray | slice:PeriodTabViewStartIndex:PeriodTabViewEndIndex; let i = index">
                                        <div *ngIf="result.values.currentActualsIndex==-1" class="col" 
                                        [ngClass]="{'NewDesignColLayout':recordNodeType==50002}">
                                            <input type="text" disabled=disabled value="{{defualtresult}}"
                                                class=" thirdsectionNoActual col-sm-11">
                                        </div>
                                        <div class="col" 
                                        [ngClass]="{'NewDesignColLayout':recordNodeType==50002}" *ngIf="result.values.currentActualsIndex!=-1">
                                            <input *ngIf="IsEnabled(i+PeriodTabViewStartIndex,result.values) == true"
                                                class=" col-sm-11 {{result.values.englishLabel}}" type="text"
                                                [style.font-weight]="FontWight(i+PeriodTabViewStartIndex, result.values)"
                                                id="{{result.values.englishLabel}}-months-{{i+PeriodTabViewStartIndex}}"
                                                [(ngModel)]="result.values.valuesArray[i+PeriodTabViewStartIndex]"
                                                [ngClass]="{'ActualModeEnable': IsEnabled(i+PeriodTabViewStartIndex,result.values) == false && FontWight(i+PeriodTabViewStartIndex, result.values)=='Normal'}"
                                                [disabled]="IsEnabled(i+PeriodTabViewStartIndex,result.values) == false && FontWight(i+PeriodTabViewStartIndex, result.values)=='Normal'"
                                                (blur)="derrivationmonthCalculation(result.values.englishLabel, result.values , $event , displayvaluelistdata , 1,i+PeriodTabViewStartIndex)"
                                                (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                (keyup)="ValidateValue($event)" style="text-align: right; padding:1px;"
                                                [disabled]="ShowWhatIf || result.values.isConsolidation"
                                                [ngModelOptions]="{standalone: true}" />
                                            <input type="text"
                                            [ngClass]="{'NewDesignColLayout':recordNodeType==50002}"
                                                *ngIf="IsEnabled(i+PeriodTabViewStartIndex, result.values) == false"
                                                disabled=disabled class=" col-sm-11 thirdsection"
                                                style="text-align: right; padding:1px;"
                                                [style.font-weight]="FontWight(i+PeriodTabViewStartIndex, result.values)"
                                                [disabled]="ShowWhatIf" value="{{defualtresult}}" />
                                        </div>
                                        <div class="display-border-after-setall row"></div>
                                    </div>
                                </div>
                                <div class="display-border-before-total"></div>
                                <div class="row valuelistdisplayrow">
                                    <div class="col" [ngClass]="{'NewDesignColLayout':recordNodeType==50002}">
                                        <input type="text" disabled=disabled class=" col-sm-11"
                                            id="{{result.values.valuesNodeId}}-total"
                                            [disabled]="ShowWhatIf || result.values.isConsolidation"
                                            value="{{displayvaluelistdata[0].total | minussignsparen}}"
                                            style="text-align: right; padding:1px;" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </form>
        </div>
    </div>
</igx-content>