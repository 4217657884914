import { error } from 'console';
import { AuthService } from './auth.service';

export function appInitializer(authService: AuthService) {
  // console.log("initialising app");
  return () =>
    new Promise((resolve) => {
      const token = localStorage.getItem("jwt");
      const refreshToken: string = localStorage.getItem("refresh_token");
      const userName = localStorage.getItem("CurrentUserName");
      const credentials = { accessToken: token, refreshToken: refreshToken, userName: userName };


      if (token && authService.isTokenExpired(token)) {
        authService.refreshToken(credentials).subscribe(res => { }, error => {
          let errorMsg = error;
        }).add(resolve);
      }
      else {
        resolve(resolve);
      }
    });
}
