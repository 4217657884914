import { Component, Injector, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ServiceService } from '../services/service.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
  selector: 'app-importforecast',
  templateUrl: './importforecast.component.html',
  styleUrls: ['./importforecast.component.scss']
})
export class ImportforecastComponent implements OnInit {
  RegionalCollection: any;
  isDefaultCountry = "";
  selectedCountryIndex = 0;
  RegionalCountryCollectionIndex = 0;
  ForecastClientCountryCollection = [];
  currencyerror:boolean;
  UserId = localStorage.getItem('CurrentUserNodeId');
  formData = new FormData()
  constructor(private listservice: ServiceService,
    private _router: Router,
    private SpinnerService: NgxSpinnerService,
    private injector: Injector) { }

  async ngOnInit() {
    this.currencyerror = false;
    let JsonCollectionArray = localStorage.getItem("ForecastClientCountryCollection");
    this.isDefaultCountry = localStorage.getItem("DefaultCounty");
    console.log("JsonCollectionArray", JsonCollectionArray);
    if (this.isDefaultCountry == "UK") {
      this.selectedCountryIndex = 1;
    }
    if (JsonCollectionArray != null && JsonCollectionArray != undefined && JsonCollectionArray != "null") {
      this.ForecastClientCountryCollection = JSON.parse(JsonCollectionArray);
      this.RegionalCollection = this.ForecastClientCountryCollection;
    } else {
      await this.getRegioanlcollectionAndCurrency(this.UserId);
    }
  }
  getRegioanlcollectionAndCurrency(UserId) {
    if (UserId != null) {
      let jsonCountryCollection = null;
      jsonCountryCollection = localStorage.getItem("ForecastClientCountryCollection");
      console.log("jsonCountryCollection", jsonCountryCollection);
      if (localStorage.getItem("ForecastClientCountryCollection") != "null" && localStorage.getItem("ForecastClientCountryCollection") != null) {
        this.RegionalCollection = JSON.parse(jsonCountryCollection);
        if (this.RegionalCollection != null) {
        }
      } else {
        this.SpinnerService.show();
        this.listservice.getRegionalCollection(UserId).subscribe(
          (response: any) => {
            if(response.length == 0){
              this.currencyerror = true;
            }
            this.SpinnerService.hide();
            this.RegionalCollection = response;
            console.log("RegionalCollection", this.RegionalCollection);
          }
        )
      }
    }
  }
  onOptionsSelected($event) {
    this.RegionalCountryCollectionIndex = parseInt($event.target.value);
  }

  handleFileInput = (files: FileList) => {
    let fileToUpload = files.item(0);
    if (fileToUpload === null) {
      return false;
    }
    let CurrentUserID = localStorage.getItem('CurrentUserID');
    let ClientId = localStorage.getItem('clientID');
    this.formData = new FormData();
    this.formData.append('file', fileToUpload, fileToUpload.name);
    this.formData.append('CurrentUserID', CurrentUserID);
    this.formData.append('ClientId', ClientId);
  }

  importForecast() {
    if (this.formData.get("file") === null) {
      return false;
    }
    const notifier = this.injector.get(NotificationService);
    this.SpinnerService.show();
    this.listservice.importForecast(this.formData, this.RegionalCountryCollectionIndex).subscribe(
      (response: any) => {
        //this.delay(5000);
        console.log("File upload response", response);
        if (response instanceof HttpResponse) {
          var forecastName = response.body;
          this.SpinnerService.hide();
          localStorage.setItem("systemCalculation", "true");
          localStorage.setItem("importedForecast", forecastName);
          notifier.showSuccess("Forecast Imported Successfully.")
          this._router.navigateByUrl('/forecast');
        }
      })
  }
}
