import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { from, Observable } from 'rxjs';
import { catchError, map, tap } from "rxjs/operators";
import { environment } from '../../../../environments/environment';
import { Forecastmodel } from './forecastmodel';
// import { ok } from 'assert';
import { data } from 'jquery';
import { Methodmodel } from '../../record/method/service/methodmodel.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  private headers: Headers;
  Forecastmodel: Forecastmodel[] = [];
  Methodmodels: Methodmodel[] = [];
  id: number;
  listdata = [];

  constructor(private http: HttpClient) { }

  getCurrentUsernodeId(UserName): Observable<Forecastmodel[]> {
    return this.http.get(environment.url + "/api/Forecast/CurrentUserNodeId/" + UserName, { responseType: "json" })
      .pipe(map(
        (response: Forecastmodel[]) => {
          return response;
        }
      ));
  }

  getIntegrationDetails(nodeId): Observable<any> {
    return this.http.get(environment.url + "/api/Forecast/getintegrationDetails/" + nodeId, { responseType: "text" })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }
  getCountryVersionDetails(nodeId, CountryName): Observable<any> {
    return this.http.get(environment.url + "/api/Forecast/ChangeCountryVersion/" + nodeId + "/" + CountryName, { responseType: "text" })
      .pipe(map(
        (response) => {
          return response;
        }
      ));
  }
  getForecastList(nodeId): Observable<Forecastmodel[]> {
    return this.http.get(environment.url + "/api/Forecast/forecastlist/" + nodeId, { responseType: "json" })
      .pipe(map(
        (response: Forecastmodel[]) => {
          console.log("forecastmodel", response);
          return response;
        }
      ));
  }

  getCurrentUserGroup(nodeId): Observable<Forecastmodel[]> {
    return this.http.get(environment.url + "/api/UserMaintenance/GetCurrentUserGroupName/" + nodeId, { responseType: "json" })
      .pipe(map(
        (response: Forecastmodel[]) => {
          return response;
        }
      ));
  }

  CreateForecast(saveRecordForecast): Observable<Methodmodel[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/ForecastSetting/CreateForecast";
    return this.http.post(url, saveRecordForecast, { headers: reqHeaders })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  DeleteForecast(nodeId): Observable<any> {
    let clientID = localStorage.getItem("clientID");
    return this.http.delete(environment.url + "/api/Forecast/DeleteForecast/" + nodeId + "/" + clientID, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  CreateConsolidation(saveRecordForecast): Observable<Methodmodel[]> {
    let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let url = environment.url + "/api/Consolidation/CreateConsolidation";
    return this.http.post(url, saveRecordForecast, { headers: reqHeaders })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  getRegionalCollection(UserId): Observable<Methodmodel[]> {
    return this.http.get(environment.url + "/api/ForecastSetting/RegionalCollection/" + UserId, { responseType: "json" })
      .pipe(map(
        (response: Methodmodel[]) => {
          return response;
        }
      ));
  }

  importForecast(forecsatData: FormData, regionCollectionIndex): Observable<boolean> {

    // forecsatData.forEach((value, key) => {
    //   console.log(key + value)
    // });
    let url = environment.url + "/api/Forecast/ImportForecast/" + regionCollectionIndex;
    return this.http.post(url, forecsatData, {
      reportProgress: true,
      observe: 'events'
    })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));

  }

  exportForecast(forecastID, currentUserID, clientID): Observable<any> {
    // let reqHeaders = new HttpHeaders().set('Content-Type', 'application/xml');
    // let url = environment.url + "/api/Forecast/ExportForecast/";
    // return this.http.post(url + forecastID + "/" + clientID + "/" + currentUserID, { headers: reqHeaders, responseType: 'xml' })
    //   .pipe(map(
    //     (response: any) => {
    //       console.log("response", response);
    //       return response;
    //     }
    //   ));

    return this.http.get(environment.url + "/api/Forecast/ExportForecast/" + forecastID + "/" + clientID + "/" + currentUserID,
      { responseType: "text" })
      .pipe(map(
        (response: any) => {
          console.log("response", response);
          return response;
        }
      ));
    // //let headers = new HttpHeaders().append('Content-Type', 'application/xml');
    // return this.http.get(
    //   environment.url + "/api/Forecast/ExportForecast/" + forecastID + "/" + clientID + "/" + currentUserID,
    //   {
    //     responseType: "xml" // this is       
    //   }).pipe(
    //     map((data) => console.log(data))
    //   );
  }

  copyForecast(forecastID): Observable<boolean> {
    return this.http.get(environment.url + "/api/Forecast/CopyForecast/" + forecastID, { responseType: "json" })
      .pipe(map(
        (response: boolean) => {
          return response;
        }
      ));
  }

  copyForecastNovalues(forecastID): Observable<boolean> {
    return this.http.get(environment.url + "/api/Forecast/CopyForecastNoValues/" + forecastID, { responseType: "json" })
      .pipe(map(
        (response: boolean) => {
          return response;
        }
      ));
  }
}