import { Component, OnInit, Input } from '@angular/core';
import { MemoRecordOptionService } from '../../../service/memo-record-option.service';
import { MemoRecordOption } from '../../../model/memo-record-option';
import { ValuelistComponent } from '../../record/valuelist/valuelist.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-finacingrecord-option',
  templateUrl: './finacingrecord-option.component.html',
  styleUrls: ['./finacingrecord-option.component.scss']
})
export class FinacingrecordOptionComponent implements OnInit {

  @Input() RecordOptionlistCollection: any;
  recordCollectionList: any;
  displaycollection: boolean = false;
  constructor(public recordOptionsServices: MemoRecordOptionService,
    private ValuelistComponent: ValuelistComponent,
    private SpinnerService: NgxSpinnerService) {
  }

  async ngOnChanges() {
    let DisplayBanklevelRecordOptions = localStorage.getItem("BankLevelMemoOptions");
    
   // if(DisplayBanklevelRecordOptions!="" && DisplayBanklevelRecordOptions =="830842") {
      this.recordCollectionList =[];
      let BankOptions =  localStorage.getItem("bankOptionNodeId");
      this.SpinnerService.show();
      this.displaycollection = false;
      if(BankOptions!= '' && BankOptions!=undefined){
        await  this.recordOptionsServices.getFinacingRecordOptionLists(parseInt(BankOptions)).subscribe
        ((MemoRecordresponse: any) => {
          this.displaycollection = true;
          this.recordCollectionList = MemoRecordresponse; 
          this.SpinnerService.hide();
          if(DisplayBanklevelRecordOptions!="" && DisplayBanklevelRecordOptions =="830842") {
            $('.displaycollection').removeClass("hide");
          }else {
            $('.displaycollection').addClass("hide");
          }
          //localStorage.setItem("bankOptionNodeId", '');
        })
      }
  }
 async ngOnInit() {
    let DisplayBanklevelRecordOptions = localStorage.getItem("BankLevelMemoOptions");
    
    //if(DisplayBanklevelRecordOptions!="" && DisplayBanklevelRecordOptions =="830842") {
      let BankOptions =  localStorage.getItem("bankOptionNodeId");
      this.recordCollectionList =[];
      this.SpinnerService.show();
      this.displaycollection = false;
      if(BankOptions!= '' && BankOptions!=undefined){
        await  this.recordOptionsServices.getFinacingRecordOptionLists(parseInt(BankOptions)).subscribe
        ((MemoRecordresponse: any) => {
          this.displaycollection = true;
          this.recordCollectionList = MemoRecordresponse; 
          if(DisplayBanklevelRecordOptions!="" && DisplayBanklevelRecordOptions =="830842") {
            $('.displaycollection').removeClass("hide");
          }else {
            $('.displaycollection').addClass("hide");
          }
          this.SpinnerService.hide();
        })
      }
    
  }


  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      if (validatebynumberFormate == '.') {
        $event.target.value = "0.";
      } else {
        $event.target.value = validatebynumberFormate;
      }
    }
  }
  fixedAssetOpeningEntryCollectionchange($event, nodeId, oldvalue) {
    if ($event.target.value == '') {
      return;
    }
    let newValue = parseFloat($event.target.value.replaceAll(/,/g, '')).toFixed(2);
    let oldvalues = parseFloat(oldvalue.toString()).toFixed(2);
    if (newValue == oldvalues) {
      return;
    }
    let collectionlist: any = this.recordCollectionList;
    let convertjson = {
      value: newValue,
      selectedNodeId: nodeId,
      activeRecordNodeId: collectionlist.destinationDepreciationModels[0].recordNodeId
    }
    this.SpinnerService.show();
    this.recordOptionsServices.GetRecordFixedassetOpeningEntry(convertjson).subscribe
      (
        (response: any) => {
          try {
            this.ValuelistComponent.DisplayAllCommonDataArray = [];
            this.ValuelistComponent.BudgetDerrivationView = [];
            this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
            this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
            this.ValuelistComponent.PeriodTabView = response.getPeriodTabView;
            this.SpinnerService.hide();
          } catch (error) {
            this.SpinnerService.hide();
          }
        }
      )
  }
  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }

  finacingRecordValuesChanges($event, CollectionList) {
    if ($event.target.value == '') {
      return;
    }
    let newValue = parseInt($event.target.value.replaceAll(/,/g, ''))
    let value = CollectionList[0].nodeId;
    this.SpinnerService.show();

    this.recordOptionsServices.GetRecordFinacingOpeningEntry(value, newValue).subscribe
      (
        (response: any) => {
          try {
            this.SpinnerService.hide();
          } catch (error) {
            this.SpinnerService.hide();
          }
        }
      )
  }

}
