import { Component ,OnInit, Input } from '@angular/core';
import { MemoRecordOptionService } from '../../../service/memo-record-option.service';
import { MemoRecordOption } from '../../../model/memo-record-option';
import { ValuelistComponent } from '../../record/valuelist/valuelist.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-finnaced-assest-record-options',
  templateUrl: './finnaced-assest-record-options.component.html',
  styleUrl: './finnaced-assest-record-options.component.scss'
})
export class FinnacedAssestRecordOptionsComponent {
 // @Input() RecordOption: any;
  recordCollectionList: [];
  displaycollection: boolean;
  constructor(public recordOptionsServices: MemoRecordOptionService,
    private ValuelistComponent: ValuelistComponent,
    private SpinnerService: NgxSpinnerService) { }

  ngOnChanges() {
    this.displaycollection = false;
    let RecordOptions = localStorage.getItem("Recordoptions");
    this.recordOptionsServices.getFinnacedAssetRecordOptionLists(RecordOptions).subscribe
      ((responseFinnacedAssest: any) => {
        this.displaycollection = true;
        this.recordCollectionList = responseFinnacedAssest;
        console.log(this.recordCollectionList);
      })
   
  }
  ngOnInit() {
    this.displaycollection = false;
    let RecordOptions = localStorage.getItem("Recordoptions");
    this.recordOptionsServices.getFinnacedAssetRecordOptionLists(RecordOptions).subscribe
      ((responseFinnacedAssest: any) => {
        this.displaycollection = true;
        this.recordCollectionList = responseFinnacedAssest;
        console.log(this.recordCollectionList);
      })
  }

  depreciationDestinationChange(collection, $event) {
    let selectedNodeId = parseInt($event.target.value);
    let CollectionList: any = this.recordCollectionList;
    let NodeId = CollectionList.nodeId;
   // this.SpinnerService.show();
    this.recordOptionsServices.changeFinnacedAssetDestinationDepreciation(NodeId, selectedNodeId, collection[0].recordNodeId).subscribe
      (
        (response: any) => {
          try {
            localStorage.setItem("SaveCalulcateEvent","true");
          } catch (error) {
            //this.SpinnerService.hide();
          }

        }
      )
  }

  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }

  fixedAssetOpeningEntryCollectionchange($event, nodeId, oldvalue) {
    if ($event.target.value == '') {
      return;
    }
    let newValue = parseFloat($event.target.value.replaceAll(/,/g, '')).toFixed(2);
    let oldvalues = parseFloat(oldvalue.toString()).toFixed(2);
    if (newValue == oldvalues) {
      return;
    }
    let collectionlist: any = this.recordCollectionList;
    let convertjson = {
      value: newValue,
      selectedNodeId: nodeId,
      activeRecordNodeId: collectionlist.destinationDepreciationModels[0].recordNodeId
    }
   // this.SpinnerService.show();
    this.recordOptionsServices.GetRecordFinnacedAssetOpeningEntry(convertjson).subscribe
      (
        (response: any) => {
          try {
            localStorage.setItem("SaveCalulcateEvent","true");
           // this.SpinnerService.hide();
          } catch (error) {
            this.SpinnerService.hide();
          }
        }
      )
  }

  FinancedAssetCallableOptions($event, nodeId){
      let selectedValue = $event.target.checked;
      this.recordOptionsServices.FinancedAssetCallableOption(nodeId, selectedValue).subscribe
      (
        (response: any) => {
          try {
            localStorage.setItem("SaveCalulcateEvent","true");
          } catch (error) {
            //this.SpinnerService.hide();
          }

        }
      )
  }

  FinancedAssetSalesOnPayment($event, nodeId){
    let selectedValue = $event.target.checked;
    this.recordOptionsServices.GetRecordFinnacedassetSalesTaxOnPaymentOption(nodeId, selectedValue).subscribe
    (
      (response: any) => {
        try {
          localStorage.setItem("SaveCalulcateEvent","true");
        } catch (error) {
          //this.SpinnerService.hide();
        }

      }
    )
}

  accumulatedDepreciationOpeningEntrychange($event, nodeId, oldvalue) {
    if ($event.target.value == '') {
      return;
    }
    let newValue = parseFloat($event.target.value.replaceAll(/,/g, '')).toFixed(2);
    let oldvalues = parseFloat(oldvalue.toString()).toFixed(2);
    if (newValue == oldvalues) {
      return;
    }
    let collectionlist: any = this.recordCollectionList;
    let convertjson = {
      value: newValue,
      selectedNodeId: nodeId,
      activeRecordNodeId: collectionlist.destinationDepreciationModels[0].recordNodeId
    }
   // this.SpinnerService.show();
    this.recordOptionsServices.GetFinnacedRecordAccumulatedDepreciationOpeningEntry(convertjson).subscribe
      (
        (response: any) => {
          try {
            localStorage.setItem("SaveCalulcateEvent","true");
           // this.SpinnerService.hide();
          } catch (error) {
           // this.SpinnerService.hide();
          }
        }
      )
  }

  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      if (validatebynumberFormate == '.') {
        $event.target.value = "0.";
      } else {
        $event.target.value = validatebynumberFormate;
      }
    }
  }
}
