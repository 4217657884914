import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MethodserviceService } from "../../component/record/method/service/methodservice.service";
import { TreeviewService } from '../../service/treeview.service';
import { ValuelistComponent } from '../../component/record/valuelist/valuelist.component';
import { NgxSpinnerService } from "ngx-spinner";
import { recordSaveModel } from 'src/app/model/recordSaveModel';
import { budgetdatamodified } from 'src/app/model/recordSaveModel';
//declare const $: any;
import * as $ from 'jquery';
import { ForecasttabsComponent } from 'src/app/common/forecasttabs/forecasttabs.component';
@Component({
  selector: 'app-calculated-loan-made',
  templateUrl: './calculated-loan-made.component.html',
  styleUrls: ['./calculated-loan-made.component.scss']
})


export class CalculatedLoanMadeComponent implements OnInit {


  @Input() startIndex: any;
  @Input() recordnodeType: any;
  @Input() ActiveTreenodeId: any;

  darrivationnode: any;
  inputdarrivation: any;
  displayvaluelistdata = [];
  displaydarrivation = [];
  sectiontreeview: any;
  displaynodevisehtml: any;
  months = [];
  getProfileCollection = [];
  addeddata: boolean;
  selectedProfile: any;
  selectedNodeIds: any;
  percentagevaluearray: any;
  nodarrivationarray = [];
  darrivationarray = [];
  activeId: any;
  enumInterestTypes: any;
  enumpaymentTiming: any;
  CalculatedloanMadeRecord: any = [];
  loantakenarray: any = {};
  PeriodTabViewStartIndex = 0;
  PeriodTabViewEndIndex = 11;
  //performance 
  CommonHtmlArrayResult: any;

  ActualsMode =
    {
      Hidden: 0,
      DisplayAsBudget: 1,
      DisplayOnly: 2,
      DerivedAsBudget: 3,
      DerivedFromActuals: 4,
      Enterable: 5,
    }
  //
  constructor(
    private methodservice: MethodserviceService,
    private TreeviewServices: TreeviewService,
    private ValuelistComponent: ValuelistComponent,
    private forecasttabsComponent: ForecasttabsComponent,
    private SpinnerService: NgxSpinnerService) {
    this.activeId = localStorage.getItem('ForecastId');
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  @ViewChild('tree') tree;

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngOnInit() {
  }

  async ngOnChanges() {
    if (this.startIndex == undefined) {
      return;
    }


    let listarray = [];
    this.displaydarrivation = [];
    this.displayvaluelistdata = [];
    this.getProfileCollection = [];

    this.selectedProfile = '';
    this.sectiontreeview = '';
    //this.getloopyears();
    this.inputdarrivation = '';
    this.addeddata = false;
    this.CalculatedloanMadeRecord = [];
    $('.OverdraftInterestRate').addClass('col-sm-5');
    if (this.startIndex != undefined) {
      this.PeriodTabViewStartIndex = parseInt(this.startIndex) * 12;
      this.PeriodTabViewEndIndex = this.PeriodTabViewStartIndex + 12;
      this.PeriodTabViewStartIndex = this.PeriodTabViewStartIndex;
      this.PeriodTabViewEndIndex = this.PeriodTabViewEndIndex;
      this.methodservice.getLoanMadeCalcultedRecord(this.activeId, this.ActiveTreenodeId).subscribe(
        (response: any) => {
          this.enumInterestTypes = this.interestTypesenum();
          this.enumpaymentTiming = this.paymenttimingenum();
          this.CalculatedloanMadeRecord = response;
          this.loantakenarray = {
            "nodeID": this.ActiveTreenodeId,
            "DisplayLoanDetailData": [
              {
                "InterestTypes": this.CalculatedloanMadeRecord.interestTypes,
                "PaymentFrequencies": this.CalculatedloanMadeRecord.paymentFrequencies.numberFormatPrefix,
                "PaymentTimings": this.CalculatedloanMadeRecord.paymentTimings,
                "isNewLoan": this.CalculatedloanMadeRecord.isNewLoan,
                "LoanDrawdownPeriodIndex": this.CalculatedloanMadeRecord.loanDrawdownPeriodIndex,
                "Term": this.CalculatedloanMadeRecord.term,
                "LoanBalance": this.CalculatedloanMadeRecord.loanBalance,
                "InterestRate": this.CalculatedloanMadeRecord.interestRate,
                "Payment": this.CalculatedloanMadeRecord.payment.toFixed(2),
                "HasDepositPayment": this.CalculatedloanMadeRecord.hasDepositPayment,
                "CanHaveDepositPayment": this.CalculatedloanMadeRecord.canHaveDepositPayment,
                "ShowDeposit": this.CalculatedloanMadeRecord.showDeposit,
                "ShowDepositPayment": this.CalculatedloanMadeRecord.showDepositPayment,
                "CanHaveBulletPayment": this.CalculatedloanMadeRecord.canHaveBulletPayment,
                "ShowBullet": this.CalculatedloanMadeRecord.showBullet,
                "ShowBulletPayment": this.CalculatedloanMadeRecord.showBulletPayment,
                "CanHaveBalloonPayment": this.CalculatedloanMadeRecord.canHaveBalloonPayment,
                "CanHaveHolidayPeriod": this.CalculatedloanMadeRecord.canHaveHolidayPeriod,
                "HasBulletPayment": this.CalculatedloanMadeRecord.hasBulletPayment,
                "HasBalloonPayment": this.CalculatedloanMadeRecord.hasBalloonPayment,
                "HasHolidayPeriod": this.CalculatedloanMadeRecord.hasHolidayPeriod,
                "DepositPayment": this.CalculatedloanMadeRecord.depositPayment,
                "BulletPayment": this.CalculatedloanMadeRecord.bulletPayment,
                "MonthsBulletDelay": this.CalculatedloanMadeRecord.monthsBulletDelay,
                "BalloonPayment": this.CalculatedloanMadeRecord.balloonPayment,
                "MonthsHoliday": this.CalculatedloanMadeRecord.monthsHoliday,
                "LoanPrincipal": this.CalculatedloanMadeRecord.loanPrincipal,
                "IsPeriodStartEnabled": this.CalculatedloanMadeRecord.isPeriodStartEnabled,
                "SelectedIndex": this.CalculatedloanMadeRecord.selectedIndex
              }
            ]
          }
          this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'] = [];
        },
        err => {
          this.SpinnerService.hide();
          throw err;
        })



    }

  }

  interestTypesenum() {
    let interesttypesenum = [{
      0: 'Undefined',
      1: 'CompoundInterest',
      2: 'SimpleInterest',
      3: 'InterestOnly',
    }]
    return interesttypesenum;
  }

  paymenttimingenum() {
    let paymenttimingenum = [{
      1: 'InAdvance',
      2: 'InArrears'
    }]
    return paymenttimingenum;
  }

  IsEnabled(Index, ValueList) {

    if (this.IsActual(Index, ValueList) && ValueList.actualsMode != this.ActualsMode.DisplayAsBudget)
      return ValueList.actualsMode == this.ActualsMode.Enterable;
    else
      return ValueList.allowValueEntry && Index >= ValueList.currentActualsIndex;
  }

  IsActual(Index, ValueList) {
    return Index <= ValueList.currentActualsIndex;
  }

  FontWight(Index, ValueList) {

    if (this.IsActual(Index, ValueList)) {
      if (ValueList.actualsMode >= this.ActualsMode.DerivedAsBudget)
        return "Bold";
      else if (ValueList.actualsMode == this.ActualsMode.Hidden)
        return "Thin";
    }
    return "Normal";
  }

  IsVisiable(Index, ValueList) {
    return Index >= ValueList.startIndex && Index <= ValueList.endIndex && (ValueList.actualsMode > this.ActualsMode.Hidden || !this.IsActual(Index, ValueList));
  }



  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }


  //Calculate As Balance
  CalculateAsBalance(recordNodeId, event) {
    let selectedOption = 0;
    if (event.target.checked == true) {
      selectedOption = 1;
    }
    this.methodservice.CalculateAsBalance(recordNodeId.selectNodeId, selectedOption).subscribe(
      (response) => {
        return response;
      }
    )

  }

  valuelistregrex($event) {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    inputValue.value = $event.target.value.replace(/\(/g, '-').replace(/\)/g, '');
  }

  LimitOnly($event, number): boolean {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    let eventValue = inputValue.value;
    if (eventValue != '') {
      if (this.check(eventValue.toString()) == true) {
        let data = eventValue.replace(/\(/g, '-').replace(/\)/g, '');
        let realvalue = parseInt(data.replace('-', ''));
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      } else {
        let realvalue = parseInt(eventValue);
        if (parseInt(number) >= realvalue) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }
  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode == 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }

  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      if (validatebynumberFormate == '.') {
        $event.target.value = "0.";
      } else {
        $event.target.value = validatebynumberFormate;
      }
    }
  }
  changeInterestType($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['InterestTypes'] = parseInt($event);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("InterestTypes");
    this.UpdateRecordModified(this.loantakenarray);
    //this.updatearray(this.loantakenarray);
  }

  changepaymentFrequcncy($event, loantakenRecordValue) {
    this.loantakenarray.DisplayLoanDetailData[0]['PaymentFrequencies'] = $event.toString();
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("PaymentFrequencies");
    this.UpdateRecordModified(this.loantakenarray);
    //this.updatearray(this.loantakenarray);
  }
  changeTerm($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['Term'] = parseInt($event.replaceAll(/,/g, ''));
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("Term");
    this.UpdateRecordModified(this.loantakenarray);
    //this.updatearray(this.loantakenarray);
  }

  changeloanmadePeriod($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['SelectedIndex'] = parseInt($event);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("SelectedIndex");
    this.UpdateRecordModified(this.loantakenarray);
    //this.updatearray(this.loantakenarray);
  }
  changeLoanBalance($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['LoanBalance'] = parseFloat($event.replaceAll(/,/g, '')).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("LoanBalance");
    this.UpdateRecordModified(this.loantakenarray);
    //this.updatearray(this.loantakenarray);
  }
  HasBallooneEvent($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.loantakenrecordballon').addClass('hidden');
    } else {
      $('.loantakenrecordballon').removeClass('hidden');
    }

    this.loantakenarray.DisplayLoanDetailData[0]['HasBalloonPayment'] = $event.target.checked;
    this.CalculatedloanMadeRecord.hasBalloonPayment = $event.target.checked;
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("HasBalloonPayment");
    this.UpdateRecordModified(this.loantakenarray);
    //this.updatearray(this.loantakenarray);
  }
  hasHolidayPeriodEvent($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.loantakenrecordhollidayPeriodEvent').addClass('hidden');
    } else {
      $('.loantakenrecordhollidayPeriodEvent').removeClass('hidden');
    }

    //this.loantakenarray.DisplayLoanDetailData[0]['HasHolidayPeriod'] = $event.target.checked;
    //this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("HasHolidayPeriod");

    this.loantakenarray.DisplayLoanDetailData[0]['HasHolidayPeriod'] = $event.target.checked;
    this.CalculatedloanMadeRecord.hasHolidayPeriod = $event.target.checked;
    this.CalculatedloanMadeRecord.canHaveBulletPayment = !$event.target.checked;
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("HasHolidayPeriod");
    this.UpdateRecordModified(this.loantakenarray);
    //this.updatearray(this.loantakenarray);
  }
  changepaymentTiming($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['PaymentTimings'] = parseInt($event);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("PaymentTimings");
    this.UpdateRecordModified(this.loantakenarray);

  }
  changeInterestRate($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['InterestRate'] = parseFloat($event.replaceAll(/,/g, '')).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("InterestRate");
    //this.updatearray(this.loantakenarray);
    this.UpdateRecordModified(this.loantakenarray);

  }
  changePayment($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    let value = $event.replaceAll(/,/g, '');
    this.loantakenarray.DisplayLoanDetailData[0]['Payment'] = parseFloat(value).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("Payment");
    // this.updatearray(this.loantakenarray);
    this.UpdateRecordModified(this.loantakenarray);

  }

  changeBaloon($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['BalloonPayment'] = parseFloat($event.replaceAll(/,/g, '')).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("BalloonPayment");
    //this.updatearray(this.loantakenarray);
    this.UpdateRecordModified(this.loantakenarray);

  }

  IsnewLoanchangeEvent($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.loantakenrecord-periodStart').addClass('hidden');
    } else {
      $('.loantakenrecord-periodStart').removeClass('hidden');
    }
    this.loantakenarray.DisplayLoanDetailData[0]['IsNewLoan'] = $event.target.checked;
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("IsNewLoan");
    //this.updatearray(this.loantakenarray)
    this.UpdateRecordModified(this.loantakenarray);
  }

  // onchange bullet event
  OnchangeBullet($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.loantakenrecord-BulletPayment').addClass('hidden');
      $('.loantakenrecord-PaymentMonth').addClass('hidden');
    } else {
      $('.loantakenrecord-BulletPayment').removeClass('hidden');
      $('.loantakenrecord-PaymentMonth').removeClass('hidden');
    }
    // this.loantakenarray.DisplayLoanDetailData[0]['HasBulletPayment'] = $event.target.checked;
    // this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("HasBulletPayment");
    // this.UpdateRecordModified(this.loantakenarray);

    this.loantakenarray.DisplayLoanDetailData[0]['HasBulletPayment'] = $event.target.checked;
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("HasBulletPayment");
    this.CalculatedloanMadeRecord.canHaveHolidayPeriod = !$event.target.checked;
    this.UpdateRecordModified(this.loantakenarray);
  }
  // onchange bullet event
  OnchangeDeposit($event, loantakenRecordValue) {
    if ($event.target.checked == false) {
      $('.enabledepositepayment').addClass('hidden');
    } else {
      $('.enabledepositepayment').removeClass('hidden');
    }
    this.loantakenarray.DisplayLoanDetailData[0]['HasDepositPayment'] = $event.target.checked;
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("HasDepositPayment");
    //this.updatearray(this.loantakenarray)
    this.UpdateRecordModified(this.loantakenarray);
  }
  changeBulletPayment($event, loantakenRecordValue) {

    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['BulletPayment'] = parseFloat($event.replaceAll(/,/g, '')).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("BulletPayment");
    console.log(this.loantakenarray);
    //this.updatearray(this.loantakenarray);
   // this.UpdateRecordModified(this.loantakenarray);
  }
  changeBulletPaymentMonth($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['MonthsBulletDelay'] = parseInt($event.replaceAll(/,/g, ''));
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("MonthsBulletDelay");
    //this.updatearray(this.loantakenarray);
    this.UpdateRecordModified(this.loantakenarray);
  }
  // update holiday(Month)
  changeholidaymonthText($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['MonthsHoliday'] = parseInt($event.replaceAll(/,/g, ''));
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("MonthsHoliday");
    //this.updatearray(this.loantakenarray);
    this.UpdateRecordModified(this.loantakenarray);
  }

  // change deposite code 
  changeDeposite($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['DepositPayment'] = parseFloat($event.replaceAll(/,/g, '')).toFixed(2);
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("DepositPayment");
    //this.updatearray(this.loantakenarray);
    this.UpdateRecordModified(this.loantakenarray);
  }
  changeAssetSold($event, loantakenRecordValue) {
    if ($event == '') {
      return;
    }
    this.loantakenarray.DisplayLoanDetailData[0]['assetSoldType'] = parseInt($event.target.value.replaceAll(/,/g, ''));
    this.loantakenarray.DisplayLoanDetailData[0]['ActiveRecord'].push("assetSoldType");
    //this.updatearray(this.loantakenarray);
    this.UpdateRecordModified(this.loantakenarray);

  }
  UpdateRecordModified(loantakenarray) {
    let UpdateLoanTakenArray = new recordSaveModel();
    UpdateLoanTakenArray.recordNodeID = loantakenarray.nodeID;
    let budgetData = new budgetdatamodified();
    budgetData.valuenodeId = loantakenarray.nodeID;
    budgetData.valuelist = [];
    budgetData.selectedNodes = [];
    budgetData.unselectedNodes = [];
    budgetData.hasLoanOtherOptions = true;
    budgetData.isDerivation = false;
    budgetData.isNodeSelection = false;
    budgetData.loanCalculationModels = loantakenarray;
    UpdateLoanTakenArray.budgetData.push(budgetData);
    this.ValuelistComponent.recordModified(UpdateLoanTakenArray);
  }
  // update record
  updatearray(updateArrayValue) {
    this.SpinnerService.show();
    this.methodservice.updateCalculatedLoanMadeRecord(updateArrayValue).subscribe(
      async (response: any) => {

        this.ValuelistComponent.DisplayAllCommonDataArray = [];
        this.ValuelistComponent.BudgetDerrivationView = [];
        this.ValuelistComponent.recordnodeType = "";
        this.ValuelistComponent.calculatedLoanMadeRecord = "";

        this.ValuelistComponent.recordnodeType = "50017";
        this.ValuelistComponent.calculatedLoanMadeRecord = "50017";
        let data = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        let derrivationdata = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
        this.ValuelistComponent.DisplayAllCommonDataArray = response.getAllDisplayBudgetDataValue[0].getDisplayBudgetData;
        // this.ValuelistComponent.DisplayAllCommonDataArray = realArray;
        this.ValuelistComponent.BudgetDerrivationView = response.getAllDisplayBudgetDataValue[0].getDataDerrivation;
        //this.ValuelistComponent.BudgetDerrivationView = realDerrivation;
        this.ValuelistComponent.PeriodTabView = response.getPeriodTabView;
        this.forecasttabsComponent.childEventClicked(response.nodeId);
        this.SpinnerService.hide();


      })
  }

  arrMove(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  updateLoanTakenrecord() {
    this.SpinnerService.show();
    this.methodservice.getLoanMadeCalcultedRecord(this.activeId, this.ActiveTreenodeId).subscribe(
      (response: any) => {
        this.enumInterestTypes = this.interestTypesenum();
        this.enumpaymentTiming = this.paymenttimingenum();
        this.CalculatedloanMadeRecord = response;
        this.SpinnerService.hide();
        this.loantakenarray = {
          "nodeID": this.ActiveTreenodeId,
          "DisplayLoanDetailData": [
            {
              "InterestTypes": this.CalculatedloanMadeRecord.interestTypes,
              "PaymentFrequencies": this.CalculatedloanMadeRecord.paymentFrequencies.numberFormatPrefix,
              "PaymentTimings": this.CalculatedloanMadeRecord.paymentTimings,
              "isNewLoan": this.CalculatedloanMadeRecord.isNewLoan,
              "LoanDrawdownPeriodIndex": this.CalculatedloanMadeRecord.loanDrawdownPeriodIndex,
              "Term": this.CalculatedloanMadeRecord.term,
              "LoanBalance": this.CalculatedloanMadeRecord.loanBalance,
              "InterestRate": this.CalculatedloanMadeRecord.interestRate,
              "Payment": this.CalculatedloanMadeRecord.payment,
              "HasDepositPayment": this.CalculatedloanMadeRecord.hasDepositPayment,
              "CanHaveDepositPayment": this.CalculatedloanMadeRecord.canHaveDepositPayment,
              "ShowDeposit": this.CalculatedloanMadeRecord.showDeposit,
              "ShowDepositPayment": this.CalculatedloanMadeRecord.showDepositPayment,
              "CanHaveBulletPayment": this.CalculatedloanMadeRecord.canHaveBulletPayment,
              "ShowBullet": this.CalculatedloanMadeRecord.showBullet,
              "ShowBulletPayment": this.CalculatedloanMadeRecord.showBulletPayment,
              "CanHaveBalloonPayment": this.CalculatedloanMadeRecord.canHaveBalloonPayment,
              "CanHaveHolidayPeriod": this.CalculatedloanMadeRecord.canHaveHolidayPeriod,
              "HasBulletPayment": this.CalculatedloanMadeRecord.hasBulletPayment,
              "HasBalloonPayment": this.CalculatedloanMadeRecord.hasBalloonPayment,
              "HasHolidayPeriod": this.CalculatedloanMadeRecord.hasHolidayPeriod,
              "DepositPayment": this.CalculatedloanMadeRecord.depositPayment,
              "BulletPayment": this.CalculatedloanMadeRecord.bulletPayment,
              "MonthsBulletDelay": this.CalculatedloanMadeRecord.monthsBulletDelay,
              "BalloonPayment": this.CalculatedloanMadeRecord.balloonPayment,
              "MonthsHoliday": this.CalculatedloanMadeRecord.monthsHoliday,
              "LoanPrincipal": this.CalculatedloanMadeRecord.loanPrincipal,
              "IsPeriodStartEnabled": this.CalculatedloanMadeRecord.isPeriodStartEnabled,
              "SelectedIndex": this.CalculatedloanMadeRecord.selectedIndex
            }
          ]
        }
      },
      err => {
        this.SpinnerService.hide();
        throw err;
      })
  }
}
