<igx-content>
    <div class="">
        <h3>Payroll Table & Options</h3>
    </div>
    <div class="bs-example" *ngIf="NavitemSubmenu.length>0">
        <!-- <ul class="nav nav-tabs">
        <li class="nav-item" 
        *ngFor="let result of NavitemSubmenu;  let i=index" (click)="ChangePensionType(result)" >
            <a href="#{{result.type}}" class="nav-link" [ngClass]="{'active':i==ActivetabView}" data-toggle="tab">{{result.name}}</a>
        </li>
    </ul> -->
        <div class="col-sm-12" style="margin-bottom: 10px;">
            <button class="btn  col-sm-1" [ngClass]="{'btn-primary': activetab=='Payrates'}" (click)="payratesOpen()">
                PAYE Rates</button>
            <button class="btn  col-sm-2" [ngClass]="{'btn-primary': activetab=='PensionDetails'}"
                style="margin-left: 3px;" (click)="PensionDetailsOpen()"> Pension Details</button>
            <button *ngIf="CountryVersion!='' && CountryVersion=='GB'" class="btn  col-sm-2"
                [ngClass]="{'btn-primary': activetab=='NIDetails'}" (click)="NIDetailsOpen()">NI Details</button>
        </div>
        <div class="">
            <div *ngIf="activetab=='Payrates'">
                <div class="">
                    <div class="col-sm-12" style="height: 630px;padding: 0px;border: 2px solid #c1c1c1;">
                        <div class="col-sm-4" *ngIf="PayRatesCollections.length>0">
                            <igx-content>
                                <div style="border-right: 2px solid #c1c1c1;height: 630px;">
                                    <h2>PAYE Rates</h2>
                                    <div class="">
                                        <button class="btn btn-primary col-sm-3"
                                            style="font-size: 16px;margin: 0px 3px 4px 0px;"
                                            (click)="AddPayRates()">New</button>
                                        <button class="btn deleteCancelButtonColor col-sm-3" 
                                        style="font-size: 16px;margin: 0 0 0 3px;"
                                            (click)="DeletePayRates()">Delete</button>
                                    </div>
                                    <table class="table table-bordered">
                                        <thead>
                                            <th class="selectColumn" scope="col-sm-1"></th>
                                            <th class="valueColumn" scope="col">Amount</th>
                                            <th class="valueColumn" scope="col">Percentages</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let Payrates of PayRatesCollections; let i=index;"
                                                [ngClass]="{'selected':i==PayRateActiveRecord}"
                                                (click)="changeValueList(i)">
                                                <th class="selectColumn" scope="col-sm-1"><span></span></th>
                                                <td class="valueColumn" [ngClass]="{'Active':i==PayRateActiveRecord}">
                                                    <input type="text" style="border:none" value="{{Payrates.amount}}"
                                                        (blur)="PayratesAmountChange(i, Payrates, $event)"
                                                        (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                                                        name="PayratesAmount[i]" class="col-sm-9"
                                                        [ngClass]="{'Payratesname':i==PayRateActiveRecord}" />
                                                </td>
                                                <td class="valueColumn" [ngClass]="{'Active':i==PayRateActiveRecord}">
                                                    <input type="text" style="border:none"
                                                        value="{{Payrates.percentage}}"
                                                        (blur)="PayratesPercentagesChange(i, Payrates, $event)"
                                                        name="PayratesPercentages[i]" class="col-sm-9"
                                                        [ngClass]="{'Payratesname':i==PayRateActiveRecord}" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </igx-content>
                        </div>
                        <div class="col-sm-3">
                            <igx-content>
                                <div id="Currencytab" style="border-right: 2px solid #c1c1c1;height: 630px; ">
                                    <h2>PAYE Options</h2>
                                    <div class="" *ngIf="PAYEOptions!=''" style="margin: 21px 0 35px 0;">
                                        <igx-select name="PAYEOPtions" [(ngModel)]="PAYEOptions.payePaymentMethod">
                                            <igx-select-item *ngFor="let results of PAYEOptions.validPayResults"
                                                [value]="results.validMethod"
                                                (click)='PAYEOptionsChanges(results.validMethod)'>
                                                {{results.label}}
                                            </igx-select-item>
                                        </igx-select>
                                    </div>
                                    <div class="textAllowns" style="margin-top: 10px;">
                                        <div>
                                            <h4>Employee Allowance</h4>
                                        </div>
                                        <div class="col-sm-12 employeeAllowanceBox">
                                            <div class="col-sm-2"><input type="checkbox" class="form-control"
                                                    [checked]="PAYEOptions.includeAllowanceInTax"
                                                    (change)="PAYEIncomeTextChange($event)" /></div>
                                            <div class="col-sm-8">Include Allowance for Income Tax</div>
                                        </div>
                                    </div>
                                </div>
                            </igx-content>

                        </div>
                        <div class="col-sm-5">

                            <div class="col-sm-12" style="margin-top:1%; height: 675px;">
                                <h2>Bonus</h2>
                                <div id="Currencytab" style="padding: 5px;" class="col-sm-12">
                                    <ul class="nav nav-tabs" style="margin: 1px 3px 16px 11px"
                                        *ngIf="BonusCollections.length>0">
                                        <li class="nav-item" style="margin: 0 8px -2px -1px;">
                                            <select form="Payrool-payrate" class="form-control"
                                                style="background: #007bff;color: white;font-size: 12px;font-weight: bold;height: 40px;">
                                                <option value="Annual" ng-reflect-value="Annual">Annual</option>
                                                <option value="All" ng-reflect-value="All">All</option>
                                            </select>
                                        </li>
                                        <li class="nav-item" *ngFor="let result of PeriodTabView.results;  let i=index"
                                            (click)="ChangePeriodType(result, i)">
                                            <a class="nav-link" [ngClass]="{'active':i==Activetabmenu}"
                                                data-toggle="tab">{{result.longLabel}}</a>
                                        </li>
                                    </ul>
                                    <div class="tab-pane fade testingData">
                                        <div class="BonusCollectionsValueListArray col-sm-9"
                                            *ngIf="BonusCollections.length>0">
                                            <div class="col-sm-12 ValueArray"
                                                *ngIf="BonusCollections[0].allowSetAllEntry == true">
                                                <label class="col-sm-5"
                                                    *ngIf="BonusCollections[0].allowSetAllEntry">SetAll</label>
                                                <input class="col-sm-5" style="text-align:end"
                                                    (blur)="SetAllPayrollBonusList(BonusCollections, $event)" value=""
                                                    *ngIf="BonusCollections[0].allowSetAllEntry" />
                                            </div>
                                            <div
                                                *ngFor="let result of BonusCollections[0].bonusCommisionValuesArray |slice:bonusCommisionStartIndex:bonusCommisionEndIndex+1; let i=index;trackBy:trackByFn ">
                                                <div class="col-sm-12 ValueArray">
                                                    <label
                                                        class="col-sm-5">{{MonthDisplay[i+bonusCommisionStartIndex].name
                                                        }}</label>
                                                    <input type="text" id="PayrollBonus{{i+bonusCommisionStartIndex}} "
                                                        class=" col-sm-5"
                                                        (blur)="MonthValuePayrollBonusList(BonusCollections, i+bonusCommisionStartIndex, $event)"
                                                        (click)="valuelistregrex($event)" (keypress)="isNumber($event)"
                                                        value="{{result}}" style="text-align:end" />
                                                    <!-- <input type="text"
                                                    id="PayrollBonus{{i+bonusCommisionStartIndex}}"
                                                    [(ngModel)]="BonusCollections[0].bonusCommisionValuesArray[i]"
                                                    class=" col-sm-5" 
                                                    [ngModelOptions]="{standalone: true}" 
                                                    style="text-align:end"/> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <p>All wages and salaries are multiplied by this figure to up lift to the bonus
                                        percent eg 110%</p>
                                    <p>Additional if you set this to 0 then all wages will be 0 being the (wages value
                                        $) * (0) = 0</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="activetab=='PensionDetails'">
                <div class="">
                    <div class="col-sm-12" style="margin-top: 1%;height: 630px;">
                        <div class="col-sm-4 PensionCollections.length>0">
                            <h2>Pension Scheme</h2>
                            <div class="">
                                <button class="btn btn-primary col-sm-3" style="font-size: 16px;margin-bottom: 4px;"
                                    (click)="AddPayPension()">New</button>
                                <button class="btn deleteCancelButtonColor col-sm-3"
                                style="font-size: 16px;margin: 0 0 0 3px;" (click)="DeletePension()">Delete</button>
                                <button class="btn SettingCancelButtonColor col-sm-4"
                                    [disabled]="DefaultPension==PensionActiveRecord" style="margin: 0 0 0 2px;"
                                    (click)="SetDefaultPension()">Default</button>

                            </div>
                            <table class="table table-bordered">
                                <thead>
                                    <th scope="col"></th>
                                    <th scope="col">Name</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let Pension of PensionCollectionsItems; let i=index;"
                                        [ngClass]="{'selected':i==PensionActiveRecord}"
                                        (click)="changePensionActiveList(i)">
                                        <td><span *ngIf="i==Pension.defaultIndex">*</span></td>
                                        <td [ngClass]="{'Active':i==PensionActiveRecord}">
                                            <input type="text" style="border:none" value="{{Pension.name}}"
                                                (blur)="PensionNameChange(i, Pension, $event)" name="PensionAmount[i]"
                                                class="col-sm-10" [ngClass]="{'Pensionname':i==PensionActiveRecord}" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-sm-4">
                            <h2>Employee Contribution</h2>
                            <div class="">
                                <button class="btn btn-primary col-sm-3" style="font-size: 16px;margin-bottom: 4px;"
                                    (click)="AddEmployee()">New</button>
                                <button class="btn deleteCancelButtonColor col-sm-3"
                                style="font-size: 16px;margin: 0 0 0 3px;"
                                    (click)="DeleteEmployee()">Delete</button>
                            </div>
                            <table class="table table-bordered">
                                <thead>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Percentage</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let employee of PensionCollections.employeeBandings; let i=index;"
                                        (click)="SelectedEmployeeSelection(i)"
                                        [ngClass]="{'Active':i==SelectedEmployee}">
                                        <td>
                                            <input type="text" style="border:none" value="{{employee.amount}}"
                                                (blur)="UpdateEmployerAmount($event, employee)" name="EmployeeAmount[i]"
                                                class="col-sm-10" />
                                        </td>
                                        <td>
                                            <input style="border:none" value="{{employee.percentage}}"
                                                (blur)="UpdateEmployerPercentage($event, employee)"
                                                name="EmployeePercentage[i]" class="col-sm-10" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-sm-4">
                            <h2>Employer Contribution</h2>
                            <div class="">
                                <button class="btn btn-primary col-sm-3" style="font-size: 16px;margin-bottom: 4px;"
                                    (click)="AddEmployer()">New</button>
                                <button class="btn deleteCancelButtonColor col-sm-3"
                                style="font-size: 16px;margin: 0 0 0 3px;"
                                    (click)="DeleteEmployer()">Delete</button>
                            </div>
                            <table class="table table-bordered">
                                <thead>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Percentage</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let employer of PensionCollections.employerBandings; let i=index;"
                                        (click)="SelectedEmployerSelection(i)"
                                        [ngClass]="{'Active':i==SelectedEmployer}">
                                        <td>
                                            <input type="text" style="border:none" value="{{employer.amount}}"
                                                (blur)="UpdateEmployerAmount($event, employer)" class="col-sm-10" />
                                        </td>
                                        <td>
                                            <input style="border:none" value="{{employer.percentage}}"
                                                (blur)="UpdateEmployerPercentage($event, employer)" class="col-sm-10" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="activetab=='NIDetails'">
                <div class="">
                    <div class="col-sm-12" style="margin-top: 1%;height: 630px;">
                        <div class="col-sm-4 NICollections.length>0">
                            <div class="">
                                <button class="btn btn-primary col-sm-3" style="font-size: 16px;margin-bottom: 4px;"
                                    (click)="AddNI()">New</button>
                                <button class="btn deleteCancelButtonColor col-sm-3"
                                style="font-size: 16px;margin: 0 0 0 3px;" (click)="DeleteNI()">Delete</button>
                                <button class="btn SettingCancelButtonColor col-sm-4"
                                    [disabled]="DefaultNI==NIActiveRecord" style="margin: 0 0 0 2px;"
                                    (click)="SetDefaultNI()">Default</button>

                            </div>
                            <table class="table table-bordered">
                                <thead>
                                    <th scope="col"></th>
                                    <th scope="col">Name</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let NIPension of NICollectionsItems; let i=index;"
                                        [ngClass]="{'selected':i==NIActiveRecord}" (click)="changeNIActiveList(i)">
                                        <td><span *ngIf="i==NIPension.defaultIndex">*</span></td>
                                        <td [ngClass]="{'Active':i==NIActiveRecord}">
                                            <input type="text" style="border:none" value="{{NIPension.name}}"
                                                (blur)="NINameChange(i, NIPension, $event)" name="NIAmount[i]"
                                                class="col-sm-10" [ngClass]="{'NIname':i==NIActiveRecord}" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-sm-4">
                            <h2>Employee Contribution</h2>
                            <div class="">
                                <button class="btn btn-primary col-sm-3" style="font-size: 16px;margin-bottom: 4px;"
                                    (click)="AddNIEmployee()">New</button>
                                <button class="btn deleteCancelButtonColor col-sm-3"
                                style="font-size: 16px;margin: 0 0 0 3px;"
                                    (click)="DeleteNIEmployee()">Delete</button>
                            </div>
                            <table class="table table-bordered">
                                <thead>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Percentage</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let employee of NICollections.employeeBandings; let i=index;"
                                        (click)="SelectedEmployeeSelection(i)"
                                        [ngClass]="{'Active':i==SelectedEmployee}">
                                        <td>
                                            <input type="text" style="border:none" value="{{employee.amount}}"
                                                (blur)="UpdateNIEmployerAmount($event, employee)"
                                                name="EmployeeAmount[i]" class="col-sm-10" />
                                        </td>
                                        <td>
                                            <input style="border:none" value="{{employee.percentage}}"
                                                (blur)="UpdateNIEmployerPercentage($event, employee)"
                                                name="EmployeePercentage[i]" class="col-sm-10" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-sm-4">
                            <h2>Employer Contribution</h2>
                            <div class="">
                                <button class="btn btn-primary col-sm-3" style="font-size: 16px;margin-bottom: 4px;"
                                    (click)="AddNIEmployer()">New</button>
                                <button class="btn deleteCancelButtonColor col-sm-3"
                                style="font-size: 16px;margin: 0 0 0 3px;"
                                    (click)="DeleteNIEmployer()">Delete</button>
                            </div>
                            <table class="table table-bordered">
                                <thead>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Percentage</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let employer of NICollections.employerBandings; let i=index;"
                                        (click)="SelectedEmployerSelection(i)"
                                        [ngClass]="{'Active':i==SelectedEmployer}">
                                        <td>
                                            <input type="text" style="border:none" value="{{employer.amount}}"
                                                (blur)="UpdateNIEmployerAmount($event, employer)" class="col-sm-10" />
                                        </td>
                                        <td>
                                            <input style="border:none" value="{{employer.percentage}}"
                                                (blur)="UpdateNIEmployerPercentage($event, employer)"
                                                class="col-sm-10" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <button class="btn button SettingSaveButtonColor col-sm-1" (click)="CalculateSave('save')"> Save</button>
            <button class="btn button SettingCancelButtonColor col-sm-1" style="margin: 0 0 0px 7px;"
                (click)="CalculateSave('close')"> Close</button>
        </div>
    </div>
</igx-content>