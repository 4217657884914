<div class="col-sm-12" *ngIf="displaycollection" style="height: 25px;margin: 5px 0 2px 1px; padding: 0px;">
    <div class="depreciationdestination col-sm-4" style="padding: 0px;">
        <input type="checkbox"
        [checked]="recordCollectionList.calleableOptionsModels.selected" 
        (change)="FinancedAssetCallableOptions($event,recordCollectionList.calleableOptionsModels.nodeId)"
         class="col-sm-2" style="width: 30px; height: 20px;margin: 0px 0 0 0;">
        <label class="col-sm-3" style="padding: 0px; font-size: 12px;"> {{recordCollectionList.calleableOptionsModels.name}} </label>
        <input type="checkbox" 
        (change)="FinancedAssetSalesOnPayment($event,recordCollectionList.salesTaxIncludePaymenOptions.nodeId)"
        [checked]="recordCollectionList.salesTaxIncludePaymenOptions.selected" class="col-sm-2" style="width: 30px; height: 20px;margin: 0px 0 0 0;">
        <label class="col-sm-5" style="padding: 0px; font-size: 12px;">{{recordCollectionList.salesTaxIncludePaymenOptions.name}}</label>
    </div>
    <div class="depreciationdestination col-sm-4" style="padding-left: 4px;">
        <label class="col-sm-4" style="padding: 0px;font-size: 12px;margin: -6px 0 0 0;">Depreciation Destination</label>
        <select name="depreciationdestination" style="height: 25px;"
            (change)="depreciationDestinationChange(recordCollectionList.destinationDepreciationModels,$event)"
            class="col-sm-7 form-control">
            <option *ngFor="let result of recordCollectionList.destinationDepreciationModels; let i=index;"
                [selected]="result.selectedindex == result.nodeId" value="{{i}}">{{result.name}}</option>
        </select>
    </div>
    <div class="accumulatedDepreciationOpeningEntryModels col-sm-2" style="padding: 0px;margin: -8px 0 0 0;">
        <label class="col-sm-12"
            style="padding: 0px; font-size: 12px; font-weight: 400;">Depreciation Opening</label>
        <input type="text" class="form-control col-sm-7" 
        style="text-align: right;height: 23px;margin: -6px 0 0 0;" (keypress)="isNumber($event)"
            (keyup)="ValidateValue($event)"
            (blur)="accumulatedDepreciationOpeningEntrychange($event,recordCollectionList.accumulatedDepreciationOpeningEntryCollection.nodeId ,recordCollectionList.accumulatedDepreciationOpeningEntryCollection.value)"
            value="{{recordCollectionList.accumulatedDepreciationOpeningEntryCollection.value}}" />
    </div>
    <div class="fixedAssetOpeningEntryCollection col-sm-2" style="padding: 0px 3px 0 12px;margin: -8px 0 0 0;">
        <label class="col-sm-12" style="padding: 0px; font-size: 12px; font-weight: 400;">Asset Opening Cost</label>
        <input type="text" class="form-control col-sm-8" (keypress)="isNumber($event)"
             style="text-align: right;height: 23px;margin: -6px 0 0 0;"
            (keyup)="ValidateValue($event)"
            (blur)="fixedAssetOpeningEntryCollectionchange($event, recordCollectionList.finnacedOpeningEntryCollection.nodeId,recordCollectionList.finnacedOpeningEntryCollection.value)"
            value="{{recordCollectionList.finnacedOpeningEntryCollection.value}}" />
    </div>
   
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>
</ngx-spinner>

