<div style="height: 30%;">
    <h2 class="HeaderColor" style="padding: 10px;width: 100%; margin-top: -5%;
    margin-bottom: 5%;"> Import Forecast </h2>
    <div class="col-sm-12">
        <label style="color: red;" *ngIf="currencyerror" >Country not loading..please logout and login and import forecast. </label>
        <div style="display: flex;flex-direction:row;" *ngIf="RegionalCollection!=null">
            <label class="col-sm-4 col-form-label">Country</label>
            <select name="ForecastCountry" class="form-control col-sm-6" (change)='onOptionsSelected($event)'
                style="color: black;">
                <option *ngFor="let CountryCollection of RegionalCollection; let CountryIndex = index"
                    [selected]="CountryIndex==selectedCountryIndex" value="{{CountryIndex}}">{{CountryCollection.name}}
                </option>
            </select>
        </div>
        <div style="display: flex;flex-direction:row;margin-top: 15px;">
            <label class="col-sm-4 col-form-label">Forecast To Import</label>
            <input type='file' class="col-sm-6" id="getFile" accept=".xml"
                (change)="handleFileInput($event.target.files)">
        </div>
        <div class="child-buttons" style="display: flex; justify-content: flex-end; float: right;width: 80%;
    position: absolute;">
            <button igxButton class="OkSaveButtonColor"
                style="margin-top: 20px;padding: 10px; float: right;margin-right: 20px;"
                (click)="importForecast()">Import Forecast</button>
        </div>
    </div>
    <!-- <label igxLabel>Important Forecast</label> -->
</div>