import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { MenuService } from '../../service/menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeviewService } from '../../service/treeview.service';
import { EnterActualsService } from '../../service/enter-actuals.service';
import { Treeview } from '../../service/treeview';
import { IgxIconService } from '@infragistics/igniteui-angular';
@Component({
  selector: 'app-rightsidebar-menu',
  templateUrl: './rightsidebar-menu.component.html',
  styleUrls: ['./rightsidebar-menu.component.scss']
})
export class RightsidebarMenuComponent implements OnInit {

  Treeviews: Treeview[] = [];
  activeId: any;
  forecastname = '';
  openingdata: any;
  IsCanActualStartActive = '';
  IsCanActualEnterActive = '';
  EnterOpenigCreditorNode: any = ''
  EnterOpeningDebtorsNode: any = '';
  NavitemSubmenu = [];
  navItems = [];
  public selected = [];
  public displaySubmenustyle = '';
  showDrawerClick: boolean;
  hideDrawerClick: boolean;
  tabs = [];
  showWhatIfbool: boolean;
  isConsolidation = 'false';
  constructor(
    private SpinnerService: NgxSpinnerService,
    public menuServices: MenuService,
    private treeview: TreeviewService,
    private enterActualsService: EnterActualsService,
    private _router: Router, private iconService: IgxIconService) {
    this.activeId = localStorage.getItem('ForecastId');
    let mini = true;
    this.forecastname = localStorage.getItem('ForecastName');
    this.isConsolidation = localStorage.getItem('isConsolidation');
  }
  public async navigate(item) {
    this.selected = [];
    this.displaySubmenustyle = 'block';
    // if (item.name == "Openings") {
    //   var activeForecastEnterOpeingdebtorsNodeId = localStorage.getItem('EnterOpeningDebotrs' + this.activeId);
    //   var activeForecastEnterOpeingcreditorsNodeId = localStorage.getItem('EnterOpeningCreditors' + this.activeId);
    //   if (activeForecastEnterOpeingdebtorsNodeId != null && activeForecastEnterOpeingcreditorsNodeId != null) {
    //     this.EnterOpeningDebtorsNode = activeForecastEnterOpeingdebtorsNodeId;
    //     this.EnterOpenigCreditorNode = activeForecastEnterOpeingcreditorsNodeId;
    //     let arrayvalue = [
    //       { name: 'Opening Trade Debtors', icon: 'paid', callback: { name: 'OpeningTradeDebotrSelectedNode', perameter: '', active: 'true' } },
    //       { name: 'Opening Trade Creditors', icon: 'credit_card', callback: { name: 'OpeningTradeCreditorSelectedNode', perameter: '', active: 'true' } },
    //       { name: 'Opening Balance Sheet', icon: 'star', callback: { name: 'EnterOpening', perameter: '', active: 'true' } }
    //     ]
    //     item.displaysubmenu = arrayvalue;
    //   } else {
    //     await this.OpeningTradeDebotrAndCreditor();
    //     let arrayvalue = [
    //       { name: 'Opening Trade Debtors', icon: 'paid', callback: { name: 'OpeningTradeDebotrSelectedNode', perameter: '', active: 'true' } },
    //       { name: 'Opening Trade Creditors', icon: 'credit_card', callback: { name: 'OpeningTradeCreditorSelectedNode', perameter: '', active: 'true' } },
    //       { name: 'Opening Balance Sheet', icon: 'star', callback: { name: 'EnterOpening', perameter: '', active: 'true' } }
    //     ]
    //     item.displaysubmenu = arrayvalue;
    //   }
    // }
    // console.log(item);
    this.selected.push(item);
  }

  RightsidebarmenClickEvent = "RightsidebarmenClickEventFunClick";
  RightsidebarmenClickEventFunClick(ForecastnodeId, Item) {
    if (Item.callback == '') {
      this[Item.callback.name](ForecastnodeId, Item);
    } else {
      if (Item.callback != '' && Item.callback.perameter == 'SaveWhatIf') {
        var eventgenarate: any = (<HTMLInputElement>document.getElementById('SaveWhatIfEntry'));
        eventgenarate.click();
      } else if (Item.callback != '' && Item.callback.perameter == 'SaveWhatIfAsBudget') {
        var eventgenarate: any = (<HTMLInputElement>document.getElementById('SaveWhatIfAsBudget'));
        eventgenarate.click();
      } else {
        this[Item.callback.name](ForecastnodeId, Item);
      }
    }


  }
  EliminationSelection() {
    this._router.navigateByUrl('/Eliminations');
  }
  GoodwillSelection() {
    this._router.navigateByUrl('/Goodwill');
  }
  ConsolidationSettings() {
    this._router.navigateByUrl('/ConsolidationSettings');
  }
  RecalcConsolidaiton() {
    this.SpinnerService.show();
    if (this.activeId === undefined || this.activeId === null) {
      this.activeId = localStorage.getItem('ForecastId');
    }
    this.menuServices.recalcConsolidation(this.activeId).subscribe(
      ((response: any) => {
        this.SpinnerService.hide();
        this._router.navigateByUrl('/forecast-list');
      })
    )
  }

  SettingCollection(ForecastnodeId, Item) {
    localStorage.setItem('SettingMenuActive', Item.name);
    this._router.navigateByUrl('/settings');
  }
  sidebarmenuContain() {
    this.displaySubmenustyle = '';
    // console.log(this.displaySubmenustyle, this.showDrawerClick)
    if (this.showDrawerClick = true) {
      this.displaySubmenustyle = 'block';
    } else {
      this.displaySubmenustyle = '';
    }
    this.selected = [];
  }


  closeTab(index: number) {
    this.tabs.splice(index, 1);
  }
  renderSidebarmenu() {
    this.displaySubmenustyle = '';
    this.IsCanActualStartActive = localStorage.getItem('IsCanStartActual');
    this.IsCanActualEnterActive = localStorage.getItem('IsCanEnterActual');
    console.log(this.IsCanActualStartActive);
    let countryName = localStorage.getItem("CountryName");
    let CountryVersion = {}
    if (countryName == "GB") {
      CountryVersion = { name: 'VAT Rates', icon: 'corporate_fare', callback: { name: 'SettingCollection', perameter: 'VAT Rates', active: true } };
    } else {
      CountryVersion = { name: 'GST Rates', icon: 'corporate_fare', callback: { name: 'SettingCollection', perameter: 'GST Rates', active: true } }
    }
    this.NavitemSubmenu = [];
    this.NavitemSubmenu = [
      //  { name: 'Home', icon: 'home', displaysubmenu: [], callback: { name: 'forecast', perameter: '', active: 'true' } },
      { name: 'Reports', icon: 'assessment', displaysubmenu: [], callback: { name: 'openReport', perameter: '', active: 'true' } },
      {
        name: 'Settings', icon: 'settings',
        displaysubmenu: [
          { name: 'Forecast Details', icon: 'details', callback: { name: 'SettingCollection', perameter: 'Forecast Details', active: true } },
          { name: 'Currencies', icon: 'monetization_on', callback: { name: 'SettingCollection', perameter: 'Currencies', active: true } },
          CountryVersion,
          /*{ name: 'GST Rates', icon: 'corporate_fare', callback: { name: 'SettingCollection', perameter: 'GST Rates', active: true } },*/
          { name: 'Profiles', icon: 'settings_accessibility', callback: { name: 'SettingCollection', perameter: 'Profiles', active: true } },
          { name: 'Payroll Tables & Options', icon: 'receipt_long', callback: { name: 'SettingCollection', perameter: 'Payroll Tables & Options', active: true } },
          { name: 'Forecast Other Options', icon: 'settings_applications', callback: { name: 'SettingCollection', perameter: 'Forecast Other Options', active: true } },
          { name: 'Preferences', icon: 'room_preferences', callback: { name: 'SettingCollection', perameter: 'Preferences', active: true } },
          { name: 'Parameter Defaults', icon: 'dataset', callback: { name: 'SettingCollection', perameter: 'Parameter Defaults', active: true } },
          { name: 'Extent/Amend', icon: 'extension', callback: { name: 'SettingCollection', perameter: 'Parameter Defaults', active: true } },
          { name: 'Contract Retentions', icon: 'account_tree', callback: { name: 'SettingCollection', perameter: 'Contract Retensions', active: true } },
        ], callback: ''
      },

      {
        // name: 'Openings', icon: 'stairs',
        // displaysubmenu: [], callback: ''
        name: 'Opening Balance Sheet', icon: 'balance', displaysubmenu: [], callback: { name: 'EnterOpening', perameter: '', active: 'true' }
      },
      {
        name: 'What If', icon: 'help',
        displaysubmenu: [
          { name: 'Show What If', icon: 'add_chart', callback: { name: 'showWhatIf', perameter: '', active: 'true' } },
          { name: 'Save What If As Budget', icon: 'add_chart', callback: { name: 'SaveWhatIfAsBudgetForecastEvent', perameter: 'SaveWhatIfAsBudget', active: 'true' } },
          { name: 'Save As a New Forecast', icon: 'add_chart', callback: { name: 'SaveWhatIfAsForecast', perameter: 'SaveWhatIf', active: 'true' } },
          { name: 'Reset as Original', icon: 'add_chart', callback: { name: 'resetWhatIf', perameter: '', active: 'true' } },
        ], callback: ''
      },
      {
        name: 'Actuals', icon: 'group_work',
        displaysubmenu: [
          { name: 'Start Actuals', icon: 'start', callback: { name: 'StartActual', perameter: '', active: this.IsCanActualStartActive } },
          { name: 'Enter Actuals', icon: 'login', callback: { name: 'openEnterActual', perameter: '', active: this.IsCanActualEnterActive } },
          { name: 'Reset Actuals', icon: 'restart_alt', callback: { name: 'resetActuals', perameter: '', active: this.IsCanActualEnterActive } },
        ], callback: ''
      },
      this.IntegrationType(),
      {
        name: 'Record Import & Export', icon: 'import_export',
        displaysubmenu: [
          { name: 'Record List Import', icon: 'file_download', callback: { name: 'ImportRecordList', perameter: localStorage.getItem('ForecastId'), active: 'true' } },
          { name: 'Record Details Import', icon: 'downloading', callback: { name: 'ImportRecordDetails', perameter: localStorage.getItem('ForecastId'), active: 'true' } },
          { name: 'Record List Export', icon: 'view_list', callback: { name: 'ExportRecordList', perameter: localStorage.getItem('ForecastId'), active: 'true' } },
          { name: 'Record Details Export', icon: 'publish', callback: { name: 'ExportRecordDetails', perameter: localStorage.getItem('ForecastId'), active: 'true' } },
          //{ name: 'Record Data Export', icon: 'analytics', callback: { name: 'ExportRecordData', perameter: localStorage.getItem('ForecastId'), active: 'true' } },
        ], callback: ''
      },

      {
        name: 'Utility', icon: 'blur_circular',
        displaysubmenu: [
          { name: 'Budget Records Recalc', icon: 'add_chart', callback: { name: 'BudgetRecordCalculation', perameter: '', active: 'true' } },
          { name: 'System Records Recalc', icon: 'system_update_alt', callback: { name: 'SystemRecordCalculation', perameter: '', active: 'true' } },
        ], callback: ''
      },


      {
        name: 'Consolidation', icon: 'merge',
        displaysubmenu: [
          { name: 'Recalc Consolidation', icon: 'add_chart', callback: { name: 'RecalcConsolidaiton', perameter: '', active: this.isConsolidation } },
          { name: 'Goodwill', icon: 'handshake', callback: { name: 'GoodwillSelection', perameter: '', active: this.isConsolidation } },
          { name: 'Eliminations', icon: 'playlist_remove', callback: { name: 'EliminationSelection', perameter: '', active: this.isConsolidation } },
          { name: 'Consolidation Settings', icon: 'settings_applications', callback: { name: 'ConsolidationSettings', perameter: '', active: this.isConsolidation } },
        ], callback: ''
      },
      // {
      //   name: 'Integration Setting', icon: 'integration_instructions',
      //   displaysubmenu: [
      //     { name: 'Setting', icon:'add_chart', callback: { name: 'IntegrationSetting', perameter: '', active: 'true' } },
      //     { name: 'Import Integration', icon:'system_update_alt', callback: { name: 'ImportIntegration', perameter: '', active: 'true' } },
      //   ], callback: ''
      // },

    ]
  }
  IntegrationType() {
    let isXeroIntegartion = localStorage.getItem("Integration");
    console.log(isXeroIntegartion);
    let jsonArray = {
      // name: 'Integration Setting', icon: 'integration_instructions',
      // displaysubmenu: [
      //   { name: 'Setting', icon: 'add_chart', callback: { name: 'IntegrationSetting', perameter: '', active: 'true' } },
      // ], callback: { name: 'IntegrationSetting', perameter: '', active: 'true' }
      name: 'IntegrationSetting', icon: 'add_chart', displaysubmenu: [], callback: { name: 'IntegrationSetting', perameter: '', active: 'true' }
    }
    if (isXeroIntegartion == "Xero") {
      return jsonArray;
    } else {
      return jsonArray = {
        name: 'Integration Setting', icon: 'integration_instructions', displaysubmenu: [], callback: { name: 'IntegrationSetting', perameter: '', active: 'true' }
      }
    }
  }
  mouseOverEvent() {
    this.showDrawer();
  }
  // showWhatIf(){

  // }
  showWhatIf($event) {
    this.menuServices.setWhatIfValue(this.activeId, $event.target.checked).subscribe(
      ((response: any) => {
        this.showWhatIfbool = response;
        window.location.reload();
      })
    )
  }
  SaveWhatIfAsBudgetForecastEvent(SaveWhatIfAsBudget) {
    this.SpinnerService.show();
    this.menuServices.SaveWhatIfAsAsBudget(this.activeId).subscribe(
      ((response: any) => {
        this.SpinnerService.hide();
        SaveWhatIfAsBudget.close();
        window.location.reload();
      })
    )
  }
  SaveWhatIfAsForecast() {
    // this.menuServices.SetWhatIfAsNewForecast(this.activeId).subscribe(
    //   ((response: any) => {
    //     window.location.reload();
    //   })
    // )
  }

  SaveWhatIfAsForecastEvent(SaveWhatIf) {
    this.SpinnerService.show();
    this.menuServices.SetWhatIfAsNewForecast(this.activeId).subscribe(
      ((response: any) => {
        this.SpinnerService.hide();
        SaveWhatIf.close();
        window.location.reload();
      })
    )
  }

  resetWhatIf() {
    this.SpinnerService.show();
    this.menuServices.ResetWhatIf(this.activeId).subscribe(
      ((response: any) => {
        this.SpinnerService.hide();
        window.location.reload();
      })
    )
  }
  showDrawer() {
    this.showDrawerClick = true;
    this.hideDrawerClick = false;
    this.displaySubmenustyle = 'block';
  }
  hideDrawer() {
    this.hideDrawerClick = true;
    this.showDrawerClick = false;
    this.displaySubmenustyle = '';
  }
  ngOnInit() {

    this.NavitemSubmenu = [];
    this.showWhatIfbool = false;
    this.renderSidebarmenu();
    this.menuServices.showWhatIfValue(this.activeId).subscribe(
      ((response: any) => {
        localStorage.setItem('showWhatIfValue', response);
        this.showWhatIfbool = response;
      })
    )
  }
  ngOnChange() {
    this.showWhatIfbool = false;
    this.renderSidebarmenu();
    if (localStorage.getItem('showWhatIfValue') != '') {
      this.showWhatIfbool = (localStorage.getItem('showWhatIfValue') === 'true');
    }
    else {
      this.menuServices.showWhatIfValue(this.activeId).subscribe(
        ((response: any) => {
          localStorage.setItem('showWhatIfValue', response);
          this.showWhatIfbool = response;
        })
      )
    }
  }

  EnterOpening() {
    this._router.navigateByUrl('/EnterOpening')
  }

  IntegrationSetting() {
    console.log("Testing Data");
    this._router.navigateByUrl('/integration-setting');
  }
  ImportIntegration() {
    this._router.navigateByUrl('/integration-import');
  }

  BudgetRecordCalculation(ForecastnodeId, Item) {
    this.activeId = localStorage.getItem('ForecastId');
    this.SpinnerService.show();
    this.menuServices.BudgetRecordRecalu(this.activeId).subscribe(
      (response => {
        try {
          this.SpinnerService.hide();
        } catch (error) {
          this.SpinnerService.hide();
          return error;
        }
      })
    )
  }
  SystemRecordCalculation(ForecastnodeId, Item) {
    this.SpinnerService.show();
    this.menuServices.SystemRecordRecalu(this.activeId).subscribe(
      (response => {
        try {
          this.SpinnerService.hide()
        } catch (error) {
          this.SpinnerService.hide();
          return error;
        }
      })
    )

  }
  NormalSystemRecordCalculation(ForecastnodeId) {
    this.SpinnerService.show();
    this.menuServices.NormalSystemRecordRecalu(this.activeId).subscribe(
      (response => {
        try {
          this.SpinnerService.hide();
          localStorage.setItem("IsRecordsModified", "0");
          this._router.navigateByUrl('/report');
        } catch (error) {
          this.SpinnerService.hide();
          return error;
        }
      })
    )

  }
  ImportRecordList(ForecastnodeId, Item) {
    document.getElementById('RecordlistImport').click();
  }

  ImportRecordDetails(ForecastnodeId, Item) {
    document.getElementById('RecordDetailsImport').click();
  }

  RecordlistImport($event: any, Item) {
    let ForecastnodeId = localStorage.getItem('ForecastId')
    var dataFile = $event.target.files[0];
    if (dataFile.type != 'text/csv') {
      alert("Upload only .CSV file");
      return;
    }
    const target: DataTransfer = <DataTransfer>($event.target);
    if (target.files.length !== 1) {
      return;
    }
    const reader: FileReader = new FileReader();
    reader.readAsText(target.files[0]);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      var csv_string = binarystr.split("\t");
      let UpdateDetails = {
        ClipBoard: binarystr
      }
      this.SpinnerService.show();
      this.menuServices.ImportRecordList(ForecastnodeId, UpdateDetails).subscribe(
        (response: any) => {
          try {
            this.SpinnerService.hide();
            window.location.reload();
          } catch (error) {
            this.SpinnerService.hide();
            window.location.reload();
          }

        })
    }
  }
  RecordDetailsImport($event: any, Item) {
    let ForecastnodeId = localStorage.getItem('ForecastId')
    var dataFile = $event.target.files[0];
    if (dataFile.type != 'text/csv') {
      alert("Upload only .CSV file");
      return;
    }

    const target: DataTransfer = <DataTransfer>($event.target);
    if (target.files.length !== 1) {
      return;
    }
    const reader: FileReader = new FileReader();
    reader.readAsText(target.files[0]);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      var csv_string = binarystr.split("\t");
      let UpdateDetails = {
        ClipBoard: binarystr
      }
      this.SpinnerService.show();
      this.menuServices.ImportRecordDetails(ForecastnodeId, UpdateDetails).subscribe(
        (response: any) => {
          try {
            this.SpinnerService.hide();
            window.location.reload();
          } catch (error) {
            this.SpinnerService.hide();
            window.location.reload();
          }

        })
    }
  }
  ExportRecordList(ForecastnodeId, Item) {
    this.SpinnerService.show();
    let separator = ','
    this.menuServices.ExportRecordList(ForecastnodeId).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        let clipboardstring = response;
        var link = document.createElement('a');
        var csv = [];
        var rows = clipboardstring.split("\n");
        var csv = [];
        if (rows != null) {
          for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].split("\t");
            for (var j = 0; j < cols.length; j++) {
              if ((cols[j]) != null) {
                var data = cols[j]
                row.push(data);
              }
            }
            csv.push(row.join(separator));
          }
          var csv_string = csv.join('\n');
          var filename = 'export_RecordList' + new Date().toLocaleDateString() + '.csv';
          link.style.display = 'none';
          link.setAttribute("a", '');
          link.setAttribute('target', '_blank');
          link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv_string));
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    )
  }
  ExportRecordDetails(forecastNodeId, Item) {
    this.SpinnerService.show();
    let separator = ','
    this.menuServices.ExportRecordDetails(forecastNodeId).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        let clipboardstring = response;
        var link = document.createElement('a');
        var csv = [];
        var rows = clipboardstring.split("\n");
        var csv = [];
        if (rows != null) {
          for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].split("\t");
            for (var j = 0; j < cols.length; j++) {
              if ((cols[j]) != null) {
                var data = cols[j]
                row.push(data);
              }
            }
            csv.push(row.join(separator));
          }
          var csv_string = csv.join('\n');
          var filename = 'export_RecordDetails' + new Date().toLocaleDateString() + '.csv';
          link.style.display = 'none';
          link.setAttribute("a", '');
          link.setAttribute('target', '_blank');
          link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv_string));
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    )
  }

  openReport() {
    //let isrecordModeiied = localStorage.getItem("IsRecordsModified");
    //if (isrecordModeiied === "1") {
    //  let forecastNodeId = localStorage.getItem("ForecastId");
    //  this.NormalSystemRecordCalculation(forecastNodeId);

    //}
    //else {
    this._router.navigateByUrl('/report');
    //}

  }
  openEnterActual() {
    this._router.navigateByUrl('/EnterActual');
  }
  forecast() {
    this._router.navigateByUrl('/forecast');
  }

  StartActual() {
    let forecastNodeId = localStorage.getItem("ForecastId");
    if (forecastNodeId != null) {
      this.SpinnerService.show();
      this.enterActualsService.startActual(forecastNodeId).subscribe
        ((response) => {
          localStorage.setItem('IsCanEnterActual', 'true');
          localStorage.setItem('IsCanStartActual', 'false');
          localStorage.setItem('IsActualStarted', 'true');
          this.SpinnerService.hide();
          this._router.navigateByUrl('/EnterActual');
          return response;
        })
    }
  }

  ResetActualsResponseNo() {
    localStorage.setItem('IsCanEnterActual', 'false');
    localStorage.setItem('IsCanStartActual', 'true');
    localStorage.setItem('IsActualStarted', 'false');
    let forecastNodeId = localStorage.getItem("ForecastId");
    if (forecastNodeId != null) {
      this.SpinnerService.show();
      this.enterActualsService.ResetActual(forecastNodeId, false).subscribe
        ((response) => {
          this.SpinnerService.hide();
          if (window.location.pathname != "/forecast") {
            this._router.navigateByUrl('/forecast');
          } else {
            window.location.reload();
          }

          return response;
        })
    }
  }
  ResetActualsResponseWithActuals() {
    localStorage.setItem('IsCanEnterActual', 'false');
    localStorage.setItem('IsCanStartActual', 'true');
    localStorage.setItem('IsActualStarted', 'false');
    let forecastNodeId = localStorage.getItem("ForecastId");
    if (forecastNodeId != null) {
      this.SpinnerService.show();
      this.enterActualsService.ResetActual(forecastNodeId, true).subscribe
        ((response) => {
          this.SpinnerService.hide();
          if (window.location.pathname != "/forecast") {
            this._router.navigateByUrl('/forecast');
          } else {
            window.location.reload();
          }
          return response;
        })
    }
  }
  resetActuals() {
    document.getElementById("ResetActualsResponse").click();
  }



  OpeningTradeDebotrAndCreditor() {
    var activeForecastEnterOpeingdebtorsNode = localStorage.getItem('EnterOpeningDebotrs' + this.activeId);
    var activeForecastEnterOpeingcreditorsNode = localStorage.getItem('EnterOpeningCreditors' + this.activeId);
    if (activeForecastEnterOpeingdebtorsNode == null || activeForecastEnterOpeingdebtorsNode == '') {
      this.treeview.GetEnterOpeingDebtorsNodeId(this.activeId).subscribe
        ((response) => {
          if (response != null) {
            var SelectedNode: any = response;
            localStorage.setItem('EnterOpeningDebotrs' + this.activeId, SelectedNode);
            this.EnterOpeningDebtorsNode = SelectedNode;

          }
        })
    }
    if (activeForecastEnterOpeingcreditorsNode == null || activeForecastEnterOpeingcreditorsNode == '') {
      this.treeview.GetEnterOpeingCreditorsNodeId(this.activeId).subscribe
        ((response) => {
          if (response != null) {
            var SelectedNode: any = response;
            localStorage.setItem('EnterOpeningCreditors' + this.activeId, SelectedNode);
            this.EnterOpenigCreditorNode = SelectedNode;
          }
        })
    }

  }

  OpeningTradeDebotrSelectedNode($event, Item) {
    document.getElementById($event).click();
  }
  OpeningTradeCreditorSelectedNode($event, Item) {
    document.getElementById($event).click();
  }
}
