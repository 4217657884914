import { Component, OnInit } from '@angular/core';
import { CurrencyServiceService } from '../../../../service/SettingServices/CurrencyService/currency-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
@Component({
  selector: 'app-currency-setting',
  templateUrl: './currency-setting.component.html',
  styleUrls: ['./currency-setting.component.scss']
})
export class CurrencySettingComponent implements OnInit {

  CurrencyCollection: any = [];
  CurrencyValidColumn: any = [];
  CurrencyValueList: any = [];
  PeriodTabViewCollection: any = [];
  DisplayMonthCollection: any = [];
  ActivetabView: any = 0;
  Activetabmenu: any = 0;
  PeriodStartIndex: any = 0;
  PeriodEndIndex: any = 11;
  ActiveRecord = '0';
  defaultIndex: any;
  constructor(private CurrencyServiceServices: CurrencyServiceService,
    private _router: Router,
    private SpinnerService: NgxSpinnerService) { }

  async ngOnInit() {
    this.CurrencyCollection = [];
    this.CurrencyValidColumn = [];
    this.CurrencyValueList = [];
    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.getPeriodCollection(ForecastNodeId);
    this.SpinnerService.show();
    this.CurrencyServiceServices.getCurrencyCollections(ForecastNodeId).subscribe(
      (async (response: any) => {
        this.SpinnerService.hide();
        this.CurrencyCollection = response.getCurrencyTableCollection;
        this.defaultIndex = this.CurrencyCollection[0].defaultIndex;
        this.ActiveRecord = this.CurrencyCollection[this.defaultIndex].selectedIndex.toString();
        this.CurrencyValueList = this.CurrencyCollection[this.defaultIndex].getBudgetData;
        this.CurrencyValueList.decimalValuesArray.forEach((element, index) => {
          this.CurrencyValueList.decimalValuesArray[index] = this.transform(element.toString());
        });
      })
    )
  }

  getPeriodCollection(ForecastNodeId) {
    this.PeriodTabViewCollection = [];
    this.DisplayMonthCollection = [];
    this.CurrencyServiceServices.getPeriodTabViewCollection(ForecastNodeId).subscribe(
      (response => {
        let PeriodViewResponse: any = response;
        this.PeriodTabViewCollection = PeriodViewResponse.getPeriodTabView;
        this.DisplayMonthCollection = PeriodViewResponse.getMonthDisplay;
      })
    )
  }

  /** 
   * 
   * @param result 
   * @param index 
   */
  ChangePeriodType(result, index) {
    this.Activetabmenu = parseInt(index);
    this.PeriodStartIndex = parseInt(index) * 12;
    this.PeriodEndIndex = parseInt(this.PeriodStartIndex + 11);
  }

  async ngOnChanges() {
    this.CurrencyCollection = [];
    this.CurrencyValueList = [];
    this.CurrencyValidColumn = [];
    let ForecastNodeId = localStorage.getItem('ForecastId');
    if (this.PeriodTabViewCollection.length > 0) {
      this.getPeriodCollection(ForecastNodeId);
    }
    this.SpinnerService.show();
    this.CurrencyServiceServices.getCurrencyCollections(ForecastNodeId).subscribe(
      (async (response: any) => {
        this.SpinnerService.hide();
        this.CurrencyCollection = response.getCurrencyTableCollection;
        this.defaultIndex = this.CurrencyCollection[this.ActiveRecord].defaultIndex.toString();
        this.ActiveRecord = this.CurrencyCollection[this.ActiveRecord].selectedIndex.toString();
        this.CurrencyValueList = this.CurrencyCollection[parseInt(this.ActiveRecord)].getBudgetData;
        this.CurrencyValueList.decimalValuesArray.forEach((element, index) => {
          this.CurrencyValueList.decimalValuesArray[index] = this.transform(element.toString());
        });
      })
    )
  }
  /**
   * 
   */
  async AddCurrency() {
    this.SpinnerService.show();
    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.CurrencyServiceServices.AddCurrency(ForecastNodeId).subscribe(
      (async response => {
        this.SpinnerService.hide();
        this.ngOnChanges();
      })
    )
  }
  /**
   * 
   * @param index 
   */
  changeValueList(index, CurrencyCollection) {
    this.ActiveRecord = index.toString();
    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.CurrencyValueList = this.CurrencyCollection[parseInt(this.ActiveRecord)].getBudgetData;
    this.CurrencyValueList.decimalValuesArray.forEach((element, index) => {
      this.CurrencyValueList.decimalValuesArray[index] = this.transform(element.toString());
    });
  }

  isNumber(evt) {
    if ((evt.keyCode < 48 || evt.keyCode > 57) && evt.keyCode != 8) {
      if (evt.srcElement.value.length > 0 && evt.keyCode == 46) {
        return true;
      }
      if (evt.srcElement.value.length == 0 && evt.keyCode != 45) {
        return true;
      } else {
        return false;
      }
    }
  }
  twoDecimalAfterDots(evt) {
    var t = evt.target.value;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = evt.target.value.length;
        let dotsvalidation = indexOfValue;
        var afterDot = evt.target.value.substr(evt.target.value.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {
        //console.log(true);
      }
    }
  }

  ValidateValue($event) {
    var totalgetelemtbyclassnamearray: any = document.getElementsByClassName($event.target.className);
    let withoutcommmavalue = $event.target.value.toString().replace(/\s/g, "");
    if ($event.target.value != "") {
      let data = this.twoDecimalAfterDots($event);
      if (data == false) {
        withoutcommmavalue = $event.target.value.toString().substring(0, $event.target.value.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = $event.target.value.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      if (validatebynumberFormate == '.') {
        $event.target.value = "0.";
      } else {
        $event.target.value = validatebynumberFormate;
      }
    }
  }

  DeleteCurrency() {
    this.SpinnerService.show();
    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.CurrencyServiceServices.DeleteCurrency(ForecastNodeId, parseInt(this.ActiveRecord)).subscribe(
      (async response => {
        this.SpinnerService.hide();
        this.ngOnInit();
      })
    )
  }
  check(expr) {
    const holder = []
    const openBrackets = ['(']
    const closedBrackets = [')']
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        return true;
      } else {
        return false;
      }
    }
  }

  DafaultCurency() {
    this.SpinnerService.show();
    let ForecastNodeId = localStorage.getItem('ForecastId');
    this.CurrencyServiceServices.DefaultCurrency(ForecastNodeId, parseInt(this.ActiveRecord)).subscribe(
      (async response => {
        this.SpinnerService.hide();
        this.ngOnChanges();

      })
    )
  }
  /**
   * 
   * @param NodeId 
   * @param $event 
   */
  SetAllCurrencyList(NodeId, $event) {
    if ($event.target.value == '') {
      //$event.target.value = 0;
      return;
    }
    let total = 0;
    let resultarray = [];
    var loopindexstart;
    let value = $event.target.value;
    for (loopindexstart = this.PeriodStartIndex; loopindexstart <= this.PeriodEndIndex; ++loopindexstart) {
      if (this.CurrencyValueList.currentActualsIndex >= loopindexstart) {
        if (this.check(this.CurrencyValueList.decimalValuesArray[loopindexstart].toString()) == true) {
          let data = this.CurrencyValueList.decimalValuesArray[loopindexstart].toString().replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
          this.CurrencyValueList.decimalValuesArray[loopindexstart] = this.converttoDisplayValue(data);
          total += parseFloat(data);
        } else {
          let data = this.CurrencyValueList.decimalValuesArray[loopindexstart].toString().replaceAll(',', '');
          this.CurrencyValueList.decimalValuesArray[loopindexstart] = this.converttoDisplayValue(data);
          total += parseFloat(data);
        }
      } else {
        if (this.check(value.toString()) == true) {
          let data = value.replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
          this.CurrencyValueList.decimalValuesArray[loopindexstart] = this.converttoDisplayValue(data);
          total += parseFloat(data);
        } else {
          let data = value.replaceAll(',', '');
          this.CurrencyValueList.decimalValuesArray[loopindexstart] = this.converttoDisplayValue(data);
          total += parseFloat(data);
        }
      }

    }
    var inputValue: any = (<HTMLInputElement>document.getElementById("total-" + this.CurrencyValueList.nodeId));
    if (inputValue != null) {
      inputValue.value = this.converttoDisplayValue(total.toString());
      this.CurrencyValueList["total"] = this.transform(inputValue.value);
    }
    this.CurrencyValueList["Edited"] = true;
    $event.target.value = '';
  }

  CalculateSave(NodeId, Stringvalues) {

    let ForecastId = localStorage.getItem("ForecastId");

    let save = {
      ForecastNodeId: ForecastId,
      SaveCurrencyData: []
    }

    this.CurrencyCollection.forEach((element) => {
      let SaveCurrencyCollection = {
        Name: String,
        Symbol: String,
        Tla: String,
        Edited: Boolean,
        NodeId: Number,
        values: [],
      }
      if (element.getBudgetData.Edited != undefined && element.getBudgetData.Edited == true) {
        SaveCurrencyCollection.Name = element.name;
        SaveCurrencyCollection.Symbol = element.symbol;
        SaveCurrencyCollection.Tla = element.tla;
        SaveCurrencyCollection.Edited = element.getBudgetData.Edited;
        SaveCurrencyCollection.NodeId = element.getBudgetData.nodeId;
        element["values"] = [];
        for (let index = 0; index < element.getBudgetData.decimalValuesArray.length; index++) {
          if (this.check(element.getBudgetData.decimalValuesArray[index].toString()) == true) {
            let data = element.getBudgetData.decimalValuesArray[index].toString().replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
            element["values"].push(parseFloat(data.toString()).toFixed(0));
          } else {
            let data = element.getBudgetData.decimalValuesArray[index].toString().replaceAll(',', '');
            element["values"].push(parseFloat(data.toString()).toFixed(2));
          }

        }
        SaveCurrencyCollection.values = element.values;
        save.SaveCurrencyData.push(SaveCurrencyCollection);
      }
    });
    if (save.SaveCurrencyData.length > 0) {
      this.SpinnerService.show();
      this.CurrencyServiceServices.saveCurrencyCollection(save).subscribe(
        (response => {
          this.CurrencyCollection = [];
          this.CurrencyValueList = [];
          this.CurrencyValidColumn = [];
          let ForecastNodeId = localStorage.getItem('ForecastId');
          if (this.PeriodTabViewCollection.length > 0) {
            this.getPeriodCollection(ForecastNodeId);
          }
          this.SpinnerService.show();
          this.CurrencyServiceServices.getCurrencyCollections(ForecastNodeId).subscribe(
            (async (response: any) => {
              this.SpinnerService.hide();
              localStorage.setItem("CurrencyCalculationneeded","true");
              this.CurrencyCollection = response.getCurrencyTableCollection;
              this.defaultIndex = this.CurrencyCollection[this.ActiveRecord].defaultIndex.toString();
              this.ActiveRecord = this.CurrencyCollection[this.ActiveRecord].selectedIndex.toString();
              this.CurrencyValueList = this.CurrencyCollection[this.ActiveRecord].getBudgetData;
              this.CurrencyValueList.decimalValuesArray.forEach((element, index) => {
                this.CurrencyValueList.decimalValuesArray[index] = this.transform(element.toString());
              });
            })
          )
        })
      )
    }
    if (Stringvalues == 'close') {
      this._router.navigateByUrl("/forecast");
    }
  }
  MonthCurrencyList(NodeId, Index, $event, budgetdata) {

    if ($event.target.value == '') {
      return;
    }
    let total = 0;
    var loopindexstart;
    let value = $event.target.value;
    if (this.check(value.toString()) == true) {
      let data = value.replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
      value = this.converttoDisplayValue(data);
    } else {
      let data = value.replaceAll(',', '');
      value = this.converttoDisplayValue(data);
    }
    for (loopindexstart = this.PeriodStartIndex; loopindexstart <= this.PeriodEndIndex; ++loopindexstart) {
      if (this.check(this.CurrencyValueList.decimalValuesArray[loopindexstart].toString()) == true) {
        let data = this.CurrencyValueList.decimalValuesArray[loopindexstart].toString().replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
        total += parseFloat(data);
      } else {
        let data = this.CurrencyValueList.decimalValuesArray[loopindexstart].toString().replaceAll(',', '');
        total += parseFloat(data);
      }
    }

    this.CurrencyValueList["Edited"] = true;

  }

  valuelistregrex($event) {
    var inputValue: any = (<HTMLInputElement>document.getElementById($event.target.id));
    inputValue.value = $event.target.value.replace(/\(/g, '-').replace(/\)/g, '');
  }

  transform(value) {
    if (value == null) {
      return 0;
    }
    if (Math.sign(value) == -1) {
      let data = Math.abs(value);
      let convertNumberFormat = data.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return '(' + convertNumberFormat + ')';
    } else {
      let convertNumberFormat = value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return convertNumberFormat;
    }
  }
  converttoDisplayValue(decimalValue) {
    let withoutcommmavalue = decimalValue.toString().replace(/\s/g, "");
    if (decimalValue != "") {
      let data = this.convertDecimal(decimalValue);
      if (data == false) {
        withoutcommmavalue = decimalValue.toString().substring(0, decimalValue.length - 1).replace(",", '');
      } else {
        withoutcommmavalue = decimalValue.toString().replace(",", '');
      }
      let value = withoutcommmavalue.toString().replace(/,/g, '');
      let validatebynumberFormate = decimalValue.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return validatebynumberFormate;
    }
    else {
      return decimalValue;
    }
  }
  convertDecimal(decimalValue) {
    var t = decimalValue;
    if (t.indexOf(".") == undefined) {
      return false;
    } else {
      let indexOfValue = t.indexOf(".");
      if (indexOfValue != -1) {
        let getallnumberLength = decimalValue.length;
        let dotsvalidation = indexOfValue;
        var afterDot = decimalValue.substr(decimalValue.indexOf('.') + 1);
        if (afterDot.length <= 2) {
          return true;
        } else {
          return false
        }
      } else {

      }
    }
  }
  /**
   * 
   * @param Index 
   * @param ValuesList 
   * @param $event 
   */
  CurrencySettingNameChange(Index, ValuesList, $event) {
    if ($event.target.value == '') {
      return;
    }
    ValuesList.name = $event.target.value;
    ValuesList.getBudgetData["Edited"] = true;

  }
  /**
   * 
   * @param Index 
   * @param ValuesList 
   * @param $event 
   */
  CurrencySettingSymbolChange(Index, ValuesList, $event) {
    let Value = $event.target.value;
    if (Value != '') {
      ValuesList.symbol = $event.target.value;
      ValuesList.getBudgetData["Edited"] = true;
    }

  }
  /**
   * 
   * @param Index 
   * @param ValuesList 
   * @param $event 
   */
  CurrencySettingTlaChange(Index, ValuesList, $event) {
    let Value = $event.target.value;
    if (Value != '') {
      ValuesList.tla = $event.target.value;
      ValuesList.getBudgetData["Edited"] = true;
    }

  }


  open($event, id) {
    console.log($event, id);
    var result = document.getElementById(id);
    var input = document.getElementsByClassName('.rightclick-' + id);
    document.getElementById('hidecopy-' + id).click();
    $('.rightclick-' + id).removeClass('hide');
    $('.rightclick-' + id).addClass('show');
  }

  async convertoExcel(id, budgetdata, $event) {
    const text = await navigator.clipboard.readText();
    var result = null;
    if (text.indexOf(',') > -1) {
      result = text.split(",");
    } else {
      result = text.split(/\r?\n/);
    }

    $('.rightclick-' + id).addClass('hide');
    $('.rightclick-' + id).removeClass('show');
    if (result.length > 0) {
      result = result[0].split("\t");
    }

    let total = 0;
    let resultarray = [];
    var loopindexstart;
    for (loopindexstart = this.PeriodStartIndex; loopindexstart <= this.PeriodEndIndex; ++loopindexstart) {
      if (result[loopindexstart] != undefined) {
        let latestvalue: any = result[loopindexstart].toString();
        var decimalvalue = latestvalue.replaceAll(',', '');
      }
      if (this.CurrencyValueList.currentActualsIndex >= loopindexstart) {
        if (this.check(this.CurrencyValueList.decimalValuesArray[loopindexstart].toString()) == true) {
          let data = this.CurrencyValueList.decimalValuesArray[loopindexstart].toString().replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
          this.CurrencyValueList.decimalValuesArray[loopindexstart] = this.transform(this.converttoDisplayValue(data));
          total += parseFloat(data);
        } else {
          let data = this.CurrencyValueList.decimalValuesArray[loopindexstart].toString().replaceAll(',', '');
          this.CurrencyValueList.decimalValuesArray[loopindexstart] = this.transform(this.converttoDisplayValue(data));
          total += parseFloat(data);
        }
      } else {
        if (this.check(decimalvalue.toString()) == true) {
          let data = decimalvalue.replace(/\(/g, '-').replace(/\)/g, '').replaceAll(',', '');
          this.CurrencyValueList.decimalValuesArray[loopindexstart] = this.transform(this.converttoDisplayValue(data));
          total += parseFloat(data);
        } else {
          let data = decimalvalue.replaceAll(',', '');
          this.CurrencyValueList.decimalValuesArray[loopindexstart] = this.transform(this.converttoDisplayValue(data));
          total += parseFloat(data);
        }
      }

    }
    var inputValue: any = (<HTMLInputElement>document.getElementById("total-" + this.CurrencyValueList.nodeId));
    if (inputValue != null) {
      inputValue.value = this.converttoDisplayValue(total.toString());
      this.CurrencyValueList["total"] = this.transform(inputValue.value);
    }
    this.CurrencyValueList["Edited"] = true;
  }

}
