<div>
    <div class="">
        <div class="defualtdisplay">
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Interest Type</label>
                <div class="col-sm-7">
                    <select name="Intersttype" class="selectloantakencss form-control"
                        (change)="changeInterestType($event.target.value, CalculatedloanMadeRecord)">
                        <option
                            *ngFor="let InterstResult of CalculatedloanMadeRecord.interestTypeCollection ; let i = index"
                            [selected]="InterstResult.nodeType === CalculatedloanMadeRecord.interestTypes"
                            value="{{InterstResult.nodeType}}">{{InterstResult.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Payment Frequcncy</label>
                <div class="col-sm-7">
                    <select name="paymentFrequcncy" class="selectloantakencss form-control"
                        (change)="changepaymentFrequcncy($event.target.value, CalculatedloanMadeRecord)">
                        <option
                            *ngFor="let paymentFrequencieResult of CalculatedloanMadeRecord.paymentFrequencieCollection"
                            value="{{paymentFrequencieResult.numberFormatPrefix}}"
                            [selected]="paymentFrequencieResult.numberFormatPrefix === CalculatedloanMadeRecord.paymentFrequencies.numberFormatPrefix">
                            {{paymentFrequencieResult.numberFormatPrefix}}</option>
                    </select>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Payment Timing</label>
                <div class="col-sm-7">
                    <select name="paymentTiming" class="selectloantakencss form-control"
                        (change)="changepaymentTiming($event.target.value, CalculatedloanMadeRecord)">
                        <option *ngFor="let paymentTimingsResult of CalculatedloanMadeRecord.paymentTimingsCollection"
                            [selected]="paymentTimingsResult.nodeType === CalculatedloanMadeRecord.paymentTimings"
                            value="{{paymentTimingsResult.nodeType}}">{{paymentTimingsResult.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <div class="col-sm-6">
                    <input [checked]="CalculatedloanMadeRecord.isNewLoan"
                        (change)="IsnewLoanchangeEvent($event,CalculatedloanMadeRecord)" type="checkbox"
                        name="IsNewLoan" class="col-sm-2 " style="height: 20px;margin: 0px">
                    <label class="col-sm-9" style="margin: 0px;">New loan</label>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord loantakenrecord-periodStart"
                [ngClass]="CalculatedloanMadeRecord.isNewLoan == false ? 'hidden' : ''">
                <label class="col-sm-5 ">Period Start</label>
                <div class="col-sm-7">
                    <select name="pariodstart" class="selectloantakencss form-control"
                        (change)="changeloanmadePeriod($event.target.value, CalculatedloanMadeRecord)">
                        <option *ngFor="let result of CalculatedloanMadeRecord.forecastperiod ; let i = index"
                            [selected]="CalculatedloanMadeRecord.selectedIndex === i" [value]="i">{{result.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Term (Months)</label>
                <div class="col-sm-7">
                    <input type="text" class=" col-sm-11 form-control"
                        (blur)="changeTerm($event.target.value, CalculatedloanMadeRecord)"
                        value="{{CalculatedloanMadeRecord.term | number }}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Loan Balance</label>
                <div class="col-sm-7">
                    <input type="text" class=" col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)"
                        (blur)="changeLoanBalance($event.target.value, CalculatedloanMadeRecord)"
                        value="{{CalculatedloanMadeRecord.loanPrincipal | number}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Interest Rate</label>
                <div class="col-sm-7">
                    <input type="text" class=" col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)"
                        (blur)="changeInterestRate($event.target.value, CalculatedloanMadeRecord)"
                        value="{{CalculatedloanMadeRecord.interestRate | number}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <label class="col-sm-5 ">Payment</label>
                <div class="col-sm-7">
                    <input type="text" class=" col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)"
                        (blur)="changePayment($event.target.value, CalculatedloanMadeRecord)"
                        value="{{CalculatedloanMadeRecord.payment | number}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord"
                [ngClass]="CalculatedloanMadeRecord.showDeposit == true ? '' : 'hidden' ">
                <div class="col-sm-6">
                    <input [checked]="CalculatedloanMadeRecord.hasDepositPayment"
                        [disabled]="!CalculatedloanMadeRecord.canHaveDepositPayment" type="checkbox"
                        style="height: 20px;margin: 0px" (change)="OnchangeDeposit($event,CalculatedloanMadeRecord)"
                        class=" col-sm-2" />
                    <label class="col-sm-9 " style="margin: 0px;">Deposit</label>
                </div>
                <!-- <div class="col-sm-4"> 
                    <label class="col-sm-4 ">Deposit</label> 
                </div>   -->
                <div class="col-sm-6">
                    <input type="text" class=" col-sm-11 form-control enabledepositepayment"
                        (keypress)="isNumber($event)" *ngIf="CalculatedloanMadeRecord.hasDepositPayment"
                        (keyup)="ValidateValue($event)"
                        (blur)="changeDeposite($event.target.value, CalculatedloanMadeRecord)"
                        value="{{CalculatedloanMadeRecord.depositPayment | number}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord">
                <div class="col-sm-6">

                    <input type="checkbox" [checked]="CalculatedloanMadeRecord.hasBulletPayment"
                        [disabled]="!CalculatedloanMadeRecord.canHaveBulletPayment"
                        (change)="OnchangeBullet($event,CalculatedloanMadeRecord)" style="height: 20px;margin: 0px"
                        class=" col-sm-2" name="Isbullet" />
                    <label class="col-sm-9" style="margin: 0px;">Bullet</label>
                </div>
            </div>

            <div class="row valuelistdisplayrow loantakenrecord loantakenrecord-BulletPayment"
                [ngClass]="CalculatedloanMadeRecord.hasBulletPayment == false ? 'hidden' : ''">
                <label class="col-sm-5 ">Bullet Payment</label>
                <div class="col-sm-6">
                    <input type="text" (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                        class=" col-sm-11 form-control"
                        (blur)="changeBulletPayment($event.target.value, CalculatedloanMadeRecord)"
                        value="{{CalculatedloanMadeRecord.bulletPayment | number}}" />
                </div>
            </div>
            <div class="row valuelistdisplayrow loantakenrecord loantakenrecord-PaymentMonth"
                [ngClass]="CalculatedloanMadeRecord.hasBulletPayment == false ? 'hidden' : ''">
                <label class="col-sm-5 ">Payment(Months)</label>
                <div class="col-sm-7">
                    <input type="text" (keypress)="isNumber($event)" (keyup)="ValidateValue($event)"
                        class=" col-sm-11 form-control"
                        (blur)="changeBulletPaymentMonth($event.target.value, CalculatedloanMadeRecord)"
                        value="{{CalculatedloanMadeRecord.monthsBulletDelay | number}}" />
                </div>
            </div>

            <div class="row valuelistdisplayrow loantakenrecord">
                <div class="col-sm-6">

                    <input [checked]="CalculatedloanMadeRecord.hasBalloonPayment"
                        [disabled]="!CalculatedloanMadeRecord.canHaveBalloonPayment" type="checkbox"
                        (change)="HasBallooneEvent($event,CalculatedloanMadeRecord)" class=" col-sm-2"
                        style="height: 20px;margin: 0px" />
                    <label class="col-sm-9">Balloon</label>
                </div>
                <div class="col-sm-6 loantakenrecordballon">
                    <input type="text" class="col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)" *ngIf="CalculatedloanMadeRecord.hasBalloonPayment"
                        (blur)="changeBaloon($event.target.value, CalculatedloanMadeRecord)"
                        value="{{CalculatedloanMadeRecord.balloonPayment | number}}" />
                </div>
            </div>

            <div class="row valuelistdisplayrow loantakenrecord">
                <div class="col-sm-6">

                    <input [checked]="CalculatedloanMadeRecord.hasHolidayPeriod"
                        [disabled]="!CalculatedloanMadeRecord.canHaveHolidayPeriod"
                        (change)="hasHolidayPeriodEvent($event,CalculatedloanMadeRecord)" type="checkbox"
                        class=" col-sm-2" style="height: 20px;margin: 0px" />
                    <label class="col-sm-9">Holiday(Months)</label>
                </div>
                <div class="col-sm-6 loantakenrecordhollidayPeriodEvent">
                    <input type="text" class="col-sm-11 form-control" (keypress)="isNumber($event)"
                        (keyup)="ValidateValue($event)" *ngIf="CalculatedloanMadeRecord.hasHolidayPeriod"
                        (blur)="changeholidaymonthText($event.target.value, CalculatedloanMadeRecord)"
                        value="{{CalculatedloanMadeRecord.monthsHoliday | number}}" />
                </div>
            </div>

        </div>
    </div>
</div>