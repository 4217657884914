import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { from, Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Report } from '../model/report/report';
import { data } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  Reports: Report[] = [];
  constructor(private http: HttpClient) { }

  /**
   * Function For get report-details by forecast Id and Report type
   * @returns response
   */
  getReportdDetail(ForecastId, Reporttype): Observable<Report[]> {
    return this.http.get(environment.url + "/api/forecast/GetDefualtReport/" + ForecastId, { responseType: "json" })
      .pipe(map(
        (response: Report[]) => {
          return response;
        }
      ));
  }

  BudgetRecordRecalu(ForecastNodeId): Observable<any> {
    return this.http.get(environment.url + "/api/RecordCalculation/BudgetReCalculate/" + ForecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          // console.log(response);
          return response;
        }
      ))
  }

  getForecastBudgetReport(ForecastId): Observable<any> {
    return this.http.get(environment.url + "/api/forecast/ForecastAllReportBudget/" + ForecastId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  GetForecastBankAccounts(ForecastId): Observable<any> {
    return this.http.get(environment.url + "/api/forecast/GetForecastBankAccounts/" + ForecastId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          console.log("GetForecastBankAccounts service", response);
          return response;
        }
      ));
  }

  setForecastBudgetReport(ForecastNodeId, NodeId): Observable<any> {
    return this.http.put(environment.url + "/api/forecast/SetBudgetReports/" + ForecastNodeId + "/" + NodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }

  /**
   * Function for get reportdata by forecast ID and ReportType
   * @returns response
   */
  getReportData(ForecastId, Reporttype, GetFrequency, index, BudgetNodeId, BankNodeId): Observable<Report[]> {
    return this.http.get(environment.url + "/api/forecast/GetReportData/" + ForecastId + "/" + Reporttype + "/" + GetFrequency + "/" + index + "/" + BudgetNodeId + "/" + BankNodeId, { responseType: "json" })
      .pipe(map(
        (response: Report[]) => {
          return response;
        }
      ));
  }

  /**
   * getPeriod Collection
   */
  getPeriodCollection(ForecastId): Observable<Report[]> {
    return this.http.get(environment.url + "/api/PeriodTabView/PeriodViewCollections/" + ForecastId, { responseType: "json" })
      .pipe(map(
        (response: Report[]) => {
          return response;
        }
      ));
  }

  getVarianceMonthCollection(forecastNodeId): any {
    return this.http.get(environment.url + "/api/Actual/GetActualMonthDisplay/" + forecastNodeId, { responseType: "json" })
      .pipe(map(
        (response: any) => {
          return response;
        }
      ));
  }
}
