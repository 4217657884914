<app-header></app-header>
<div class="forcastlistingpage">
  <div class="box" style="padding: 0px;">
    <!-- <div class="clientName">
      <label style="padding: 10px;background: green;color: white;">Client Name : {{clientName}}</label>
    </div> -->
    <div class="col-sm-12" id="listgirdviewchangelayout" style="padding: 0px;">
      <div class="filtringwithiocn col">
        <div class="col-sm-3 sample-column" style="padding: 0px;">
          <igx-input-group type="search" style="background: #f4f6f6; width: 90% !important;">
            <input #input1 igxInput placeholder="Search" style="font-size: 16px; background: #f4f6f6;" />
            <igx-prefix>
              <igx-icon class="IconOkButtonColor">search</igx-icon>
            </igx-prefix>
            <igx-suffix *ngIf="input1.value.length > 0" (click)="input1.value = ''">
              <igx-icon>clear</igx-icon>
            </igx-suffix>
          </igx-input-group>
        </div>
        <div class="col-sm-3" style="margin: 0px">
          <div class="GridandListIcon">
            <div class="glyphicon glyphicon-th active" id="gridviewactivebutton" (click)="activegridview()"></div>
            <div class="glyphicon glyphicon-th-list" id="listviewactivebutton" (click)="activelistview()"></div>
          </div>
        </div>
      </div>
      <!-- list view Grid View start-->
      <div class="listview hidden col-sm-12" id="listview" style="height: 450px; padding: 0px;">
        <div class="col-sm-2 sidebarmenu " id="GridandListIcon" style="padding: 14px;">
          <igx-card style="background: white !important;padding: 10px;border-radius: 18px !important;">
            <div id="listingdata">
              <ul class="list-group">
                <li class="list-item"><button type="button" (click)="newforecast()"
                    class="btn btn-primary OkSaveButtonColor">New
                    Forecast</button></li>
                <li class="list-item"><button type="button" class="btn btn-info OkSaveButtonColor"
                    (click)="copyforecast()">Copy Forecast</button>
                </li>

                <li class="list-item"><button type="button" class="btn btn-info OkSaveButtonColor"
                    (click)="copyforecastNoValues()">Copy
                    Forecast No Values</button>
                </li>
                <li class="list-item"><button type="button" class="btn btn-secondary OkSaveButtonColor"
                    (click)="deleteForecastFromList()">Delete
                    Forecast</button></li>

                <li class="list-item"><button type="button" class="btn btn-info OkSaveButtonColor"
                    (click)="printOTR()">Print
                    OTR</button>
                </li>
                <li class="list-item"> <button type="button" class="btn btn-secondary OkSaveButtonColor"
                    (click)="openImportForecast()">Import Forecast
                  </button>
                  <!--document.getElementById('getFile').click() <input type='file' id="getFile" accept=".xml" style="display:none"
                    (change)="handleFileInput($event.target.files)"> -->
                </li>
                <li class="list-item"><button type="button" class="btn btn-info OkSaveButtonColor"
                    (click)="exportforecast()">Export Forecast</button>
                </li>

              </ul>
            </div>
          </igx-card>

        </div>
        <div class="forcast-listing-rightside col-sm-10" style="height: 500px;overflow: auto;">
          <igx-grid class="forecastGrid" [igxPreventDocumentScroll]="true" #grid1 [data]="forecastlist"
            [autoGenerate]="false" primaryKey="nodeId" rowSelection="single" [rowSelectable]="true"
            cellSelection="none">
            <igx-column field="name" [sortable]="true" [resizable]="true" header="Forecast Name" width="200px">
              <ng-template igxCell let-cell="cell">
                <a (click)="forecastDetail(cell.row)">{{cell.value}}</a>
              </ng-template>
            </igx-column>
            <igx-column [resizable]="true" field="type" [sortable]="true" header="Type"></igx-column>
            <igx-column [resizable]="true" field="modified" [sortable]="true" header="Modified"></igx-column>
            <igx-column [width]="120" field="tag" [resizable]="true" [sortable]="true" header="Tag"></igx-column>
            <igx-column [width]="140" field="additionalTag" [resizable]="true" [sortable]="true"
              header="Additional Tag"></igx-column>
            <igx-column field="owner" [resizable]="true" [sortable]="true" header="Owner"></igx-column>
            <igx-column field="group" [resizable]="true" [sortable]="true" header="Group"></igx-column>

          </igx-grid>
          <!-- </igx-card> -->
        </div>

      </div>
      <div class="col-sm-12">
        <div class="gridview" id="gridview" style="height: 450px;">
          <div class="row row-cols-1 row-cols-md-4 g-5 listingmainrow ">
            <div class="col">
              <igx-card elevated>
                <div class="card forcastgrid_view">
                  <div class="card-body">
                    <div style=" width: 44%; display: inline-block;">
                      <label class="newimpForecastText">New Forecast </label>
                      <div class="glyphicon glyphicon-plus" (click)="newforecast()"
                        style=" margin: 14px 0 0 71px;font-size: 37px; color:#1e90ff;width: 8%;"></div>
                    </div>
                    <div style=" width: 43%; display: inline-block;">
                      <label class="newimpForecastText"> New Consolidation</label>
                      <div class="glyphicon glyphicon-import" (click)="newConsolidation()"
                        style="margin: 14px 0 0 71px;font-size: 37px; color:#1e90ff;width: 8%;"></div>
                    </div>
                  </div>
                </div>
              </igx-card>

            </div>
            <div class="col" style="margin-bottom: 5px;" *ngFor="let listing of forecastlist">
              <igx-card elevated>
                <div class="card forcastgrid_view">
                  <div class="card-body">
                    <igx-input-group>
                      <h5 class="card-title" igxInput style="cursor: pointer;"
                        (click)="openforecastdetail(listing.nodeId,listing.name, listing.isConsolidation,listing.startdate, listing)">
                        {{listing.name}}</h5>
                    </igx-input-group>
                    <div class="card-name col">Type : {{listing.type}}</div>
                    <div class="card-name col">Owner : {{listing.owner}}</div>
                    <div class="card-name col">Modified : {{listing.modified}}</div>
                  </div>
                  <div class="editdeleteimportexporticon">
                    <div class="glyphicon glyphicon-folder-close deleteIconColor" (click)="DeleteForecastForm.open()"
                      ngbTooltip="Delete Forecast">
                    </div>
                    <igx-dialog #DeleteForecastForm [closeOnOutsideSelect]="false">
                      <div class="insidecontainer" style="width: 465px;height: 100px;">
                        <igx-dialog-title class="dialogtitle">
                          <div class="title-container">
                            <div class="dialog-title" style="font-size: 16px;">Delete Forecast</div>
                          </div>
                        </igx-dialog-title>
                        <div class="Department row">
                          <p style="font-size: 24px;margin: 2px 3px 2px 45px;">Are you sure you want to delete Forecast?
                          </p>
                        </div>
                      </div>
                      <div igxDialogActions>
                        <button igxButton (click)="DeleteForecastForm.close()"
                          class="deleteCancelButtonColor">Cancel</button>
                        <button igxButton class="OkSaveButtonColor"
                          (click)="deleteforecast(listing.nodeId,DeleteForecastForm)">Ok</button>
                      </div>
                    </igx-dialog>
                  </div>
                </div>
              </igx-card>
            </div>
          </div>
        </div>

      </div>

      <!-- List View Grid View End-->
    </div>

  </div>

  <div class="box overlay hidden" id="newforecastlist" style="background: aliceblue !important;;">
    <igx-card elevated>
      <div class="" style=" height: 824px;">
        <ul class="list-group" *ngIf="ActiveForecastDiv && Newforecastselected">
          <app-newforecast [forecastSelection]="Newforecastselected"></app-newforecast>
        </ul>
      </div>
    </igx-card>
  </div>
  <div class="box overlayConsol hidden" id="newConsolidationCreate">
    <igx-card elevated>
      <div class="" style=" height: 733px;">
        <ul class="list-group" *ngIf="ActiveForecastConsolidationDiv">
          <app-newconsolidation></app-newconsolidation>
        </ul>
      </div>
    </igx-card>
  </div>
  <div>
    <igx-dialog #OTRReport [closeOnOutsideSelect]="false">
      <!-- <app-formula [FormulaDetails]="displayvaluelistdata" (opening)="onDialogOpenHandler($event)"
                                (formulaModifiedEvent)="formulaModified($event)"></app-formula> -->
      <!-- <app-formula [FormulaDetails]="displayvaluelistdata" (formulaModifiedEvent)="formulaModified($event)"></app-formula>
      <div igxDialogActions>
        <button igxButton (click)="bonusformulaform.close()" class="deleteCancelButtonColor">CANCEL</button>
        <button igxButton class="OkSaveButtonColor"
          (click)="saveFormulaCollection(bonusformulaform,displayvaluelistdata)">Save</button>
      </div> -->
    </igx-dialog>
  </div>
  <div *ngIf="this.systemCalculation">
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
      <p style="color: white">System Records are being calculated, Please wait. </p>
    </ngx-spinner>
  </div>
  <div *ngIf="this.consolidaitonCalculation">
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
      <p style="color: white">Consolidation is being calculated, Please wait. </p>
    </ngx-spinner>
  </div>
</div>
<div class="test-importForecast hide">
  <div style="display: inline-block;width: fit-content;padding: 3px 6px 0px 0;">
    <button class="form-control hide" id="ImportForecastevent" style="display: inline-block;width: fit-content;"
      (click)="ImportForecast.open()">Import Forecast</button>
    <div>
      <igx-dialog #ImportForecast [closeOnOutsideSelect]="true">
        <div class="insidecontainer" style="width: 420px;height: 280px; overflow: auto;">
          <igx-dialog-title class="dialogtitle">
            <!-- <div class="title-container">
              <h2 class="dialog-title">Import Forecast</h2>
            </div> -->
          </igx-dialog-title>
          <div class="" style="display: flex; flex-direction: column;">
            <app-importforecast></app-importforecast>
            <div igxDialogActions>
              <button igxButton (click)="closePopupEvent(ImportForecast)" style="margin-top: 15px;padding: 10px;"
                class="deleteCancelButtonColor">Close</button>
            </div>
          </div>
        </div>

      </igx-dialog>
    </div>
  </div>
</div>
<app-footer></app-footer>