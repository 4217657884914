// IgxExcelExporterService no longer need to be manually provided and can be safely removed.
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { AppInitService } from './app-init.service'
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ListForecastComponent } from './component/list-forecast/list-forecast.component';
import { ImportforecastComponent } from './component/list-forecast/importforecast/importforecast.component';
import { ValuelistComponent } from './component/record/valuelist/valuelist.component';
import { ForecasttabsComponent } from './common/forecasttabs/forecasttabs.component';
import { DetailComponent } from './common/detail/detail.component';
import { ForecastExtentTabsComponent } from './common/forecast-extent-tabs/forecast-extent-tabs.component';
import { MenuComponent } from './common/menu/menu.component';
import { TreemenuComponent } from './common/treemenu/treemenu.component';
// import { TreeviewModule } from 'ngx-treeview';
import { CommonModule } from '@angular/common';
import { TreeModule } from '@ali-hm/angular-tree-component';
import { NumericDirective } from './component/record/valuelist/numeric.directive';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MinusSignToParens } from './component/record/valuelist/minus-sign-to-parens.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";
import { LoginComponent } from './login/login.component';
import { MethodComponent } from './component/record/method/method.component';
import { MannuallyEntered } from './darrivation/mannually-entered';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';


// code for authentication
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { CoreModule } from './core/core.module';
import { NewforecastComponent } from './component/list-forecast/newforecast/newforecast.component';
import { NetSalesComponent } from './component/record/valuelist/net-sales/net-sales.component';
import { GrossReceiptsGroupedComponent } from './component/record/valuelist/gross-receipts-grouped/gross-receipts-grouped.component';
import { CommonhtmlComponent } from './common/commonhtml/commonhtml.component';
import { SectiontreeComponent } from './common/sectiontree/sectiontree.component';
import { MinussignsparenPipe } from './common/commonhtml/minussignsparen.pipe';
import { ReportComponent } from './component/report/report.component';
import { SectionsComponent } from './component/sections/sections.component';

import { DataTablesModule } from 'angular-datatables';
import { StockComponentComponent } from './component/stock-component/stock-component.component';
import { EnterOpeningComponent } from './component/enter-opening/enter-opening.component';
import { TreeopeningComponent } from './component/enter-opening/treeopening/treeopening.component';
import { LeftSidebarMenuComponent } from './component/left-sidebar-menu/left-sidebar-menu.component';
import { RecordOptionComponent } from './component/record-option/record-option.component';
import { ReportTreeComponent } from './component/report-tree/report-tree.component';
import { EnterActualsComponent } from './component/enter-actuals/enter-actuals.component';
import { RightsidebarMenuComponent } from './component/rightsidebar-menu/rightsidebar-menu.component';
import { EnterActualTreeComponent } from './component/enter-actuals/enter-actual-tree/enter-actual-tree.component';
import { StockComponentHtmlComponent } from './component/stock-component/stock-component-html/stock-component-html.component';
import { TreeNodefolderComponent } from './component/tree-nodefolder/tree-nodefolder.component';
import { TreeNodeDepartmentComponent } from './component/tree-node-department/tree-node-department.component';
import { FolderViewDetailsComponent } from './common/detail/FolderViewDetails/folder-view-details/folder-view-details.component';
import { SectionViewDetailsComponent } from './common/detail/SectionViewDetails/section-view-details/section-view-details.component';
import { DepartmentViewDetailsComponent } from './common/detail/DepartmentViewDetails/department-view-details/department-view-details.component';
import { ForecastViewDetailsComponent } from './common/detail/ForecastViewDetails/forecast-view-details/forecast-view-details.component';
import { JwtModule } from "@auth0/angular-jwt";
import {
  IgxTabsModule,
  IgxCheckboxComponent,
  IgxCardModule,
  IgxIconModule,
  IgxButtonModule,
  IgxInputGroupModule,
  IgxRippleModule,
  IgxAvatarModule,
  IgxLayoutModule,
  IgxCalendarModule,
  IgxDatePickerModule,
  IgxNavbarModule,
  IgxSelectModule,
  IgxNavigationDrawerModule,
  IgxToggleModule,
  IgxExcelExporterService,
  IgxDateTimeEditorModule,
  IgxTextSelectionModule,
  IgxExpansionPanelModule,
  IgxDropDownModule,
  IgxDialogModule,
  IgxTooltipModule,
  IgxSnackbarModule,
  IgxSnackbarComponent,
  IgxCheckboxModule,
  IgxAutocompleteModule,
  IgxGridModule,
  IgxTreeGridModule,
  IgxActionStripModule,
  IgxComboModule,
  IgxSimpleComboModule,
  IgxSplitterModule
} from '@infragistics/igniteui-angular';


import { CurrencySettingComponent } from './component/Settings/CurrencySetting/currency-setting/currency-setting.component';
import { ProfileSettingComponent } from './component/Settings/ProfileSetting/profile-setting/profile-setting.component';
import { SettingsComponent } from './component/Settings/settings/settings.component';
import { SettingsmenuComponent } from './component/Settings/settingsmenu/settingsmenu/settingsmenu.component';
import { SettingbaseComponent } from './component/Settings/settingbase/settingbase.component';
import { PayrollTableOptionsComponent } from './component/Settings/payroll-table-options/payroll-table-options.component';
import { ContractRetentionsComponent } from './component/Settings/contract-retentions/contract-retentions.component';
import { GSTRateSettingComponent } from './component/Settings/gstrate-setting/gstrate-setting.component';
import { PreferencesSettingComponent } from './component/Settings/preferences-setting/preferences-setting.component';
import { ForecastotherOptionComponent } from './component/Settings/forecastother-option/forecastother-option.component';
import { PameterDefaultComponent } from './component/Settings/pameter-default/pameter-default.component';
import { HeaderComponent } from './common/header/header.component';
import { ClientsComponent } from './component/clients/clients.component';
import { ForecastSettingDetailsComponent } from './component/Settings/forecast-setting-details/forecast-setting-details.component';
import { ForecastExtentComponent } from './component/Settings/forecast-extent/forecast-extent.component';
import { EmployeeGroupComponent } from './component/employee-group/employee-group.component';
import { BonusesComisionComponent } from './component/employee-group/bonuses-comision/bonuses-comision.component';
import { BonuscommisionComonHtmlComponent } from './component/employee-group/bonuses-comision/bonuscommision-comon-html/bonuscommision-comon-html.component';
import { BonusesComponent } from './component/employee-group/bonuses/bonuses.component';
import { CommombonusesComponent } from './component/employee-group/bonuses/commombonuses/commombonuses.component';
import { FormulaComponent } from './component/formula/formula.component';
import { FormulatreeViewComponent } from './component/formula/formulatree-view/formulatree-view.component';
import { ReportDecimalpipePipe } from './component/report/report-decimalpipe.pipe';
import { EmployeeCalculationPipe } from './component/employee-group/employee-calculation.pipe';
import { EmployeetotalPipe } from './component/employee-group/employeetotal.pipe';
import { DecimalPipe } from '@angular/common';
import { HotlinkComponent } from './component/hotlink/hotlink.component';
import { HotlinkDataSourceCollectionComponent } from './component/hotlink/hotlink-data-source-collection/hotlink-data-source-collection.component';
import { PermissionDirective } from './core/directive/permission.directive';
import { GlobalErrorHandler } from './core/interceptors/global-error-handler';
import { UserRegistrationComponent } from './component/user-registration/user-registration.component';
import { InputComponent } from './component/user-registration/input/input.component';
import { LicensePurchaseComponent } from './component/license-purchase/license-purchase.component';
import { XeroAuthenticationComponent } from './component/Integration/Xero/xero-authentication/xero-authentication.component';
import { ImportDataComponent } from './component/Integration/Xero/import-data/import-data.component';
// import { WhatIfCommonComponent } from './common/what-if-common/what-if-common.component';
import { NewconsolidationComponent } from './component/list-forecast/newconsolidation/newconsolidation.component';
import { ConsolidationValuelistComponent } from './component/record/valuelist/consolidation-valuelist/consolidation-valuelist.component';
import { EliminationsComponent } from './component/eliminations/eliminations.component';
import { ConsolidationSettingsComponent } from './component/consolidation-settings/consolidation-settings.component';
import { ConsolidaitondetailsComponent } from './component/Settings/forecast-setting-details/consolidaitondetails/consolidaitondetails.component';
import { CapitalpaidComponent } from './component/capitalpaid/capitalpaid.component';
import { CalculatedLoanMadeComponent } from './component/calculated-loan-made/calculated-loan-made.component';
import { FinnacedassetsComponent } from './component/finnacedassets/finnacedassets.component';
import { StockmadecomponetComponent } from './component/stockmadecomponet/stockmadecomponet.component';
import { StockmadecommonComponent } from './component/stockmadecomponet/stockmadecommon/stockmadecommon.component';
import { FixedassetrecordComponent } from './component/record-option/fixedassetrecord/fixedassetrecord.component';
import { AccruedPrepaidCostRecordOptionsComponent } from './component/record-option/accrued-prepaid-cost-record-options/accrued-prepaid-cost-record-options.component';
import { OtherfixedassestoptionComponent } from './component/record-option/otherfixedassestoption/otherfixedassestoption.component';
import { OneTouchReportComponent } from './component/one-touch-report/one-touch-report.component';
import { GoodwillComponent } from './component/goodwill/goodwill.component';
import { UpdatecurrencyComponent } from './component/list-forecast/updatecurrency/updatecurrency.component';
import { CustomDecimalPipe } from './common/customPipe/custom-decimal.pipe';
import { AdminSettingsComponent } from './component/AdminModule/admin-settings/admin-settings.component';
import { PurchaseModuleComponent } from './component/AdminModule/purchase-module/purchase-module.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { CreatPasswordComponent } from './component/forgot-password/creat-password/creat-password.component';
import { UserManagementComponent } from './component/user-management/user-management.component';
import { ListUserForecastComponent } from './component/user-management/list-user-forecast/list-user-forecast.component';
import { ListUsersComponent } from './component/user-management/list-users/list-users.component';
import { ListsubusersComponent } from './component/user-management/listsubusers/listsubusers.component';
import { ValidateEmailComponent } from './component/validate-email/validate-email.component';
import { SupportRequestComponent } from './component/support-request/support-request.component';
import { StockMethodComponent } from './component/stock-component/stock-method/stock-method.component';
import { StockMadeMethodComponent } from './component/stockmadecomponet/stock-made-method/stock-made-method.component';
import { StockCalculationPipePipe } from './component/stockmadecomponet/pipe/stock-calculation-pipe.pipe';
import { PrintOTRComponent } from './component/print-otr/print-otr.component';
import { OTRComponent } from './component/otr/otr.component';
import { FooterComponent } from './common/footer/footer.component';
import { FinacingrecordOptionComponent } from './component/record-option/finacingrecord-option/finacingrecord-option.component';
import { FinnacedAssestRecordOptionsComponent } from './component/record-option/finnaced-assest-record-options/finnaced-assest-record-options.component';

export function init_app(appLoadService: AppInitService) {
  // console.log("initapp");
  return () => appLoadService.init();
}

@NgModule({
  declarations: [
    PermissionDirective,
    AppComponent,
    ListForecastComponent,
    ValuelistComponent,
    NumericDirective,
    ForecasttabsComponent,
    DetailComponent,
    MinusSignToParens,
    ForecastExtentTabsComponent,
    MenuComponent,
    TreemenuComponent,
    LoginComponent,
    MethodComponent,
    NewforecastComponent,
    NetSalesComponent,
    GrossReceiptsGroupedComponent,
    CommonhtmlComponent,
    SectiontreeComponent,
    MinussignsparenPipe,
    ReportComponent,
    SectionsComponent,
    StockComponentComponent,
    EnterOpeningComponent,
    TreeopeningComponent,
    LeftSidebarMenuComponent,
    RecordOptionComponent,
    ReportTreeComponent,
    EnterActualsComponent,
    RightsidebarMenuComponent,
    EnterActualTreeComponent,
    StockComponentHtmlComponent,
    TreeNodefolderComponent,
    TreeNodeDepartmentComponent,
    FolderViewDetailsComponent,
    SectionViewDetailsComponent,
    DepartmentViewDetailsComponent,
    ForecastViewDetailsComponent,
    CurrencySettingComponent,
    ProfileSettingComponent,
    SettingsComponent,
    SettingsmenuComponent,
    SettingbaseComponent,
    PayrollTableOptionsComponent,
    ContractRetentionsComponent,
    GSTRateSettingComponent,
    PreferencesSettingComponent,
    ForecastotherOptionComponent,
    PameterDefaultComponent,
    HeaderComponent,
    ClientsComponent,
    ForecastSettingDetailsComponent,
    ForecastExtentComponent,
    EmployeeGroupComponent,
    BonusesComisionComponent,
    BonuscommisionComonHtmlComponent,
    BonusesComponent,
    CommombonusesComponent,
    FormulaComponent,
    FormulatreeViewComponent,
    ReportDecimalpipePipe,
    EmployeeCalculationPipe,
    EmployeetotalPipe,
    HotlinkComponent,
    HotlinkDataSourceCollectionComponent,
    UserRegistrationComponent,
    InputComponent,
    LicensePurchaseComponent,
    XeroAuthenticationComponent,
    ImportDataComponent,
    // WhatIfCommonComponent,
    NewconsolidationComponent,
    ConsolidationValuelistComponent,
    EliminationsComponent,
    ConsolidationSettingsComponent,
    ConsolidaitondetailsComponent,
    CapitalpaidComponent,
    CalculatedLoanMadeComponent,
    FinnacedassetsComponent,
    StockmadecomponetComponent,
    StockmadecommonComponent,
    FixedassetrecordComponent,
    AccruedPrepaidCostRecordOptionsComponent,
    OtherfixedassestoptionComponent,
    UpdatecurrencyComponent,
    OneTouchReportComponent,
    GoodwillComponent,
    CustomDecimalPipe,
    AdminSettingsComponent,
    PurchaseModuleComponent,
    ForgotPasswordComponent,
    CreatPasswordComponent,
    UserManagementComponent,
    ListUserForecastComponent,
    ListUsersComponent,
    ListsubusersComponent,
    ValidateEmailComponent,
    SupportRequestComponent,
    StockMethodComponent,
    StockMadeMethodComponent,
    StockCalculationPipePipe,
    PrintOTRComponent,
    OTRComponent,
    FooterComponent,
    FinacingrecordOptionComponent,
    ImportforecastComponent,
    FinnacedAssestRecordOptionsComponent,
  ],
  imports: [
    BrowserModule,
    TreeModule,
    CommonModule,
    NgbModule,
    CoreModule,
    HttpClientModule,
    AppRoutingModule,
    // TreeviewModule.forRoot(),
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule, BrowserAnimationsModule,
    DataTablesModule,
    IgxCardModule,
    IgxCardModule,
    IgxIconModule,
    IgxButtonModule,
    IgxInputGroupModule,
    IgxRippleModule,
    IgxAvatarModule,
    IgxLayoutModule,
    IgxTabsModule,
    IgxNavbarModule,
    IgxNavigationDrawerModule,
    IgxToggleModule,
    IgxSelectModule,
    IgxDateTimeEditorModule,
    IgxTextSelectionModule,
    IgxCalendarModule,
    IgxDatePickerModule,
    IgxDropDownModule,
    IgxDialogModule,
    IgxExpansionPanelModule,
    IgxTooltipModule,
    IgxSnackbarModule,
    MatSnackBarModule,
    IgxCheckboxModule,
    IgxAutocompleteModule,
    IgxGridModule,
    IgxTreeGridModule,
    IgxActionStripModule,
    MatMenuModule,
    IgxComboModule,
    IgxSimpleComboModule,
    IgxSplitterModule,
    DataTablesModule,

    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [],
        disallowedRoutes: []
      }
    })
  ],
  exports: [
    PermissionDirective,
    InputComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [AppInitService, RightsidebarMenuComponent, MinussignsparenPipe, EmployeeGroupComponent, HotlinkDataSourceCollectionComponent, RecordOptionComponent, NewforecastComponent, FinacingrecordOptionComponent,
    UpdatecurrencyComponent,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true
    }, IgxExcelExporterService, TreemenuComponent, IgxSnackbarComponent,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function tokenGetter() {
  return localStorage.getItem("jwt");
}

//platformBrowserDynamic().bootstrapModule(AppModule);




